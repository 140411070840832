<template>
  <div class="pledgeCoin mainBgColor spacing">
    <div class="pledgeCoinLogoImg">
      <span class="white block-name">{{ $t("message.fundName") }}</span>
      <div class="line">
        <img src="@/assets/image/show.png" class="line-img" alt="line" />
      </div>
      <div class="desc gray">
        {{ $t("message.fundDesc") }}
      </div>
      <div class="flex-center">
        <div
          class="common-btn open"
          @click="openWeb('https://t.me/TGslots_bet_bot')"
        >
          {{ $t("message.openTips") }}
        </div>
        <!-- <div class="common-btn download m-l-20">
          {{ $t("message.download") }}
        </div> -->
      </div>
    </div>
    <div class="step">
      <div class="stepItem" v-for="(item, index) in stepList" :key="index">
        <div class="flex-center">
          <div class="white">{{ $t("message.stepName") }}{{ index + 1 }}</div>
          <img class="coinImg" :src="item.img" alt="pledgeCoin" />
          <img
            v-if="item.flag"
            class="coinImg"
            :src="item.img1"
            alt="pledgeCoin"
          />
          <img
            v-if="item.flag"
            class="coinImg"
            :src="item.img2"
            alt="pledgeCoin"
          />
        </div>
        <!-- <p class="stepTitle white">{{ item.title }}</p> -->
        <p class="stepDesc gray fs12">
          {{ $t(item.tips) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepList: [
        {
          tips: "message.fundTips1",
          img: require("@/assets/image/appLogo.png"),
          flag: false,
        },
        {
          tips: "message.fundTips2",
          img: require("@/assets/image/fox.png"),
          img1: require("@/assets/image/webHome.png"),
          img2: require("@/assets/image/card.png"),
          flag: true,
        },
        {
          tips: "message.fundTips3",
          img: require("@/assets/image/bell.png"),
          flag: false,
        },
      ],
    };
  },
  methods: {
    openWeb(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.coinImg {
  width: 44px;
  height: auto;
  margin-left: 5px;
}
.open {
  background-color: rgba(13, 153, 255, 0.33);
  border: 1px solid #fff;
  width: 240px;
  font-size: 16px;
}
.download {
  background-color: #0d99ff;
  border: 1px solid #0d99ff;
  width: 240px;
  font-size: 16px;
}
</style>
