<template>
  <div class="safeContainer mainBgColor spacing">
    <el-row :gutter="20">
      <el-col :sm="7">
        <div class="safeDesc">
          <div class="safeLogoImg">
            <span class="white block-name">{{ $t("message.jumpName") }}</span>
            <div class="line">
              <img src="@/assets/image/show.png" class="line-img" alt="line" />
            </div>
            <div class="desc gray">
              {{ $t("message.jumpTips") }}
            </div>
          </div>
          <div class="safeItem" v-for="(item, index) in safeList" :key="index">
            <img :src="item.img" alt="" style="width: 27px; height: auto" />
            <div class="safeTitle m-l-30">{{ $t(item.title) }}</div>
          </div>
          <div
            class="jump-play common-btn"
            @click="openWeb('https://t.me/TGslots_bet_bot')"
          >
            {{ $t("message.jumpPlay") }}
          </div>
          <img class="webImage" src="@/assets/image/phone.png" alt="" />
        </div>
      </el-col>
      <el-col :sm="3"></el-col>
      <el-col :sm="14">
        <img class="webImg" src="@/assets/image/safeLogo.png" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      safeList: [
        {
          title: "message.safeTips1",
          img: require("@/assets/image/cardIcons.png"),
        },
        {
          title: "message.safeTips2",
          img: require("@/assets/image/idIcons.png"),
        },
        {
          title: "message.safeTips3",
          img: require("@/assets/image/phoneIcons.png"),
        },
        {
          title: "message.safeTips4",
          img: require("@/assets/image/flyIcons.png"),
        },
      ],
    };
  },
  methods: {
    openWeb(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-row {
  align-items: center;
}
.webImg {
  width: 100%;
  height: auto;
  margin-top: 105px;
}
.safeContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .safeLogoImg {
    margin-bottom: 50px;
  }
  .safeItem {
    align-items: center;
    display: flex;
    font-size: 20px;
    margin-bottom: 24px;
    color: #fff;
  }
  .jump-play {
    width: 200px;
    background-color: #0085ff;
    border: 1px solid #0085ff;
    font-size: 20px;
    margin-top: 50px;
  }
}
</style>
