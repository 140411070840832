<template>
  <div class="mainBgColor spacing">
    <a name="HowItWorks" class="hidden-anchor"></a>
    <div class="operationContainer">
      <el-row :gutter="20">
        <el-col :sm="7">
          <div class="operationDesc">
            <div class="operationLogoImg">
              <span class="white block-name">{{ $t("message.work") }} ?</span>
              <div class="line">
                <img
                  src="@/assets/image/show.png"
                  class="line-img"
                  alt="line"
                />
              </div>
            </div>
            <div class="desc gray m-t-20">
              {{ $t("message.workTips") }}
            </div>
          </div>
        </el-col>
        <el-col :sm="2"></el-col>
        <el-col :sm="15">
          <img class="operationDescImg" src="@/assets/image/chart.png" alt="" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-row {
  align-items: center;
}
.operationContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .operationDescImg {
    width: 100% !important;
    height: auto;
  }
}
</style>
