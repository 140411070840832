import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/vue";
import { baseURL } from "@/api/config";
// 1. Get projectId
// const projectId = "2c007509e21f3b346eb2f4a7e4e9c751";
const projectId = "0b31131012f46e311458fc7bcd9ecef6";
// 2. Set chains
const mainnet = {
  chainId: 56, //正式
  // chainId: 97, // 测试
  name: "BNB",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: baseURL.RPCURL_API,
};

// 3. Create modal
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: baseURL.RPCURL_API,
  icons: ["https://avatars.mywebsite.com/"],
};
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  /*Optional*/
  auth: {
    email: true, // default to true
    socials: ["google", "x", "github", "discord", "apple"],
    showWallets: true, // default to true
    walletFeatures: true, // default to true
  },
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: baseURL.RPCURL_API, // used for the Coinbase SDK
  defaultChainId: 56, // used for the Coinbase SDK
});
// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true,
});
export default createWeb3Modal;
