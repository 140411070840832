//  西班牙语/墨西哥（español）
export default {
  message: {
    unStakeErrorTips:
      "El token de pignoración solo se puede levantar después de cotizar en la bolsa.",
    copyTips: "Copiar",
    tgsName: "Dirección del token $TGS",
    tgsTips:
      "Esta es la dirección del token. No realice transferencias a esta dirección. Si desea comprar fichas, utilice fideos originales de preventa.",
    succeeTips: "Transacción exitosa",
    failTips: "La transacción falló, inténtelo nuevamente",
    viewTips: "Ver en BSC Scan: ",
    approve: "Aprobar",
    menuHome: "Hogar",
    menuPresale: "Preventa",
    menuWork: "Cómo funciona",
    menuStaking: "Recompensas de apuesta",
    menuToken: "Economía de tokens",
    menuBuy: "Cómo comprar",
    menuFAQ: "Preguntas más frecuentes",
    homeTips: "Las mejores tragamonedas del mundo en Telegram",
    slot: "RANURAS",
    join: "Únete a la comunidad",
    play: "Reproducir ahora",
    letMeIn: "Déjame entrar",
    presaleTop: "PREVENTA",
    event: "Evento de preventa",
    http: "https://TGSlots/#home",
    desc: "TGSlots es el lugar exclusivo para tragamonedas en línea y otros juegos de casino en Telegram. Licencia completa y sus fondos 100% seguros con billeteras sin custodia. ¡Obtén nuestro token $TGS para compartir una parte de nuestras ganancias!",
    title: "¿Por qué obtener $TGS en la preventa?",
    tips1: "Recompensas exclusivas",
    currentPrice: "Precio actual",
    achieved: "Nuestra meta",
    increases: "Compra ahora antes de que aumente el precio en:",
    pay: "Tu pagas",
    max: "MÁXIMO",
    receive: "$TGS que recibes",
    buyRequire: "Por favor compre al menos 100 $TGS.",
    enoughTips: "No tienes suficiente",
    enoughTips1: "para pagar esta transacción.",
    gasPayTips: "Asegúrate de tener suficiente",
    gasPayTips1: "para pago de gasolina",
    gasTips: "Oferta de tiempo limitado",
    gasTips1:
      "TGSlots subsidia completamente las tarifas de gasolina cuando compra $TGS; comuníquese con atención al cliente para reclamar. ",
    walletTips: "Billetera",
    balanceTips: "Balance",
    toContact: "Haga clic para contactar",
    purchaseTips:
      "Si no puede comprar normalmente utilizando un navegador de terceros, copie el enlace al navegador integrado de la billetera para acceder.",
    copyLink: "Copiar link",
    apy: "APY de apuesta actual:",
    buyAndStake: "Compre y apueste por APY",
    apyTips: "APY",
    justBuy: "Solo quiero comprar sin apostar",
    connectWallet: "Conectar billetera",
    wallet: "Cómo agregar $TGS a mi billetera",
    navStakTips:
      "TGSlots te permite apostar y retirar tus tokens. Elija qué función desea. Sólo los tokens apostados ganan APY.",
    blockStakName1: "APY actual",
    blockStakName2: "Total de $TGS apostados",
    blockStakName3: "% del total de $TGS apostados ",
    blockStakName4: "Recompensas totales pagadas ($TGS)",
    youStaking: "Tu apuesta",
    staked1: "Total de $TGS apostados",
    staked2: "% del total de $TGS apostados",
    harvested: "Recompensas totales recolectadas ($TGS)",
    claimable: "Recompensas totales reclamables ($TGS)",
    receiveTips: "Recibir",
    stake: "Apostar",
    unStake: "Desapuesta",
    learnMore: "Aprende más",
    indicatedAPY:
      "Apuesta tus tokens para recibir recompensas pasivas basadas en el APY indicado",
    unStakeTips: "Retire sus tokens y canjee sus recompensas por apostar",
    preSaleName: "¿Por qué obtener $TGS en la preventa?",
    presaleTitle1: "Recompensas exclusivas",
    presaleTips1:
      "Los participantes de nuestra preventa tienen derecho a recompensas exclusivas cuando juegan TGSlots",
    presaleTitle2: "El mejor precio de todos los tiempos",
    presaleTips2:
      "Nunca habrá un mejor momento para obtener $TGS que durante la preventa porque $TGS aumentará a medida que crezcan los ingresos de nuestra plataforma.",
    presaleTitle3: "Recompensas para el gran apostador",
    presaleTips3:
      "¡Invierta 5.000 USD o más en nuestra preventa para recibir NFT exclusivos y otras recompensas posteriores al lanzamiento!",
    presaleTitle4: "Comience a ganar hoy",
    presaleTips4:
      "¡Obtén $TGS en nuestra preventa y podrás apostar inmediatamente para desbloquear recompensas de inmediato!",
    rewards1:
      "Los participantes de nuestra preventa tienen derecho a recompensas exclusivas cuando juegan TGSlots Best Price Ever",
    tips2: "El mejor precio de todos los tiempos",
    rewards2:
      "Nunca habrá un mejor momento para obtener $TGS que durante la preventa porque $TGS aumentará a medida que crezcan los ingresos de nuestra plataforma.",
    tips3: "Recompensas para el gran apostador",
    rewards3:
      "¡Invierta 5.000 USD o más en nuestra preventa para recibir NFT exclusivos y otras recompensas posteriores al lanzamiento!",
    tips4: "Comience a ganar hoy",
    rewards4:
      "¡Obtén $TGS en nuestra preventa y podrás apostar inmediatamente para desbloquear recompensas de inmediato!",
    work: "Cómo funciona",
    workTips:
      "TGSlots tiene todo lo que un casino en línea tiene para ofrecer, además de nuestro exclusivo sistema de incentivos que convierte a los usuarios en nuestros accionistas y son recompensados constantemente al conservar $TGS.",
    stakTitle: "Recompensas de apuesta",
    stakTips:
      "Al apostar $TGS comprados durante la preventa y ganados en el juego, te convertirás en nuestro accionista y compartirás una parte de nuestras ganancias con un APY alto.",
    incomTitle: "Obtenga ingresos pasivos",
    incomTips:
      "Observe cómo crecen sus tenencias de $TGS con el tiempo, incluso mientras duerme. La magia ocurre en el momento en que apuestas tus $TGS",
    giftTitle: "Reclame recompensas al instante",
    giftTips:
      "Puede reclamar recompensas por apostar cuando lo desee. ¡Puedes usarlo como fichas en TGSlots o volver a apostarlo en el grupo de apuestas para obtener aún más recompensas!",
    cashTitle: "Más oportunidades de ingresos",
    cashTips:
      "Cuando apuestas $TGS, desbloqueas el acceso a recompensas basadas en los ingresos de nuestra plataforma. ¡Consulte nuestra página de apuestas de vez en cuando para obtener actualizaciones!",
    tokenTitle: "Economía de tokens",
    tokenTips:
      "Nuestra economía de tokens está diseñada para alinear los incentivos y lograr una situación sostenible en la que todos ganen todos los poseedores de tokens involucrados.",
    itemTitle1: "Preventa",
    itemTitle2: "Recompensas de apuesta",
    itemTitle3: "Fondo de liquidez",
    itemTitle4: "Recompensas para jugadores",
    itemTitle5: "agente cooperativo",
    itemTitle6: "Inversores institucionales",
    itemTitle7: "tesorería comunitaria",
    itemTitle8: "Marketing",
    itemTips1: "Para poner en marcha el ecosistema $TGS",
    itemTips2: "Para recompensar a nuestros dedicados poseedores",
    itemTips3: "Para crear el par en DEX y CEX",
    itemTips4: "Recompensas para cada jugador",
    itemTips5: "Recompensas para quienes ayudan a hacer crecer TGSlots",
    itemTips6:
      "Reservado para organizaciones de inversión que invierten en TGSlots",
    itemTips7: "Para reserva estratégica comunitaria",
    itemTips8: "Para la cooperación y el marketing directo.",
    jumpName: "Salta directamente",
    jumpTips:
      "¡Simplemente abra TGSlots en Telegram y recargue su cuenta con las principales criptomonedas/tarjetas de crédito para comenzar!",
    safeTips1: "Con licencia y sin custodia",
    safeTips2: "No se necesita configuración de cuenta",
    safeTips3: "Juega en tu teléfono inteligente",
    safeTips4: "Totalmente integrado en Telegram",
    jumpPlay: "Reproducir ahora",
    whitepaper: "Papel blanco",
    whitepaperTips:
      "Para las personas que se toman tan en serio TGSlots como nosotros, hemos preparado el documento técnico que explora los detalles y las ideas detrás de la creación de TGSlots. ¡Léelo para ver en qué nos diferenciamos de otros casinos en línea!",
    FqName: "Preguntas frecuentes",
    FqTitle1: "¿Qué hace que TGSlots sea diferente?",
    FqTips1:
      "TGSlots le brinda acceso a todos los mejores juegos de casino en línea, casas de apuestas deportivas y más, pero también estamos comprometidos a cultivar un ecosistema que valora la lealtad. Como testimonio de nuestra dedicación, estamos retribuyendo compartiendo una parte de los ingresos de nuestro casino a través de recompras del token $TGS. ¡Juega, gana y sé recompensado!",
    FqTitle2: "¿Cuánto dura la preventa?",
    FqTips2:
      "La preventa estará abierta hasta alcanzar el límite máximo. El límite mínimo para la preventa es de 2 millón de dólares.",
    FqTitle3: "¿Por qué debería comprar $TGS?",
    FqTips3:
      "Si bien TGSlots te permitirá jugar con muchas de las principales criptomonedas, solo $TGS te dará acceso a recompensas increíbles, tanto por apostar como por simplemente mantener nuestro token. $TGS no es necesario para jugar en TGSlots, pero se puede utilizar para apostar en juegos como con todas las demás monedas.",
    FqTitle4: "¿Recibo los tokens $TGS de la preventa inmediatamente?",
    FqTips4:
      "No hay período de adquisición de derechos para $TGS. Sin embargo, si apuesta sus tokens durante la preventa, estarán bloqueados hasta el día del lanzamiento + 7 días. Al apostar los tokens, comienza inmediatamente a ganar recompensas por apostar y otros beneficios futuros.",
    FqTitle5: "¿Cuándo se lanzará el token?",
    FqTips5:
      "Lanzaremos $TGS unos días después de que finalice la preventa. Mantendremos a todos informados sobre el día del lanzamiento a través de nuestro sitio web y canales de redes sociales.",
    stakingName: "Replantear",
    stakingTips:
      "TGSlots le permite apostar y retirar sus tokens $TGS cuando lo desee. Pero tenga cuidado, ¡solo los tokens apostados ganan APY! Detalles",
    sakeips1: "APY actual",
    sakeips2: "Total de $TGS en el fondo de apuestas",
    sakeips3: "Su % de propiedad en Stake Pool ($TGS)",
    sakeips4: "Pago total ($TGS)",
    buyName: "Cómo comprar",
    buyTips:
      "Para comprar tokens durante la preventa, visite el sitio web oficial de TGSlots. Después de la preventa, los tokens se pueden obtener directamente desde el sitio web oficial o mediante intercambios descentralizados.",
    buyTitle: "Cómo comprar $TGS durante la preventa",
    buyStepName1: "Paso 1: Visita el portal oficial",
    buySteTips1:
      "Visite la página web dedicada de TGSlots para encontrar la interfaz exclusiva de compra de preventa. Tenga en cuenta que esta es la única plataforma autorizada para obtener tokens $TGS en preventa.",
    buyStepName2: "Paso 2: Elige el método de pago",
    buySteTips2:
      "Puede optar por comprar el token $TGS de preventa en la red Ethereum utilizando Ethereum (ETH) o Tether (USDT). Alternativamente, puede aprovechar Binance Smart Chain y elegir BNB o USDT. Haga su elección y continúe con el siguiente paso.",
    buyStepName3: "Paso 3: conecta tu billetera criptográfica",
    buySteTips3:
      "Si su billetera criptográfica aún no se ha sincronizado, verá un mensaje que le pedirá que se conecte. Normalmente, las billeteras están configuradas para la red principal de Ethereum de forma predeterminada; esto funciona a su favor si opera con ETH. Sin embargo, si elige Binance Smart Chain, es posible que deba confirmar el cambio de red.",
    buyStepName4: "Paso 4: Especifique el monto de la compra",
    buySteTips4:
      "Ingrese la cantidad de tokens $TGS que desea comprar. Tenga en cuenta que, además del coste del token que desea comprar, se requiere un mínimo de 0,015 ETH para completar la transacción. En Binance Smart Chain, este requisito mínimo es menor, pero aún se aplica. La cantidad mínima de tokens $TGS que se pueden comprar está establecida en 100 $TGS.",
    buyStepName5: "Paso 5: Completa tu compra",
    buySteTips5:
      "Una vez que haya ingresado el monto, se le pedirá que autorice la transacción. Al hacer clic en 'Aprobar', su transacción se iniciará y verificará en la cadena de bloques. Los tiempos de finalización de las transacciones pueden variar de segundos a minutos, según la congestión de la red y la actividad de los contratos inteligentes. Permanezca en la interfaz hasta que la compra se ejecute por completo.",
    contactUs: "Contáctenos",
    footerText: "tgslots Todos los derechos reservados",
    privacy: "Privacidad",
    terms: "Términos",
    cookie: "Privacidad",
    stepName: "Paso",
    fundName: "Juega de forma anónima con tus fondos seguros",
    fundDesc:
      "Gracias a la naturaleza descentralizada de blockchain, no le exigimos KYC ni necesitamos almacenar sus fondos de manera centralizada. Por lo tanto, no necesita entregar su información personal y no necesita preocuparse por la seguridad de sus fondos. ¡Todo lo que necesitas es una cuenta de Telegram, algunas criptomonedas y listo!",
    fundTips1:
      "Si aún no tiene una cuenta de Telegram, descargue la aplicación y cree una cuenta. Luego, abra TGSlots a través del enlace 'Abrir TGSlots' de arriba. Si ya tiene una cuenta de Telegram, haga clic en 'Abrir TGSlots' para comenzar de inmediato.",
    fundTips2:
      "Puede optar por transferir fondos a su cuenta TGSlots desde su billetera criptográfica, su cuenta CEX o su tarjeta de crédito (recargando su billetera TON). Una vez que termines de depositar, ¡estarás listo para comenzar!",
    fundTips3:
      "¡Elige un juego y ponte en marcha! Disfrute de una experiencia de juego sin complicaciones las 24 horas del día, los 7 días de la semana en TGSlots, con atención al cliente profesional disponible también las 24 horas del día, los 7 días de la semana.",
    openTips: "Abrir TGSlots",
    download: "Descargar Telegrama",
  },
};
