//  葡萄牙语/巴西（Português）
export default {
  message: {
    unStakeErrorTips:
      "Os sinais prometidos só podem ser libertados da promessa depois de serem listados na troca.",
    copyTips: "cópia",
    tgsName: "$TGS token address",
    tgsTips:
      "Este é o endereço de sinais. Não transferam para este endereço. Se você quiser comprar fitas, por favor use noodles genuinos pré-venda.",
    succeeTips: "Transação bem-sucedida",
    failTips: "Falha na transação, tente novamente",
    viewTips: "Ver no BSC Scan: ",
    approve: "Aprovar",
    menuHome: "Casa",
    menuPresale: "Pré-venda",
    menuWork: "Como Funciona",
    menuStaking: "Recompensas de Apostas",
    menuToken: "Economia das fichas",
    menuBuy: "Como Comprar",
    menuFAQ: "FAQ",
    homeTips: "Os melhores slots do mundo no Telegram",
    slot: "SLOTS",
    join: "Junte-se à comunidade",
    play: "Jogue agora",
    letMeIn: "Me deixar entrar",
    presaleTop: "PRÉ-VENDA",
    event: "Evento de pré-venda",
    http: "https://TGSlots/#home",
    desc: "TGSlots é o local exclusivo para caça-níqueis online e outros jogos de cassino no Telegram. Totalmente licenciado e seus fundos 100% seguros com carteiras sem custódia. Obtenha nosso token $TGS para compartilhar uma parte de nossos ganhos!",
    title: "Por que ganhar $ TGS na pré-venda?",
    tips1: "Recompensas exclusivas",
    currentPrice: "Preço atual",
    achieved: "Nosso objetivo",
    increases: "Compre agora antes que os preços aumentem em:",
    pay: "você paga",
    max: "MÁX.",
    receive: "$TGS você recebe",
    buyRequire: "Por favor compre pelo menos 100 $TGS.",
    enoughTips: "Você não tem o suficiente",
    enoughTips1: "para pagar por esta transação.",
    gasPayTips: "Certifique-se de ter o suficiente",
    gasPayTips1: "para pagamento de gás",
    gasTips: "Tempo limitado de oferta",
    gasTips1:
      "A TGSlots subsidia totalmente as taxas de gás quando você compra $ TGS, entre em contato com o suporte ao cliente para reivindicar. ",
    walletTips: "Carteira",
    balanceTips: "Equilíbrio",
    toContact: "Clique para entrar em contato",
    purchaseTips:
      "Se você não conseguir comprar normalmente usando um navegador de terceiros, copie o link para o navegador integrado da carteira para acessá-lo.",
    copyLink: "Link de cópia",
    apy: "APY de piquetagem atual:",
    buyAndStake: "Compre e aposte por APY",
    apyTips: "APY",
    justBuy: "Só quero comprar sem apostar",
    connectWallet: "Conectar carteira",
    wallet: "Como adicionar $TGS à minha carteira",
    navStakTips:
      "TGSlots permite apostar e retirar seus tokens. Escolha qual função você deseja. Apenas os tokens apostados ganham APY.",
    blockStakName1: "APY atual",
    blockStakName2: "Total de $TGS apostados",
    blockStakName3: "% do total de $TGS apostados%",
    blockStakName4: "Total de recompensas pagas ($TGS)",
    youStaking: "Sua aposta",
    staked1: "Total de $TGS apostados",
    staked2: "% do total de $TGS apostados",
    harvested: "Total de recompensas colhidas ($TGS)",
    claimable: "Total de recompensas exigíveis ($TGS)",
    receiveTips: "Receber",
    stake: "Estaca",
    unStake: "Desempacotar",
    learnMore: "Saber mais",
    indicatedAPY:
      "Aposte seus tokens para receber recompensas passivas com base no APY indicado",
    unStakeTips: "Retire seus tokens e resgate suas recompensas de apostar",
    preSaleName: "Por que ganhar $ TGS na pré-venda?",
    presaleTitle1: "Recompensas exclusivas",
    presaleTips1:
      "Os participantes da nossa pré-venda têm direito a recompensas exclusivas ao jogar TGSlots",
    presaleTitle2: "Melhor preço de todos os tempos",
    presaleTips2:
      "Nunca haverá melhor momento para obter o $TGS do que durante a pré-venda, porque o $TGS crescerá à medida que a receita da nossa plataforma crescer.",
    presaleTitle3: "Recompensas para High Roller",
    presaleTips3:
      "Invista 5 mil dólares ou mais em nossa pré-venda para ser recompensado com NFTs exclusivos e outras recompensas pós-lançamento!",
    presaleTitle4: "Comece a ganhar hoje",
    presaleTips4:
      "Ganhe $TGS em nossa pré-venda e você poderá apostar imediatamente para desbloquear recompensas imediatamente!",
    rewards1:
      "Os participantes da nossa pré-venda têm direito a recompensas exclusivas quando jogam no TGSlots Best Price Ever",
    tips2: "Melhor preço de todos os tempos",
    rewards2:
      "Nunca haverá melhor momento para obter o $TGS do que durante a pré-venda, porque o $TGS crescerá à medida que a receita da nossa plataforma crescer.",
    tips3: "Recompensas para High Roller",
    rewards3:
      "Invista 5 mil dólares ou mais em nossa pré-venda para ser recompensado com NFTs exclusivos e outras recompensas pós-lançamento!",
    tips4: "Comece a ganhar hoje",
    rewards4:
      "Ganhe $TGS em nossa pré-venda e você poderá apostar imediatamente para desbloquear recompensas imediatamente!",
    work: "Como funciona",
    workTips:
      "TGSlots tem tudo o que um cassino online tem a oferecer, além de nosso sistema de incentivos exclusivo que torna os usuários nossos acionistas e são recompensados constantemente mantendo $ TGS",
    stakTitle: "Recompensas de aposta",
    stakTips:
      "Ao apostar $TGS comprados durante a pré-venda e ganhos no jogo, você se tornará nosso acionista e compartilhará uma parte de nossos ganhos com um alto APY!",
    incomTitle: "Ganhe renda passiva",
    incomTips:
      "Observe suas participações em $TGS crescerem ao longo do tempo, mesmo enquanto você está dormindo. A mágica acontece no momento em que você aposta seu $ TGS",
    giftTitle: "Reivindique recompensas instantaneamente",
    giftTips:
      "Você pode reivindicar recompensas por apostar como e quando desejar. Você pode usá-los como fichas em TGSlots ou recolocá-los no pool de apostas para obter ainda mais recompensas!",
    cashTitle: "Mais oportunidades de ganhos",
    cashTips:
      "Ao apostar $TGS, você desbloqueia acesso a recompensas com base na receita da nossa plataforma. Verifique nossa página de staking de tempos em tempos para atualizações!",
    tokenTitle: "Economia de Tokens",
    tokenTips:
      "Nossa economia de tokens foi projetada para alinhar incentivos e alcançar uma situação sustentável e ganha-ganha para todos os detentores de tokens envolvidos.",
    itemTitle1: "Pré-venda",
    itemTitle2: "Recompensas de aposta",
    itemTitle3: "Pool de liquidez",
    itemTitle4: "Recompensas dos Jogadores",
    itemTitle5: "Agente cooperativo",
    itemTitle6: "Investidores institucionais",
    itemTitle7: "Tesouro comunitário",
    itemTitle8: "Marketing",
    itemTips1: "Para impulsionar o ecossistema $TGS",
    itemTips2: "Para recompensar nossos detentores dedicados",
    itemTips3: "Para criar o par em DEX e CEX",
    itemTips4: "Recompensas para cada jogador",
    itemTips5: "Recompensas para quem ajuda a crescer o TGSlots",
    itemTips6:
      "Reservado para organizações de investimento que investem na TGSlots",
    itemTips7: "Para reserva estratégica comunitária",
    itemTips8: "Para cooperação e marketing direto",
    jumpName: "Vá direto ao ponto",
    jumpTips:
      "Basta abrir o TGSlots no Telegram e recarregar sua conta com as principais criptomoedas/cartões de crédito para começar!",
    safeTips1: "Licenciado e sem custódia",
    safeTips2: "Nenhuma configuração de conta necessária",
    safeTips3: "Jogue no seu smartphone",
    safeTips4: "Totalmente integrado ao Telegram",
    jumpPlay: "Jogue agora",
    whitepaper: "Papel branco",
    whitepaperTips:
      "Para pessoas que levam TGSlots tão a sério quanto nós, preparamos o white paper que explora os detalhes e as ideias por trás da criação de TGSlots. Leia para ver como somos diferentes de outros cassinos online!",
    FqName: "perguntas frequentes",
    FqTitle1: "O que torna o TGSlots diferente?",
    FqTips1:
      "A TGSlots oferece acesso a todos os principais jogos de cassino online, apostas esportivas e muito mais, mas também estamos comprometidos em cultivar um ecossistema que valoriza a lealdade. Como prova da nossa dedicação, estamos retribuindo compartilhando uma parte da receita do nosso cassino por meio de recompras do token $TGS. Jogue, ganhe e seja recompensado!",
    FqTitle2: "Quanto tempo dura a pré-venda?",
    FqTips2:
      "pré-venda ficará aberta até que o hard cap seja atingido. O soft cap para a pré-venda é de 2 milhão de dólares.",
    FqTitle3: "Por que devo comprar $TGS?",
    FqTips3:
      "Embora o TGSlots permita que você jogue com muitas das principais criptomoedas, apenas o $TGS lhe dará acesso a recompensas incríveis, tanto por apostar quanto apenas por manter nosso token. $TGS não é necessário para jogar em TGSlots, mas pode ser usado para apostar em jogos como todas as outras moedas.",
    FqTitle4: "Recebo os tokens $TGS da pré-venda imediatamente?",
    FqTips4:
      "Não há período de aquisição para $TGS. No entanto, se você apostar seus tokens durante a pré-venda, eles ficarão bloqueados até o dia do lançamento + 7 dias. A aposta dos tokens começa imediatamente a ganhar recompensas de aposta e outros benefícios futuros.",
    FqTitle5: "Quando o token será lançado?",
    FqTips5:
      "Lançaremos $TGS alguns dias após o término da pré-venda. Manteremos todos informados sobre o dia do lançamento através do nosso site e canais de mídia social.",
    stakingName: "Estacamento",
    stakingTips:
      "TGSlots permite que você aposte e retire seus tokens $ TGS como e quando desejar. Mas tenha cuidado, apenas os tokens apostados ganham APY! Detalhes",
    sakeips1: "APY atual",
    sakeips2: "Total de $TGS no pool de apostas",
    sakeips3: "Sua% de participação no Staking Pool ($TGS)",
    sakeips4: "Pagamento total ($TGS)",
    buyName: "Como comprar",
    buyTips:
      "Para adquirir tokens durante a pré-venda, visite o site oficial da TGSlots. Após a pré-venda, os tokens podem ser obtidos diretamente no site oficial ou por meio de exchanges descentralizadas.",
    buyTitle: "Como comprar $TGS durante a pré-venda",
    buyStepName1: "Passo 1: Visite o portal oficial",
    buySteTips1:
      "Visite a página dedicada do TGSlots para encontrar a interface exclusiva de compra na pré-venda. Observe que esta é a única plataforma autorizada para obter tokens pré-venda de $TGS.",
    buyStepName2: "Passo 2: Escolha a forma de pagamento",
    buySteTips2:
      "Você pode optar por comprar o token pré-venda $TGS na rede Ethereum usando Ethereum (ETH) ou Tether (USDT). Alternativamente, você pode aproveitar o Binance Smart Chain e escolher BNB ou USDT. Faça sua escolha e prossiga para a próxima etapa.",
    buyStepName3: "Passo 3: Conecte sua carteira criptografada",
    buySteTips3:
      "Se sua carteira criptografada ainda não tiver sido sincronizada, você verá um aviso solicitando que você se conecte. Normalmente, as carteiras são configuradas para a rede principal Ethereum por padrão; isso funciona a seu favor se você negociar com ETH. No entanto, se você escolher Binance Smart Chain, pode ser necessário confirmar a troca de rede.",
    buyStepName4: "Etapa 4: especifique o valor da compra",
    buySteTips4:
      "Insira a quantidade de tokens $TGS que deseja comprar. Observe que além do custo do token que você pretende comprar, é necessário um mínimo de 0,015 ETH para concluir a transação. Na Binance Smart Chain, esse requisito mínimo é menor, mas ainda se aplica. A quantidade mínima de tokens $TGS que podem ser adquiridos é definida como 100 $TGS.",
    buyStepName5: "Passo 5: Conclua sua compra",
    buySteTips5:
      "Depois de inserir o valor, você será solicitado a autorizar a transação. Ao clicar em ‘Aprovar’, sua transação será iniciada e verificada na blockchain. Os tempos de conclusão das transações podem variar de segundos a minutos, dependendo do congestionamento da rede e da atividade do contrato inteligente. Permaneça na interface até que a compra seja totalmente executada.",
    contactUs: "Contate-nos",
    footerText: "tgslots Todos os direitos reservados",
    privacy: "Privacidade",
    terms: "Termos",
    cookie: "Privacidade",
    stepName: "Etapa",
    fundName: "Jogue anonimamente com seus fundos seguros",
    fundDesc:
      "Graças à natureza descentralizada do blockchain, não exigimos KYC de você nem precisamos armazenar seus fundos de maneira centralizada. Portanto, você não precisa fornecer suas informações pessoais e não precisa se preocupar com a segurança de seus fundos. Tudo que você precisa é de uma conta no Telegram, algumas criptomoedas e você está pronto para começar!",
    fundTips1:
      "Se você ainda não possui uma conta no Telegram, baixe o aplicativo e crie uma conta. Em seguida, abra o TGSlots através do link ‘Abrir TGSlots’ acima. Se você já possui uma conta no Telegram, clique em ‘Abrir TGSlots’ para começar imediatamente!",
    fundTips2:
      "Você pode optar por transferir fundos para sua conta TGSlots de sua carteira criptografada, de sua conta CEX ou de seu cartão de crédito (recarregando sua carteira TON). Depois de terminar de depositar, você estará pronto para começar!",
    fundTips3:
      "Escolha um jogo e vá em frente! Desfrute de uma experiência de jogo descomplicada 24 horas por dia, 7 dias por semana no TGSlots, com suporte profissional ao cliente em espera 24 horas por dia, 7 dias por semana também.",
    openTips: "Abrir slots TGS",
    download: "Baixar telegrama",
  },
};
