<template>
  <div class="navStaking mainBgColor spacing">
    <a name="Staking" class="hidden-anchor"></a>
    <div class="pledgeCoinLogoImg">
      <span class="white block-name">{{ $t("message.stakingName") }}</span>
      <div class="desc gray m-t-20">{{ $t("message.navStakTips") }}</div>
    </div>
    <div class="step">
      <el-row :gutter="20">
        <el-col
          :span="6"
          v-for="(item, index) in stakList"
          :key="index"
          @click="selectCard(item, index)"
        >
          <div
            class="eth-btn white"
            :class="activeIndex === index ? 'isActive' : ''"
          >
            <span>{{ $t(item.name) }}</span>
            <div>{{ item.value }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="webAccount.address">
      <div class="my-pledge">
        <div class="flex-center justify-between">
          <div class="actionPledgeTitle white">
            {{ $t("message.youStaking") }}
          </div>
          <div class="flex-center wallet-text">
            <span class="m-r-8">{{ formatString(webAccount.address) }}</span
            ><img
              class="icon-24 cursor"
              src="@/assets/image/quit.png"
              alt=""
              @click="quitWallet()"
            />
          </div>
        </div>

        <div class="minePledge white">
          <div class="minePledgeItem">
            <p class="actionPledgeDesc">{{ $t("message.staked1") }}</p>
            <el-input
              type="number"
              disabled=""
              class="ant-input ant-input-disabled"
              v-model="totalPledgedAmount"
            />
          </div>
          <div class="minePledgeItem">
            <p class="actionPledgeDesc">{{ $t("message.staked2") }}</p>
            <el-input
              disabled=""
              class="ant-input ant-input-disabled"
              type="text"
              v-model="totalPledgedRate"
            />
          </div>
          <div class="minePledgeItem">
            <p class="actionPledgeDesc">{{ $t("message.harvested") }}</p>
            <el-input
              type="number"
              disabled=""
              class="ant-input ant-input-disabled"
              v-model="totalReward"
            />
          </div>
          <div class="minePledgeItem">
            <p class="actionPledgeDesc">{{ $t("message.claimable") }}</p>
            <div>
              <el-input
                type="number"
                disabled=""
                class="ant-input ant-input-disabled"
                v-model="reciveReward"
              >
                <template #append>
                  <el-button type="primary" @click="getReceiveReward">{{
                    $t("message.receiveTips")
                  }}</el-button>
                </template>
              </el-input>
            </div>
          </div>
        </div>
        <div class="actionPledge">
          <el-row :gutter="80">
            <el-col :span="12">
              <div class="goPledge">
                <div class="actionPledgeTitle">{{ $t("message.stake") }}</div>
                <div class="actionPledgeDesc">
                  {{ $t("message.indicatedAPY") }}
                </div>
                <div style="position: relative">
                  <el-input
                    v-model="stakeValue"
                    style="max-width: 600px"
                    placeholder="Please input"
                  >
                    <template #append>
                      <el-button
                        type="primary"
                        class="maxVal"
                        @click="clickMaxSatke()"
                        >{{ $t("message.max") }}</el-button
                      >
                    </template>
                  </el-input>
                </div>
                <div class="goPledgeBtn">
                  <div class="btn cursor" @click="openLearnMore()">
                    {{ $t("message.learnMore") }}
                  </div>
                  <el-button
                    type="primary"
                    class="btn m-l-17"
                    :disabled="stakeValue === 0 ? true : false"
                    @click="clickStake()"
                    >{{ $t("message.stake") }}</el-button
                  >
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="outPledge">
                <div class="actionPledgeTitle">{{ $t("message.unStake") }}</div>
                <div class="actionPledgeDesc">
                  {{ $t("message.unStakeTips") }}
                </div>
                <div>
                  <el-input
                    v-model="unStakeValue"
                    style="max-width: 600px"
                    placeholder="Please input"
                  >
                    <template #append>
                      <el-button
                        type="primary"
                        class="maxVal"
                        @click="clickMaxUn()"
                        >{{ $t("message.max") }}</el-button
                      >
                    </template>
                  </el-input>
                </div>
                <div class="goPledgeBtn">
                  <div class="btn cursor" @click="openLearnMore()">
                    {{ $t("message.learnMore") }}
                  </div>
                  <el-button
                    type="primary"
                    class="btn m-l-17"
                    :disabled="unStakeValue === 0 ? true : false"
                    @click="clickUnStake()"
                    >{{ $t("message.unStake") }}</el-button
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-button v-else class="white-btn" type="primary" @click="openWeb3()">{{
      $t("message.connectWallet")
    }}</el-button>
  </div>
</template>

<script>
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useDisconnect,
} from "@web3modal/ethers/vue";
import Web3 from "web3";
import Web3Modal from "web3modal";
import commonWeb3 from "@/api/web3";
import { baseURL } from "@/api/config";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      stakList: [
        {
          name: "message.blockStakName1",
        },
        {
          name: "message.blockStakName2",
        },
        {
          name: "message.blockStakName3",
        },
        {
          name: "message.blockStakName4",
        },
      ],
      stakingContent: {
        currentAPY: 0,
        tgsStaked: 0,
        tgsStakedRate: 0,
        rewardsPaid: 0,
      },
      totalPledgedRate: 0,
      totalPledgedAmount: 0,
      totalReward: 0,
      reciveReward: 0,
      activeIndex: 0,
      isShowMore: false,
      webAccount: {
        address: "",
      },
      web3: "",
      firstBlock: baseURL.FIRSTBLOCK,
      exponent: 18,
      currentBlock: "",
      // token
      tokenContract: {},
      tokenAbi: commonWeb3.tokenAbi,
      tokenAddress: baseURL.TOKEN_ADDRESS, // token 合约地址
      // stakingManagerV1
      stakingManagerV1Contract: {},
      stakingAbi: commonWeb3.stakingAbi,
      stakingAddress: baseURL.STAKINGMANAGERV1_ADDRESS, // StakingManagerV1 合约地址
      // presale
      presaleContract: {},
      presaleAbi: commonWeb3.presaleAbi,
      presaleAddress: baseURL.PRESALEV1_PROXY_ADDRESS, // PresaleV1_Proxy 合约地址
      totalPrizePool: null, // 总奖池
      endDay: baseURL.END_DAY,
      startDay: null,
      pledgeDay: null, // 质押天数
      pledgeHour: null, // 质押小时数
      twentyPercent: baseURL.TWENTY_PERCENT,
      stakeValue: 0, // 质押数
      unStakeValue: 0, // 解质押数
      isApprove: false,
      comparisonValue: 0, // 比较值
      unlimitedValue: 0, // 无限值,
      otherWeb3: null,
      noConnectTokenContract: {},
      noConnectStakeContract: {},
      noConnectPresaleContract: {},
      twentyMillon: baseURL.TWENTY_MILLION,
      tgsStakedBalanceWei: 0,
    };
  },
  created() {
    this.webAccount = useWeb3ModalAccount();
    this.getNoConnectContract();
  },
  watch: {
    webAccount: {
      handler(newValue) {
        if (newValue.address && newValue.isConnected) {
          this.getConnectContract();
        }
      },
      deep: true, // 深度监听
      immediate: true,
    },
  },
  methods: {
    // 未连接钱包
    async getNoConnectContract() {
      this.otherWeb3 = new Web3(baseURL.RPCURL_API);
      this.noConnectTokenContract = new this.otherWeb3.eth.Contract(
        this.tokenAbi,
        this.tokenAddress
      );
      this.noConnectStakeContract = new this.otherWeb3.eth.Contract(
        this.stakingAbi,
        this.stakingAddress
      );
      this.getPledgeDays();
      this.getTokenBalance();
      this.getBlockNumber();
    },
    truncateDecimals(num, decimals) {
      const re = new RegExp("(\\d+\\.\\d{" + decimals + "})(\\d)"),
        m = num.toString().match(re);
      return m ? parseFloat(m[1]) : num.valueOf();
    },
    // 获取时间
    getPledgeDays() {
      const today = new Date(); // 获取当天日期
      const nextYear = new Date( // 获取明年的今天
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      );
      const timeDiff = nextYear - today;
      this.pledgeDay = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // 剩余质押天数---pledgeDay
      this.pledgeHour = this.pledgeDay * 24; // 剩余质押小时数
    },
    // 未连接钱包调用合约，读取数据
    async getTokenBalance() {
      // Total TGS Staked
      const tokensStaked = await this.noConnectStakeContract.methods
        .tokensStaked()
        .call();
      // 总质押
      const tokensStakedWei = parseFloat(
        this.otherWeb3.utils.fromWei(tokensStaked, "ether")
      );
      const tgsStakedBalance = await this.noConnectTokenContract.methods
        .balanceOf(this.stakingAddress)
        .call();
      this.tgsStakedBalanceWei = this.otherWeb3.utils.fromWei(
        tgsStakedBalance,
        "ether"
      );
      this.stakingContent.tgsStaked = parseFloat(
        this.twentyMillon + tokensStakedWei - this.tgsStakedBalanceWei
      ).toFixed(2);
      this.stakList[1]["value"] = this.stakingContent.tgsStaked;
      // const text =
      //   "(" +
      //   this.twentyMillon +
      //   "+" +
      //   tokensStakedWei +
      //   ")" +
      //   "-" +
      //   tgsStakedBalanceWei;
      // console.log(
      //   text,
      //   "总待领取: (20亿+总质押)-token.balanceOf( StakingManagerV1)"
      // );
      // % of Total $TGS Staked %
      const presale = this.otherWeb3.utils.fromWei(
        await this.noConnectTokenContract.methods
          .balanceOf(this.presaleAddress)
          .call(),
        "ether"
      );
      if (this.stakingContent.tgsStaked !== 0 && presale !== 0) {
        const number = (this.stakingContent.tgsStaked / presale) * 100;
        this.stakingContent.tgsStakedRate = this.truncateDecimals(number, 2);
      }
      this.stakList[2]["value"] = this.stakingContent.tgsStakedRate + "%";
    },
    async getBlockNumber() {
      this.currentBlock = await this.otherWeb3.eth.getBlockNumber(); //
      const rewardTokensPerBlock = await this.noConnectStakeContract.methods
        .rewardTokensPerBlock()
        .call();
      this.stakingContent.rewardsPaid = (
        (parseInt(this.currentBlock) - this.firstBlock) *
        this.otherWeb3.utils.fromWei(rewardTokensPerBlock, "ether")
      ).toFixed(2);
      this.stakList[3]["value"] = this.stakingContent.rewardsPaid;
      // Current APY
      const poolPrice = await this.noConnectTokenContract.methods
        .totalSupply()
        .call();
      const totalSupply = this.otherWeb3.utils.fromWei(poolPrice, "ether");
      this.totalPrizePool = totalSupply * this.twentyPercent; // 总奖池
      if (this.stakingContent.tgsStaked !== 0) {
        this.stakingContent.currentAPY =
          ((this.totalPrizePool - this.stakingContent.rewardsPaid) /
            this.stakingContent.tgsStaked) *
          (365 / this.pledgeDay) *
          100;
      }
      this.stakList[0]["value"] =
        this.stakingContent.currentAPY.toFixed(2) + "%";
    },
    // 了解更多
    openLearnMore() {
      window.open(baseURL.WHITEPAPER_URL);
    },
    formatString(str) {
      return str.substring(0, 4) + "****" + str.substring(str.length - 4);
    },
    async quitWallet() {
      useDisconnect().disconnect();
    },
    // 链接钱包
    openWeb3() {
      useWeb3Modal().open();
    },
    async getConnectContract() {
      const web3Modal = new Web3Modal({
        cacheProvider: true, // 是否缓存提供商信息
        disableInjectedProvider: false, // 是否禁用浏览器上已注入的提供商（例如 MetaMask）
        fullScreen: false, // 是否使用全屏模式显示 Web3Modal 登录框
        network: "mainnet", // 连接的区块链网络（例如 mainnet，rinkeby，ropsten）
        theme: "light", // 选择 Web3Modal 登录框的主题，可选值有“light”（浅色主题）和“dark”（深色主题）
      });
      const provider = await web3Modal.connect();
      // console.log(provider, "---提供者---");
      this.web3 = new Web3(provider);
      // console.log(this.web3, "this.web3");
      this.tokenContract = new this.web3.eth.Contract(
        this.tokenAbi,
        this.tokenAddress
      );
      // console.log(this.tokenContract, "this.tokenContract");
      this.stakingManagerV1Contract = new this.web3.eth.Contract(
        this.stakingAbi,
        this.stakingAddress
      );
      // console.log(this.stakingManagerV1Contract, "this.stakingManagerV1");
      this.presaleContract = new this.web3.eth.Contract(
        this.presaleAbi,
        this.presaleAddress
      );
      const poolStakers = await this.stakingManagerV1Contract.methods
        .poolStakers(this.webAccount.address)
        .call();
      this.totalReward = parseInt(
        this.web3.utils.fromWei(poolStakers.harvestedRewards, "ether")
      );
      this.totalPledgedAmount = parseInt(
        this.web3.utils.fromWei(poolStakers.amount, "ether")
      );
      const tgsStaked = await this.tokenContract.methods
        .balanceOf(this.stakingAddress)
        .call();
      const stakingTgsStaked = parseInt(
        this.web3.utils.fromWei(tgsStaked, "ether")
      ).toFixed(2);
      this.totalPledgedRate = parseFloat(
        this.totalPledgedAmount / stakingTgsStaked
      ).toFixed(18);
    },
    selectCard(item, index) {
      this.activeIndex = index;
    },
    async getReceiveReward() {
      const reward = await this.stakingManagerV1Contract.methods
        .getRewards(this.webAccount.address)
        .call();
      this.reciveReward = parseInt(this.web3.utils.fromWei(reward, "ether"));
    },
    // 质押
    async clickMaxSatke() {
      const tokenBalance = await this.tokenContract.methods
        .balanceOf(this.webAccount.address)
        .call();
      const tokenWeiBalance = parseInt(
        this.web3.utils.fromWei(tokenBalance, "ether")
      );
      const stakeValue = tokenWeiBalance - this.totalPledgedAmount;
      stakeValue <= 0 ? (this.stakeValue = 0) : (this.stakeValue = stakeValue);
    },
    async clickStake() {
      // eslint-disable-next-line no-undef
      const value = BigInt(10) ** BigInt(18) - BigInt(1);
      this.comparisonValue = this.web3.utils.toWei(value.toString(), "ether");
      this.approveTokenCoin = await this.tokenContract.methods
        .allowance(this.webAccount.address, this.stakingAddress)
        .call();
      if (parseInt(this.approveTokenCoin) < parseInt(this.comparisonValue)) {
        this.isApprove = false;
      } else {
        this.isApprove = true;
      }
      if (this.isApprove) {
        this.deposit();
      } else {
        this.unlimitedValue = await this.web3.utils
          .toBN("2")
          .pow(this.web3.utils.toBN("256"))
          .sub(this.web3.utils.toBN("1"))
          .toString();
        try {
          this.tokenContract.methods
            .approve(this.stakingAddress, this.unlimitedValue)
            .send({
              from: this.webAccount.address,
            })
            .then(() => {
              this.deposit();
            });
        } catch (error) {
          // console.error("Error:", error);
        }
      }
    },
    async deposit() {
      try {
        await this.stakingManagerV1Contract.methods
          .deposit(this.stakeValue)
          .send({
            from: this.webAccount.address,
          })
          .then(() => {});
      } catch (error) {
        // console.error("Error:", error);
      }
    },
    async clickMaxUn() {
      const poolStakers = await this.stakingManagerV1Contract.methods
        .poolStakers(this.webAccount.address)
        .call();
      this.unStakeValue = parseInt(
        this.web3.utils.fromWei(poolStakers.amount, "ether")
      );
    },
    async clickUnStake() {
      ElMessage.error(`${this.$t("message.unStakeErrorTips")}`);
      // await this.stakingManagerV1Contract.methods.withdraw().send({
      //   from: this.webAccount.address,
      //   value: this.unStakeValue,
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  background: #2e3442 !important;
  border: none !important;
  box-shadow: none !important;
}

::v-deep .el-input__inner {
  color: #fff !important;
}

::v-deep .el-input-group__append {
  background: #0d99ff !important;
  border: 1px solid #0d99ff;
  color: #fff;
  box-shadow: 0 1px 0 0 #0d99ff inset, 0 -1px 0 0 #0d99ff inset,
    -1px 0 0 0 #0d99ff inset;
}

::v-deep .el-button > span {
  font-size: 14px !important;
}

.navStaking {
  overflow: hidden;

  .wallet-text {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 400px;
  }

  .eth-btn {
    height: 35px;
    margin: 10px 0;
    background: #2e3442;
    border: 1px solid #586174;
    border-radius: 8px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .isActive {
    background: #0d99ff;
    border: 1px solid #0d99ff;
  }

  .white-btn {
    padding: 22px 80px;
    display: flex;
    justify-content: center;
    margin: 40px auto 0;
  }

  .minePledge {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }

  .actionPledgeTitle {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  .actionPledge {
    width: 100%;

    .actionPledgeDesc {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .goPledgeBtn {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .btn {
        color: #fff;
        align-items: center;
        background-color: #0d99ff;
        border-radius: 8px;
        // cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        height: 52px;
        justify-content: center;
        // margin-right: 20px;
        width: 48%;
      }
    }
  }
}
</style>
