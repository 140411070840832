// 阿拉伯语
export default {
  message: {
    unStakeErrorTips:
      "تعهد الرموز لا يعفى من التعهد إلا بعد الادراج في البورصة .",
    copyTips: "استنساخ",
    tgsName: "عنوان رمز TGS $",
    tgsTips:
      "هذا هو رمز العنوان . لا نقل هذا العنوان . إذا كنت ترغب في شراء رمز ، يرجى استخدام ما قبل البيع أصيلة المعكرونة .",
    succeeTips: "تمت الصفقة بنجاح",
    failTips: "فشلت المعاملة، يرجى المحاولة مرة أخرى",
    viewTips: "عرض على مسح BSC: ",
    // Home 模块
    approve: "يعتمد",
    menuHome: "بيت",
    menuPresale: "ما قبل البيع",
    menuWork: "كيف تعمل",
    menuStaking: "مكافآت التوقيع المساحي",
    menuToken: "الاقتصاد الرمزي",
    menuBuy: "كيف اشترى",
    menuFAQ: "التعليمات",
    homeTips: "أفضل فتحات العالم على تيليجرام",
    slot: "فتحات",
    join: "انضم إلى المجتمع",
    play: "العب الان",
    letMeIn: "دعني ادخل",
    presaleTop: "البيع المسبق",
    event: "حدث ما قبل البيع",
    http: "https://TGSlots/#home",
    desc: "TGSlots هو المكان الحصري لألعاب السلوتس عبر الإنترنت وألعاب الكازينو الأخرى على Telegram. مرخصة بالكامل وأموالك آمنة بنسبة 100% مع محافظ غير وصاية. احصل على رمز TGS الخاص بنا لمشاركة جزء من أرباحنا!",
    title: "لماذا تحصل على $TGS في البيع المسبق؟",
    tips1: "مكافآت حصرية",
    currentPrice: "السعر الحالي",
    achieved: "هدفنا",
    increases: "اشتري الآن قبل زيادة الأسعار في:",
    pay: "انت تدفع",
    max: "الأعلى",
    receive: "$TGS تتلقاها",
    buyRequire: "يرجى شراء ما لا يقل عن 100 دولار TGS.",
    enoughTips: "لم يكن لديك ما يكفي",
    enoughTips1: "يأتي لدفع ثمن الصفقة .",
    gasPayTips: "تأكد من أن لديك ما يكفي",
    gasPayTips1: "دفع الغاز الطبيعي",
    gasTips: "وقت العرض",
    gasTips1:
      "tgslots الدعم الكامل من البنزين عند شراء مبلغ TGS . يرجى الاتصال دعم العملاء للحصول على تعويض . ",
    walletTips: "محفظة",
    balanceTips: "التوازن",
    toContact: "انقر للاتصال",
    purchaseTips:
      "إذا لم تتمكن من الشراء بشكل طبيعي باستخدام متصفح طرف ثالث، فيرجى نسخ الرابط إلى المتصفح المدمج في المحفظة للوصول إليه. ",
    copyLink: "نسخ الوصلة",
    apy: "الستاكينغ الحالي APY :",
    buyAndStake: "شراء ومشاركة لـ APY",
    apyTips: "أبي",
    justBuy: "أريد فقط الشراء دون التوقيع المساحي ",
    connectWallet: "ربط المحفظة",
    wallet: "كيفية إضافة $TGS إلى محفظتي",
    // NavStaking
    navStakTips:
      "تتيح لك TGSlots مشاركة الرموز المميزة الخاصة بك وإلغاء ملكيتها. اختر الوظيفة التي تريدها. فقط الرموز المميزة هي التي تكسب APY.",
    blockStakName1: "أبي الحالي",
    blockStakName2: "مجموع $TGS المراهنة",
    blockStakName3: "% من إجمالي $TGS المتراكم ",
    blockStakName4: "إجمالي المكافآت المدفوعة ($TGS)",
    youStaking: "التوقيع المساحي الخاص بك",
    staked1: "مجموع $TGS المراهنة",
    staked2: "% من إجمالي $TGS المتراكم",
    harvested: "إجمالي المكافآت المحصودة ($TGS)",
    claimable: "إجمالي المكافآت المطالب بها ($TGS)",
    receiveTips: "يستلم",
    stake: "حصة",
    unStake: "غير مستقر",
    learnMore: "يتعلم أكثر",
    indicatedAPY:
      "قم بتوزيع الرموز المميزة الخاصة بك للحصول على مكافآت سلبية بناءً على APY المشار إليه",
    unStakeTips:
      "قم بإلغاء ملكية الرموز الخاصة بك واسترد مكافآتك من التوقيع المساحي",
    //preSale 模块
    preSaleName: "لماذا تحصل على $TGS في البيع المسبق؟",
    presaleTitle1: "مكافآت حصرية",
    presaleTips1:
      "يحق للمشاركين في البيع المسبق لدينا الحصول على مكافآت حصرية عندما يلعبون TGSlots",
    presaleTitle2: "أفضل سعر على الإطلاق",
    presaleTips2:
      "لن يكون هناك وقت أفضل للحصول على $TGS من وقت البيع المسبق لأن $TGS سوف يزدهر مع نمو إيرادات منصتنا.",
    presaleTitle3: "مكافآت للهاي رولر",
    presaleTips3:
      "استثمر 5 آلاف دولار أمريكي أو أكثر في البيع المسبق الخاص بنا للحصول على مكافأة برموز NFT حصرية ومكافآت أخرى بعد الإطلاق!",
    presaleTitle4: "ابدأ في الربح اليوم",
    presaleTips4:
      "احصل على $TGS في البيع المسبق لدينا وستتمكن من المشاركة على الفور لفتح المكافآت على الفور!",
    rewards1:
      " يحق للمشاركين في البيع المسبق لدينا الحصول على مكافآت حصرية عندما يلعبون أفضل سعر على الإطلاق في TGSlots",
    tips2: "أفضل سعر على الإطلاق",
    rewards2:
      "لن يكون هناك وقت أفضل للحصول على $TGS من وقت البيع المسبق لأن $TGS سوف يزدهر مع نمو إيرادات منصتنا.",
    tips3: "مكافآت للهاي رولر",
    rewards3:
      "استثمر 5 آلاف دولار أمريكي أو أكثر في البيع المسبق الخاص بنا للحصول على مكافأة برموز NFT حصرية ومكافآت أخرى بعد الإطلاق!",
    tips4: "ابدأ في الربح اليوم",
    rewards4:
      "احصل على $TGS في البيع المسبق لدينا وستتمكن من المشاركة على الفور لفتح المكافآت على الفور!",
    // Work 模块
    work: "كيف تعمل",
    workTips:
      "لدى TGSlots كل ما يقدمه الكازينو عبر الإنترنت، بالإضافة إلى نظام الحوافز الفريد لدينا الذي يجعل المستخدمين مساهمين لدينا ويتم مكافأتهم باستمرار من خلال الاحتفاظ بـ $TGS",
    stakTitle: "مكافآت التوقيع المساحي",
    stakTips:
      "من خلال الرهان على TGS بقيمة $ التي تم شراؤها خلال فترة ما قبل البيع والتي تم ربحها داخل اللعبة، ستصبح مساهمًا لدينا وستشارك جزءًا من أرباحنا بسعر APY مرتفع!",
    incomTitle: "احصل على دخل سلبي",
    incomTips:
      "شاهد مقتنياتك من $TGS تنمو بمرور الوقت، حتى أثناء نومك. يحدث السحر في اللحظة التي تقوم فيها بالمراهنة بـ TGS الخاص بك",
    giftTitle: "المطالبة بالمكافآت على الفور",
    giftTips:
      "يمكنك المطالبة بالمكافآت من التوقيع المساحي متى شئت. يمكنك استخدامها كرقائق في TGSlots، أو إعادة وضعها مرة أخرى في مجموعة الستاكينغ للحصول على المزيد من المكافآت!",
    cashTitle: "المزيد من فرص الربح",
    cashTips:
      "عندما تقوم بحصة $TGS، فإنك تفتح إمكانية الوصول إلى المكافآت بناءً على إيرادات منصتنا. تحقق من صفحة التوقيع المساحي الخاصة بنا من وقت لآخر للحصول على التحديثات!",
    tokenTitle: "الاقتصاد الرمزي",
    tokenTips:
      "تم تصميم اقتصاديات الرمز المميز لدينا لمواءمة الحوافز وتحقيق وضع مستدام مربح للجانبين لجميع حاملي الرمز المميز المعنيين.",
    itemTitle1: "ما قبل البيع",
    itemTitle2: "مكافآت التوقيع المساحي",
    itemTitle3: "مجمع السيولة",
    itemTitle4: "مكافآت اللاعبين",
    itemTitle5: "الوكيل التعاوني",
    itemTitle6: "المستثمرين المؤسسيين",
    itemTitle7: "خزينة المجتمع",
    itemTitle8: "تسويق",
    itemTips1: "لبدء تشغيل النظام البيئي $TGS",
    itemTips2: "لمكافأة أصحابنا المتفانين",
    itemTips3: "لإنشاء الزوج على DEX وCEX",
    itemTips4: "مكافآت لكل لاعب",
    itemTips5: "مكافآت لأولئك الذين يساعدون في تطوير TGSlots",
    itemTips6: "محفوظة للمؤسسات الاستثمارية التي تستثمر في TGSlots",
    itemTips7: "للاحتياطي الاستراتيجي للمجتمع",
    itemTips8: "للتعاون والتسويق المباشر",
    jumpName: "الحق في القفز",
    jumpTips:
      "ما عليك سوى فتح TGSlots في Telegram وتعبئة حسابك بالعملات المشفرة / بطاقات الائتمان الرئيسية للبدء!",
    safeTips1: "المرخصة وغير الاحتجازية",
    safeTips2: "لا حاجة لإعداد الحساب",
    safeTips3: "العب على هاتفك الذكي",
    safeTips4: "متكاملة تماما في برقية",
    jumpPlay: "العب الان",
    whitepaper: "ورق ابيض",
    whitepaperTips:
      "بالنسبة للأشخاص الذين لديهم نفس القدر من الجدية بشأن TGSlots مثلنا، فقد قمنا بإعداد المستند التقني الذي يستكشف التفاصيل والأفكار الكامنة وراء إنشاء TGSlots. اقرأها لترى كيف نختلف عن الكازينوهات الأخرى على الإنترنت!",
    FqName: "أسئلة مكررة",
    FqTitle1: "ما الذي يجعل TGSlots مختلفًا؟",
    FqTips1:
      "تمنحك TGSlots إمكانية الوصول إلى أفضل ألعاب الكازينو عبر الإنترنت والكتب الرياضية والمزيد، ولكننا ملتزمون أيضًا بتعزيز النظام البيئي الذي يقدر الولاء. وكدليل على تفانينا، فإننا نرد الجميل من خلال مشاركة جزء من إيرادات الكازينو لدينا من خلال عمليات إعادة شراء رمز TGS المميز. العب واكسب واحصل على المكافأة!",
    FqTitle2: "ما هي مدة العرض المسبق؟",
    FqTips2:
      "سيظل البيع المسبق مفتوحًا حتى الوصول إلى الحد الأقصى. الحد الأقصى الناعم للبيع المسبق هو 2 مليون دولار أمريكي.",
    FqTitle3: "لماذا يجب أن أشتري $TGS؟",
    FqTips3:
      "في حين أن TGSlots ستتيح لك اللعب بالعديد من أفضل العملات المشفرة، فإن TGS $ فقط هي التي ستمنحك إمكانية الوصول إلى مكافآت مذهلة، سواء من التوقيع المساحي أو مجرد الاحتفاظ بالرموز الخاصة بنا. إن $TGS ليس مطلوبًا للعب في TGSlots، ولكن يمكن استخدامه للمراهنة في الألعاب مثل جميع العملات الأخرى.",
    FqTitle4: "هل يمكنني الحصول على رموز TGS $ من البيع المسبق على الفور؟",
    FqTips4:
      "لا توجد فترة استحقاق لـ TGS. ومع ذلك، إذا قمت بتخزين الرموز المميزة الخاصة بك أثناء البيع المسبق، فسيتم قفلها حتى يوم الإطلاق + 7 أيام. يبدأ تخزين الرموز على الفور في الحصول على مكافآت التوقيع والمزايا المستقبلية الأخرى.",
    FqTitle5: "متى سيتم إطلاق الرمز؟",
    FqTips5:
      "سنطلق $TGS خلال أيام من انتهاء البيع المسبق. سنبقي الجميع على علم بيوم الإطلاق من خلال موقعنا الإلكتروني وقنوات التواصل الاجتماعي.",
    stakingName: "التوقيع المساحي",
    stakingTips:
      "تتيح لك TGSlots مشاركة رموز TGS الخاصة بك وإلغاء ملكيتها متى شئت. لكن كن حذرًا، فالرموز المميزة فقط هي التي تكسب APY! تفاصيل",
    sakeips1: "أبي الحالي",
    sakeips2: "إجمالي $TGS في مجموعة التوقيع المساحي",
    sakeips3: "نسبة الملكية الخاصة بك في Stake Pool ($TGS)",
    sakeips4: "إجمالي العائد ($TGS)",
    // Buy 模块
    buyName: "كيف اشترى",
    buyTips:
      "لشراء العملات خلال فترة ما قبل البيع، قم بزيارة الموقع الرسمي لـ TGSlots. بعد البيع المسبق، يمكن الحصول على الرموز مباشرة من الموقع الرسمي أو من خلال البورصات اللامركزية.",
    buyTitle: "كيفية شراء $TGS خلال فترة ما قبل البيع",
    buyStepName1: "الخطوة 1: قم بزيارة البوابة الرسمية",
    buySteTips1:
      "تفضل بزيارة صفحة الويب المخصصة لـ TGSlots للعثور على واجهة الشراء المسبق الحصرية. يرجى ملاحظة أن هذه هي المنصة الوحيدة المعتمدة للحصول على رموز TGS للبيع المسبق.",
    buyStepName2: "الخطوة 2: اختر طريقة الدفع",
    buySteTips2:
      "يمكنك اختيار شراء الرمز المميز Presale $TGS على شبكة Ethereum باستخدام Ethereum (ETH) أو Tether (USDT). وبدلاً من ذلك، يمكنك الاستفادة من Binance Smart Chain واختيار BNB أو USDT. حدد اختيارك وانتقل إلى الخطوة التالية.",
    buyStepName3: "الخطوة 3: قم بتوصيل محفظتك المشفرة",
    buySteTips3:
      "إذا لم تتم مزامنة محفظتك المشفرة بعد، فسترى مطالبة تطلب منك الاتصال. عادةً، يتم إعداد المحافظ لشبكة Ethereum الرئيسية افتراضيًا؛ وهذا يعمل لصالحك إذا كنت تتداول مع ETH. ومع ذلك، إذا اخترت Binance Smart Chain، فقد تحتاج إلى تأكيد تبديل الشبكة.",
    buyStepName4: "الخطوة 4: تحديد مبلغ الشراء",
    buySteTips4:
      "أدخل مبلغ رموز TGS التي ترغب في شرائها. يرجى ملاحظة أنه بالإضافة إلى تكلفة الرمز المميز الذي تنوي شراءه، يلزم وجود حد أدنى قدره 0.015 ETH لإكمال المعاملة. في Binance Smart Chain، يكون هذا الحد الأدنى من المتطلبات أقل، ولكنه لا يزال ساريًا. تم تعيين الحد الأدنى لمبلغ رموز TGS $ التي يمكن شراؤها على 100 $ TGS.",
    buyStepName5: "الخطوة 5: أكمل عملية الشراء",
    buySteTips5:
      "بمجرد إدخال المبلغ، سيُطلب منك الموافقة على المعاملة. بالنقر فوق ،'موافقة' سيتم بدء معاملتك والتحقق منها على blockchain. يمكن أن تختلف أوقات إتمام المعاملات من ثوانٍ إلى دقائق، اعتمادًا على ازدحام الشبكة ونشاط العقد الذكي. يرجى البقاء على الواجهة حتى يتم تنفيذ عملية الشراء بالكامل.",
    // Footer
    contactUs: "اتصل بنا",
    footerText: "tgslots جميع الحقوق محفوظة",
    privacy: "خصوصية",
    terms: "شروط",
    cookie: "خصوصية",
    stepName: "خطوة",
    fundName: "العب بشكل مجهول مع أموالك الآمنة",
    fundDesc:
      "بفضل الطبيعة اللامركزية لتقنية blockchain، فإننا لا نطلب منك KYC ولا نحتاج إلى تخزين أموالك بطريقة مركزية. وبالتالي لا تحتاج إلى تسليم معلوماتك الشخصية ولا داعي للقلق بشأن سلامة أموالك. كل ما تحتاجه هو حساب Telegram، وبعض العملات المشفرة، وستكون جاهزًا للانطلاق!",
    fundTips1:
      "إذا لم يكن لديك حساب على Telegram حتى الآن، فيرجى تنزيل التطبيق وإنشاء حساب. بعد ذلك، افتح TGSlots عبر رابط 'Open TGSlots' أعلاه. إذا كان لديك حساب Telegram بالفعل، فانقر فوق 'Open TGSlots' للبدء على الفور!",
    fundTips2:
      "يمكنك اختيار تحويل الأموال إلى حساب TGSlots الخاص بك إما من محفظتك المشفرة أو حساب CEX الخاص بك أو بطاقتك الائتمانية (عن طريق تعبئة محفظة TON الخاصة بك). بمجرد الانتهاء من الإيداع، أنت جاهز للانطلاق!",
    fundTips3:
      "اختر لعبة وابدأ! استمتع بتجربة لعب خالية من المتاعب على مدار الساعة طوال أيام الأسبوع في TGSlots، مع دعم عملاء محترف في وضع الاستعداد على مدار الساعة طوال أيام الأسبوع أيضًا.",
    openTips: "افتح فتحات TGS",
    download: "تحميل برقية",
  },
};
