// 印地语
export default {
  message: {
    // 交易提示
    unStakeErrorTips:
      "प्रतिज्ञा के टोकन सिर्फ बदले पर सूचीबद्ध के बाद केवल प्रतिज्ञा से छोड़ सकते हैं.",
    copyTips: "कॉपी",
    tgsName: "$TGS टोकन पता",
    tgsTips:
      "यह टोकन पता है। इस पते पर ट्रांसफर न करें। यदि आप टोकन खरीदना चाहते हैं, तो कृपया प्री-सेल असली नूडल्स का उपयोग करें।",
    succeeTips: "लेन-देन सफल हुआ",
    failTips: "लेन-देन विफल, कृपया पुनः प्रयास करें",
    viewTips: "BSC स्कैन पर देखें: ",
    approve: "मंज़ूरी देना",
    menuHome: "घर",
    menuPresale: "पूर्व बिक्री",
    menuWork: "यह काम किस प्रकार करता है",
    menuStaking: "स्टेकिंग पुरस्कार",
    menuToken: "टोकन अर्थशास्त्र",
    menuBuy: "कैसे खरीदे",
    menuFAQ: "सामान्य प्रश्न",
    homeTips: "टेलीग्राम पर दुनिया की सर्वश्रेष्ठ स्लॉट मशीनें",
    slot: "स्लॉट",
    join: "समुदाय से जुड़ें",
    play: "अब खेलते हैं",
    letMeIn: "मुझे अंदर आने दो",
    presaleTop: "पूर्व बिक्री",
    event: "प्री-सेल इवेंट",
    http: "https://TGSlots/#home",
    desc: "TGSlots टेलीग्राम पर ऑनलाइन स्लॉट और अन्य कैसीनो गेम के लिए विशेष स्थान है। पूरी तरह से लाइसेंस प्राप्त और नॉन-कस्टोडियल वॉलेट के साथ आपके फंड 100% सुरक्षित हैं। हमारी कमाई का एक हिस्सा साझा करने के लिए हमारा $TGS टोकन प्राप्त करें!",
    title: "प्री-सेल में $TGS क्यों प्राप्त करें?",
    tips1: "विशेष पुरस्कार",
    currentPrice: "मौजूदा कीमत",
    achieved: "हमारा लक्ष्य",
    increases: "कीमत बढ़ने से पहले अभी खरीदें:",
    pay: "तुम भुगतान दो",
    max: "मैक्स",
    receive: "आपको प्राप्त होने वाला $TGS",
    buyRequire: "कृपया कम से कम 100 $TGS खरीदें।",
    enoughTips: "तुम्हारे पास पर्याप्त नही है",
    enoughTips1: "इस लेनदेन के लिए भुगतान करने हेतु.",
    gasPayTips: "सुनिश्चित करो कि आपके पास पर्याप्त है",
    gasPayTips1: "गैस पैसा के लिए",
    gasTips: "सीमित- समय आफ़र",
    gasTips1:
      "जब आप $TGS खरीद करते हैं, कृपया ग्राहक समर्थन के लिए संपर्क करें. ",
    walletTips: "वालेट",
    balanceTips: "संतुलन",
    toContact: "संपर्क करने के लिए क्लिक करें",
    purchaseTips:
      "यदि आप सामान्य रूप से किसी तृतीय-पक्ष ब्राउज़र का उपयोग करके खरीदारी नहीं कर सकते हैं, तो कृपया इसे एक्सेस करने के लिए वॉलेट के अंतर्निहित ब्राउज़र के लिंक को कॉपी करें।",
    copyLink: "लिंक की प्रतिलिपि करें",
    apy: "वर्तमान स्टेकिंग APY :",
    buyAndStake: "APY के लिए खरीदें और हिस्सेदारी करें",
    apyTips: "एपीवाई",
    justBuy: "बस बिना किसी दांव के खरीदना चाहते हैं",
    connectWallet: "वॉलेट कनेक्ट करें",
    wallet: "अपने वॉलेट में $TGS कैसे जोड़ें?",
    navStakTips:
      "TGSlots आपको अपने टोकन को दांव पर लगाने और हटाने की सुविधा देता है। आप जो भी सुविधा चाहते हैं, उसे चुनें। केवल दांव पर लगाए गए टोकन ही APY कमाते हैं।",
    blockStakName1: "वर्तमान एपीवाई",
    blockStakName2: "कुल $TGS दांव पर लगा दिया गया",
    blockStakName3: "कुल $TGS स्टेक का ",
    blockStakName4: "कुल भुगतान किए गए पुरस्कार ($TGS)",
    youStaking: "आपका स्टेकिंग",
    staked1: "कुल $TGS दांव पर लगा",
    staked2: "कुल $TGS स्टेक का %",
    harvested: "कुल अर्जित पुरस्कार ($TGS)",
    claimable: "दावा योग्य कुल पुरस्कार ($TGS)",
    receiveTips: "प्राप्त करें",
    stake: "दांव",
    unStake: "अनस्टेक",
    learnMore: "और अधिक जानें",
    indicatedAPY:
      "संकेतित APY के आधार पर निष्क्रिय पुरस्कार प्राप्त करने के लिए अपने टोकन को दांव पर लगाएं",
    unStakeTips:
      "अपने टोकन अनस्टेक करें और स्टेकिंग से अपने पुरस्कार रिडीम करें",
    preSaleName: "प्री-सेल में $TGS क्यों प्राप्त करें?",
    presaleTitle1: "विशेष पुरस्कार",
    presaleTips1:
      "हमारे प्रीसेल में भाग लेने वाले प्रतिभागी TGSlots खेलने पर विशेष पुरस्कार के हकदार होते हैं",
    presaleTitle2: "अब तक की सबसे अच्छी कीमत",
    presaleTips2:
      "प्री-सेल के दौरान $TGS प्राप्त करने के लिए इससे बेहतर समय कभी नहीं होगा, क्योंकि हमारे प्लेटफॉर्म का राजस्व बढ़ने के साथ ही $TGS भी बढ़ेगा।",
    presaleTitle3: "हाई रोलर के लिए पुरस्कार",
    presaleTips3:
      "अनन्य NFTs और अन्य पोस्ट-लॉन्च पुरस्कारों के साथ पुरस्कृत होने के लिए हमारी प्री-सेल में 5K USD या अधिक का निवेश करें!",
    presaleTitle4: "आज से कमाई शुरू करें",
    presaleTips4:
      "हमारी प्री-सेल में $TGS प्राप्त करें और आप तुरंत पुरस्कार अनलॉक करने के लिए तुरंत दांव लगा सकेंगे!",
    rewards1:
      "हमारे प्रीसेल में भाग लेने वाले प्रतिभागी TGSlots Best Price Ever खेलने पर विशेष पुरस्कार के हकदार हैं",
    tips2: "अब तक की सबसे अच्छी कीमत",
    rewards2:
      "प्री-सेल के दौरान $TGS प्राप्त करने के लिए इससे बेहतर समय कभी नहीं होगा, क्योंकि हमारे प्लेटफॉर्म का राजस्व बढ़ने के साथ ही $TGS भी बढ़ेगा।",
    tips3: "हाई रोलर के लिए पुरस्कार",
    rewards3:
      "अनन्य NFTs और अन्य पोस्ट-लॉन्च पुरस्कारों के साथ पुरस्कृत होने के लिए हमारी प्री-सेल में 5K USD या अधिक का निवेश करें!",
    tips4: "आज से कमाई शुरू करें",
    rewards4:
      "हमारी प्री-सेल में $TGS प्राप्त करें और आप तुरंत पुरस्कार अनलॉक करने के लिए तुरंत दांव लगा सकेंगे!",
    work: "यह काम किस प्रकार करता है",
    workTips:
      "TGSlots में वह सब कुछ है जो एक ऑनलाइन कैसीनो प्रदान करता है, साथ ही हमारी अनूठी प्रोत्साहन प्रणाली जो उपयोगकर्ताओं को हमारे शेयरधारक बनाती है और $TGS पर पकड़ बनाए रखने से उन्हें लगातार पुरस्कृत किया जाता है",
    stakTitle: "स्टेकिंग पुरस्कार",
    stakTips:
      "प्री-सेल के दौरान खरीदे गए और गेम में अर्जित $TGS को दांव पर लगाकर, आप हमारे शेयरधारक बन जाएंगे और उच्च APY पर हमारी कमाई का एक हिस्सा साझा करेंगे!",
    incomTitle: "निष्क्रिय आय अर्जित करें",
    incomTips:
      "अपने $TGS होल्डिंग्स को समय के साथ बढ़ते हुए देखें, यहाँ तक कि जब आप सो रहे हों तब भी। जादू उस पल होता है जब आप अपना $TGS दांव पर लगाते हैं",
    giftTitle: "तुरंत पुरस्कार का दावा करें",
    giftTips:
      "आप जब चाहें स्टेकिंग से रिवॉर्ड का दावा कर सकते हैं। आप इसे TGSlots में चिप्स के रूप में इस्तेमाल कर सकते हैं, या और भी ज़्यादा रिवॉर्ड के लिए इसे स्टेकिंग पूल में वापस स्टेक कर सकते हैं!",
    cashTitle: "अधिक कमाई के अवसर",
    cashTips:
      "जब आप $TGS दांव पर लगाते हैं तो आप हमारे प्लेटफ़ॉर्म राजस्व के आधार पर पुरस्कारों तक पहुँच प्राप्त करते हैं। अपडेट के लिए समय-समय पर हमारे स्टेकिंग पेज को देखें!",
    tokenTitle: "टोकन अर्थशास्त्र",
    tokenTips:
      "हमारा टोकन अर्थशास्त्र प्रोत्साहनों को संरेखित करने और इसमें शामिल सभी टोकन धारकों के लिए एक स्थायी जीत की स्थिति प्राप्त करने के लिए डिज़ाइन किया गया है।",
    itemTitle1: "पूर्व बिक्री",
    itemTitle2: "स्टेकिंग पुरस्कार",
    itemTitle3: "तरलता पूल",
    itemTitle4: "खिलाड़ियों को पुरस्कार",
    itemTitle5: "सहकारी एजेंट",
    itemTitle6: "संस्थागत निवेशक",
    itemTitle7: "सामुदायिक खजाना",
    itemTitle8: "विपणन",
    itemTips1: "$TGS पारिस्थितिकी तंत्र को गति देने के लिए",
    itemTips2: "हमारे समर्पित धारकों को पुरस्कृत करने के लिए",
    itemTips3: "DEX और CEX पर जोड़ी बनाने के लिए",
    itemTips4: "प्रत्येक खिलाड़ी के लिए पुरस्कार",
    itemTips5: "TGSlots को बढ़ाने में मदद करने वालों के लिए पुरस्कार",
    itemTips6: "TGSlots में निवेश करने वाले निवेश संगठनों के लिए आरक्षित",
    itemTips7: "सामुदायिक रणनीतिक रिजर्व के लिए",
    itemTips8: "सहयोग और प्रत्यक्ष विपणन के लिए",
    jumpName: "सीधे कूदो",
    jumpTips:
      "बस टेलीग्राम में TGSlots खोलें और आरंभ करने के लिए अपने खाते को प्रमुख क्रिप्टोकरेंसी / क्रेडिट कार्ड के साथ टॉप अप करें!",
    safeTips1: "लाइसेंस प्राप्त और गैर-संरक्षक",
    safeTips2: "कोई खाता सेटअप की आवश्यकता नहीं",
    safeTips3: "अपने स्मार्टफ़ोन पर खेलें",
    safeTips4: "टेलीग्राम में पूर्णतः एकीकृत",
    jumpPlay: "अब खेलते हैं",
    whitepaper: "सफेद कागज",
    whitepaperTips:
      "जो लोग TGSlots को लेकर उतने ही गंभीर हैं, जितने हम हैं, उनके लिए हमने श्वेतपत्र तैयार किया है, जो TGSlots को बनाने के पीछे के विवरण और सोच का पता लगाता है। इसे पढ़कर देखें कि हम अन्य ऑनलाइन कैसीनो से किस तरह अलग हैं!",
    FqName: "अक्सर पूछे जाने वाले प्रश्नों",
    FqTitle1: "टीजीस्लॉट्स को क्या अलग बनाता है?",
    FqTips1:
      "TGSlots आपको सभी शीर्ष ऑनलाइन कैसीनो गेम, स्पोर्ट बुक्स और बहुत कुछ तक पहुँच प्रदान करता है, लेकिन हम एक ऐसे पारिस्थितिकी तंत्र को विकसित करने के लिए भी प्रतिबद्ध हैं जो वफादारी को महत्व देता है। हमारे समर्पण के प्रमाण के रूप में, हम $TGS टोकन के लिए बायबैक के माध्यम से अपने कैसीनो राजस्व का एक हिस्सा साझा करके वापस दे रहे हैं। खेलें, कमाएँ और पुरस्कृत हों!",
    FqTitle2: "प्री-सेल कब तक है?",
    FqTips2:
      "प्री-सेल तब तक खुला रहेगा जब तक हार्ड कैप नहीं पहुंच जाती। प्री-सेल के लिए सॉफ्ट कैप 2 मिलियन अमरीकी डॉलर है।",
    FqTitle3: "मुझे $TGS क्यों खरीदना चाहिए?",
    FqTips3:
      "जबकि TGSlots आपको कई शीर्ष क्रिप्टोकरेंसी के साथ खेलने देगा, केवल $TGS आपको अविश्वसनीय पुरस्कारों तक पहुँच प्रदान करेगा, दोनों स्टेकिंग से और हमारे टोकन को रखने से। TGSlots में खेलने के लिए $TGS की आवश्यकता नहीं है, लेकिन इसका उपयोग अन्य सभी मुद्राओं की तरह खेलों में दांव लगाने के लिए किया जा सकता है।",
    FqTitle4: "क्या मुझे प्री-सेल से $TGS टोकन तुरंत मिल जाएंगे?",
    FqTips4:
      "$TGS के लिए कोई वेस्टिंग अवधि नहीं है। हालाँकि, यदि आप प्रीसेल के दौरान अपने टोकन दांव पर लगाते हैं, तो वे लॉन्च के दिन + 7 दिन तक लॉक रहेंगे। टोकन को दांव पर लगाने से तुरंत ही स्टेकिंग पुरस्कार और अन्य भविष्य के लाभ मिलना शुरू हो जाते हैं।",
    FqTitle5: "टोकन कब लॉन्च होगा?",
    FqTips5:
      "हम प्रीसेल खत्म होने के कुछ दिनों के भीतर $TGS लॉन्च करेंगे। हम अपनी वेबसाइट और सोशल मीडिया चैनलों के ज़रिए लॉन्च के दिन सभी को सूचित रखेंगे।",
    stakingName: "जताया",
    stakingTips:
      "TGSlots आपको अपनी इच्छानुसार अपने $TGS टोकन को स्टेक और अनस्टेक करने की सुविधा देता है। लेकिन सावधान रहें, केवल स्टेक किए गए टोकन ही APY कमाते हैं! विवरण",
    sakeips1: "वर्तमान एपीवाई",
    sakeips2: "स्टेकिंग पूल में कुल $TGS",
    sakeips3: "स्टेकिंग पूल में आपका % स्वामित्व ($TGS)",
    sakeips4: "कुल भुगतान ($TGS)",
    buyName: "कैसे खरीदे",
    buyTips:
      "प्रीसेल के दौरान टोकन खरीदने के लिए, TGSlots की आधिकारिक वेबसाइट पर जाएँ। प्रीसेल के बाद, टोकन सीधे आधिकारिक वेबसाइट से या विकेंद्रीकृत एक्सचेंजों के माध्यम से प्राप्त किए जा सकते हैं।",
    buyTitle: "प्री-सेल के दौरान $TGS कैसे खरीदें?",
    buyStepName1: "चरण 1: आधिकारिक पोर्टल पर जाएं",
    buySteTips1:
      "विशेष प्रीसेल खरीद इंटरफ़ेस को खोजने के लिए TGSlots के समर्पित वेबपेज पर जाएँ। कृपया ध्यान दें कि यह प्रीसेल $TGS टोकन प्राप्त करने के लिए एकमात्र अधिकृत प्लेटफ़ॉर्म है।",
    buyStepName2: "चरण 2: भुगतान विधि चुनें",
    buySteTips2:
      "आप Ethereum (ETH) या Tether (USDT) का उपयोग करके Ethereum नेटवर्क पर प्रीसेल $TGS टोकन खरीदना चुन सकते हैं। वैकल्पिक रूप से, आप Binance स्मार्ट चेन का लाभ उठा सकते हैं और BNB या USDT चुन सकते हैं। अपना विकल्प चुनें और अगले चरण पर आगे बढ़ें।",
    buyStepName3: "चरण 3: अपना क्रिप्टो वॉलेट कनेक्ट करें",
    buySteTips3:
      "अगर आपका क्रिप्टो वॉलेट अभी तक सिंक नहीं हुआ है, तो आपको कनेक्ट करने के लिए कहने वाला एक प्रॉम्प्ट दिखाई देगा। आम तौर पर, वॉलेट डिफ़ॉल्ट रूप से एथेरियम मेननेट के लिए सेट किए जाते हैं; अगर आप ETH के साथ ट्रेड करते हैं तो यह आपके लिए फ़ायदेमंद है। हालाँकि, अगर आप Binance Smart Chain चुनते हैं, तो आपको नेटवर्क स्विच की पुष्टि करने की आवश्यकता हो सकती है।",
    buyStepName4: "चरण 4: खरीद राशि निर्दिष्ट करें",
    buySteTips4:
      "$TGS टोकन की वह राशि दर्ज करें जिसे आप खरीदना चाहते हैं। कृपया ध्यान दें कि आप जिस टोकन को खरीदना चाहते हैं उसकी कीमत के अलावा, लेनदेन को पूरा करने के लिए न्यूनतम 0.015 ETH की आवश्यकता होती है। Binance Smart Chain पर, यह न्यूनतम आवश्यकता कम है, लेकिन फिर भी लागू होती है। खरीदे जा सकने वाले $TGS टोकन की न्यूनतम राशि 100 $TGS निर्धारित की गई है।",
    buyStepName5: "चरण 5: अपनी खरीदारी पूरी करें",
    buySteTips5:
      "एक बार जब आप राशि दर्ज कर लेते हैं, तो आपको लेनदेन को अधिकृत करने के लिए कहा जाएगा। 'अनुमोदन' पर क्लिक करके, आपका लेनदेन आरंभ हो जाएगा और ब्लॉकचेन पर सत्यापित हो जाएगा। नेटवर्क की भीड़ और स्मार्ट अनुबंध गतिविधि के आधार पर लेनदेन पूरा होने का समय सेकंड से लेकर मिनटों तक भिन्न हो सकता है। कृपया खरीदारी पूरी तरह से निष्पादित होने तक इंटरफ़ेस पर बने रहें।",
    contactUs: "संपर्क करें",
    footerText: "tgslots सभी अधिकार सुरक्षित",
    privacy: "गोपनीयता",
    terms: "शर्तें",
    cookie: "गोपनीयता",
    stepName: "कदम",
    fundName: "अपने धन के साथ गुमनाम रूप से खेलें सुरक्षित",
    fundDesc:
      "ब्लॉकचेन की विकेंद्रीकृत प्रकृति के कारण, हमें आपसे KYC की आवश्यकता नहीं है और न ही हमें आपके फंड को केंद्रीकृत तरीके से संग्रहीत करने की आवश्यकता है। इसलिए आपको अपनी व्यक्तिगत जानकारी सौंपने की आवश्यकता नहीं है और आपको अपने फंड की सुरक्षा के बारे में चिंता करने की आवश्यकता नहीं है। आपको बस एक टेलीग्राम अकाउंट, कुछ क्रिप्टोकरेंसी की आवश्यकता है और आप जाने के लिए तैयार हैं!",
    fundTips1:
      "अगर आपके पास अभी तक टेलीग्राम अकाउंट नहीं है, तो कृपया ऐप डाउनलोड करें और अकाउंट बनाएँ। फिर, ऊपर दिए गए 'ओपन टीजीस्लॉट्स' लिंक के ज़रिए टीजीस्लॉट्स खोलें। अगर आपके पास पहले से ही टेलीग्राम अकाउंट है, तो तुरंत शुरू करने के लिए 'ओपन टीजीस्लॉट्स' पर क्लिक करें!",
    fundTips2:
      "आप अपने क्रिप्टो वॉलेट, अपने CEX अकाउंट या अपने क्रेडिट कार्ड (अपने TON वॉलेट को टॉप अप करके) से अपने TGSlots अकाउंट में फंड ट्रांसफर करना चुन सकते हैं। एक बार जब आप जमा करना समाप्त कर लेते हैं, तो आप जाने के लिए तैयार हैं!",
    fundTips3:
      "कोई गेम चुनें और खेलना शुरू करें! TGSlots पर 24/7 परेशानी मुक्त गेमिंग अनुभव का आनंद लें, साथ ही 24/7 स्टैंडबाय पर पेशेवर ग्राहक सहायता भी।",
    openTips: "TGSlots खोलें",
    download: "टेलीग्राम डाउनलोड करें",
  },
};
