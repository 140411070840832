import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "",
      keepAlive: true,
    },
  },
  {
    name: "stak",
    path: "/stak",
    component: () => import("@/views/stak/stakIng.vue"),
    meta: {
      title: "",
      keepAlive: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (localStorage.getItem("accessToken")) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});
export default router;
