<template>
  <div class="commonProblemContainer mainBgColor spacing">
    <a name="FAQ" class="hidden-anchor"></a>
    <div>
      <span class="white block-name">{{ $t("message.FqName") }}</span>
      <div class="line">
        <img src="@/assets/image/show.png" class="line-img" alt="line" />
      </div>
    </div>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item
        v-for="(item, index) in frequentList"
        :title="$t(item.title)"
        :key="index"
        :name="index"
      >
        <div>
          {{ $t(item.tips) }}
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 0,
      frequentList: [
        {
          title: "message.FqTitle1",
          tips: "message.FqTips1",
        },
        {
          title: "message.FqTitle2",
          tips: "message.FqTips2",
        },
        {
          title: "message.FqTitle3",
          tips: "message.FqTips3",
        },
        {
          title: "message.FqTitle4",
          tips: "message.FqTips4",
        },
        {
          title: "message.FqTitle5",
          tips: "message.FqTips5",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse {
  border-top: #303f4b !important;
  margin-top: 30px;
}
// ::v-deep .el-collapse-item__arrow {
//   order: -1;
// }
::v-deep .el-collapse-item__header {
  background: #212536 !important;
  color: #fff !important;
  border-bottom: 1px solid #30323a;
  padding: 40px;
  width: 100%;
  display: flex;
  font-size: 20px;
  justify-content: space-between; /* 左右居中 */
  align-items: center;
}
::v-deep .el-collapse-item__wrap {
  background: #2e3442 !important;
  border: none !important;
}
::v-deep .el-collapse-item__content {
  color: #fff;
  font-weight: 600;
  padding: 40px;
  font-size: 16px;
}
</style>
