// 繁体中文
export default {
  message: {
    // 交易提示
    unStakeErrorTips: "質押的代幣只有在上交易所後才能解除質押。",
    copyTips: "複 製",
    tgsName: "$TGS 代幣地址",
    tgsTips:
      "這是代幣地址。 不要转移到這個地址。 如果您想贾代幣，請使用预售真面。",
    succeeTips: "交易成功",
    failTips: "交易失敗，請重試",
    viewTips: "在 BSC 上查看: ",
    approve: "核准",
    menuHome: "首頁",
    menuPresale: "預售",
    menuWork: "怎麼運作的",
    menuStaking: "質押獎勵",
    menuToken: "代幣經濟學",
    menuBuy: "如何購買",
    menuFAQ: "常見問題",
    homeTips: "Telegram 上的世界上最好的老虎機",
    slot: "老虎機",
    join: "加入社區",
    play: "立即試玩",
    letMeIn: "讓我進去",
    presaleTop: "預售",
    event: "預售活動",
    http: "https://TGSlots/#home",
    desc: "TGSlots 是 Telegram 上線上老虎機和其他賭場遊戲的專屬場所。獲得完全許可，使用非託管錢包您的資金 100% 安全。取得我們的 $TGS 代幣來分享我們的部分收入！",
    title: "為什麼在預售中獲得 $TGS？",
    tips1: "獨家獎勵",
    currentPrice: "時價",
    achieved: "我們的目標",
    increases: "在價格上漲之前立即購買：",
    pay: "你付錢",
    max: "最大限度",
    receive: "您收到的 $TGS",
    buyRequire: "請購買至少 100 $TGS。",
    enoughTips: "你沒有足够的",
    enoughTips1: "來支付這筆交易。",
    gasPayTips: "確保你有足够的",
    gasPayTips1: "用於瓦斯付款",
    gasTips: "限時優惠",
    gasTips1: "TGSlots在您購買$TGS時全額補貼gas費，請聯系客戶支援進行索賠。 ",
    walletTips: "錢包",
    balanceTips: "餘額",
    toContact: "點擊聯繫",
    purchaseTips:
      "如果您使用第三方瀏覽器無法正常購買，請複製連結到錢包內建瀏覽器存取。",
    copyLink: "複製連結",
    apy: "目前質押年利率：",
    buyAndStake: "購買並質押 APY",
    apyTips: "平均年產量",
    justBuy: "只是想買，不想抵押",
    connectWallet: "連接錢包",
    wallet: "如何將 $TGS 加入我的錢包",
    navStakTips:
      "TGSlots 讓您可以質押和取消質押您的代幣。選擇您想要的功能。只有質押的代幣才能賺取 APY。",
    blockStakName1: "當前年利率",
    blockStakName2: "質押總額 $TGS",
    blockStakName3: "% 佔 TGS 質押總額的 ",
    blockStakName4: "支付的總獎勵（$TGS）",
    youStaking: "您的質押",
    staked1: "質押總額 $TGS",
    staked2: "佔 TGS 質押總額的百分比",
    harvested: "收穫的總獎勵（$TGS）",
    claimable: "可領取的總獎勵（$TGS）",
    receiveTips: "收到",
    stake: "賭注",
    unStake: "取消質押",
    learnMore: "了解更多",
    indicatedAPY: "根據指定的 APY 質押您的代幣以獲得被動獎勵",
    unStakeTips: "取消質押您的代幣並透過質押兌換您的獎勵",
    preSaleName: "為什麼在預售中獲得 $TGS？",
    presaleTitle1: "獨家獎勵",
    presaleTips1: "預售參與者在玩 TGSlots 時有權獲得獨家獎勵",
    presaleTitle2: "有史以來最優惠的價格",
    presaleTips2:
      "預售期間是獲得 $TGS 的最佳時機，因為隨著我們平台收入的成長，$TGS 將會上漲。",
    presaleTitle3: "高額賭客獎勵",
    presaleTips3:
      "在我們的預售中投資 5,000 美元或更多，即可獲得獨家 NFT 和其他發布後獎勵！",
    presaleTitle4: "今天開始賺錢",
    presaleTips4: "在我們的預售中獲得 $TGS，您將能夠立即質押以立即解鎖獎勵！",
    rewards1: "預售參與者在玩 TGSlots Best Price Ever 時有權獲得獨家獎勵",
    tips2: "有史以來最優惠的價格",
    rewards2:
      "預售期間是獲得 $TGS 的最佳時機，因為隨著我們平台收入的成長，$TGS 將會上漲。",
    tips3: "高額賭客獎勵",
    rewards3:
      "在我們的預售中投資 5,000 美元或更多，即可獲得獨家 NFT 和其他發布後獎勵！",
    tips4: "今天開始賺錢",
    rewards4: "在我們的預售中獲得 $TGS，您將能夠立即質押以立即解鎖獎勵！",
    work: "怎麼運作的",
    workTips:
      "TGSlots 擁有線上賭場所提供的一切，加上我們獨特的激勵系統，使用戶成為股東，並透過持有 TGS 美元不斷獲得獎勵",
    stakTitle: "質押獎勵",
    stakTips:
      "透過質押在預售期間購買並在遊戲中賺取的 $TGS，您將成為我們的股東並以高 APY 分享我們的部分收入！",
    incomTitle: "賺取被動收入",
    incomTips:
      "觀察您的 $TGS 持有量隨著時間的推移而增長，即使您在睡覺時也是如此。當您質押 TGS 美元的那一刻，奇蹟就發生了",
    giftTitle: "立即領取獎勵",
    giftTips:
      "您可以隨時透過質押領取獎勵。您可以將其用作 TGSlots 中的籌碼，或將其重新放回質押池以獲得更多獎勵！",
    cashTitle: "更多賺錢機會",
    cashTips:
      "當您質押 $TGS 時，您可以根據我們的平台收入解鎖獎勵。請不時查看我們的質押頁面以獲取更新！",
    tokenTitle: "代幣經濟學",
    tokenTips:
      "我們的代幣經濟學旨在協調激勵措施並為所有相關代幣持有者實現可持續的雙贏局面。",
    itemTitle1: "預售",
    itemTitle2: "質押獎勵",
    itemTitle3: "流動資金池",
    itemTitle4: "玩家獎勵",
    itemTitle5: "合作代理",
    itemTitle6: "機構投資者",
    itemTitle7: "社區金庫",
    itemTitle8: "行銷",
    itemTips1: "快速啟動 $TGS 生態系統",
    itemTips2: "獎勵我們的忠實持有者",
    itemTips3: "在 DEX 和 CEX 上建立對",
    itemTips4: "每位玩家的獎勵",
    itemTips5: "獎勵那些幫助 TGSlots 發展的人",
    itemTips6: "保留給投資 TGSlots 的投資機構",
    itemTips7: "用於社區策略儲備",
    itemTips8: "合作及直銷",
    jumpName: "立即試玩",
    jumpTips:
      "只需在 Telegram 中開啟 TGSlots 並使用主要加密貨幣/信用卡為您的帳戶充值即可開始！",
    safeTips1: "授權和非託管",
    safeTips2: "無需設定帳戶",
    safeTips3: "在您的智能手機上玩",
    safeTips4: "完全整合到 Telegram",
    jumpPlay: "立即試玩",
    whitepaper: "白皮書",
    whitepaperTips:
      "對於像我們一樣認真對待 TGSlots 的人們，我們準備了一份白皮書，探討製作 TGSlots 背後的細節和想法。閱讀本文，了解我們與其他線上賭場有何不同！",
    FqName: "經常問的問題",
    FqTitle1: "是什麼讓 TGSlots 與眾不同？",
    FqTips1:
      "TGSlots 讓您可以存取所有頂級線上賭場遊戲、體育博彩等，但我們也致力於培養重視忠誠度的生態系統。為了證明我們的奉獻精神，我們透過回購 $TGS 代幣來分享我們賭場收入的一部分。玩、賺錢並獲得獎勵！",
    FqTitle2: "預售時間多久？",
    FqTips2: "預售將持續進行，直到達到硬頂。預售的軟上限為 200 萬美元。",
    FqTitle3: "我為什麼要買 $TGS？",
    FqTips3:
      "雖然 TGSlots 可以讓您玩許多頂級加密貨幣，但只有 $TGS 才能讓您獲得令人難以置信的獎勵，無論是透過質押還是持有我們的代幣。玩 TGSlots 不需要 $TGS，但它可以像所有其他貨幣一樣用於遊戲下注。",
    FqTitle4: "我可以立即從預售中獲得 $TGS 代幣嗎？",
    FqTips4:
      "$TGS 沒有歸屬期。但是，如果您在預售期間質押代幣，它們將被鎖定，直到發布日 + 7 天。質押代幣立即開始賺取質押獎勵和其他未來收益。",
    FqTitle5: "代幣何時推出？",
    FqTips5:
      "我們將在預售結束後幾天內推出 $TGS。我們將透過我們的網站和社群媒體管道向所有人通報發布日期。",
    stakingName: "質押",
    stakingTips:
      "TGSlots 可讓您隨時質押並取消質押您的 $TGS 代幣。但要小心，只有質押的代幣才能賺 APY！細節",
    sakeips1: "當前年利率",
    sakeips2: "質押池中的總 TGS 美元",
    sakeips3: "您在質押池中的所有權百分比（$TGS）",
    sakeips4: "總支出 ($TGS)",
    buyName: "如何購買",
    buyTips:
      "要在預售期間購買代幣，請訪問 TGSlots 官方網站。預售結束後，可直接從官網或透過去中心化交易所取得代幣。",
    buyTitle: "如何在預售期間購買$TGS",
    buyStepName1: "第一步：訪問官方門戶",
    buySteTips1:
      "造訪TG.Slots的專用網頁，找到專屬Presale購買介面。請注意，這是獲得預售 $TGS 代幣的唯一授權平台。",
    buyStepName2: "步驟2：選擇付款方式",
    buySteTips2:
      "您可以選擇使用以太幣 (ETH) 或 Tether (USDT) 在以太坊網路上購買預售 $TGS 代幣。或者，您可以利用幣安智能鏈並選擇 BNB 或 USDT。做出選擇並繼續下一步。",
    buyStepName3: "第 3 步：連接您的加密錢包",
    buySteTips3:
      "如果您的加密錢包尚未同步，您將看到一條提示，要求您連線。通常情況下，錢包預設為以太坊主網；如果您使用 ETH 進行交易，這對您有利。但如果您選擇幣安智能鏈，您可能需要確認網路切換。",
    buyStepName4: "步驟4：指定購買金額",
    buySteTips4:
      "輸入您想要購買的 $TGS 代幣數量。請注意，除了您打算購買的代幣費用之外，完成交易還需要至少 0.015 ETH。在幣安智能鏈上，這一最低要求較低，但仍然適用。可以購買的 $TGS 代幣的最低數量設定為 100 $TGS。",
    buyStepName5: "第 5 步：完成購買",
    buySteTips5:
      "輸入金額後，系統會提示您授權交易。點擊“批准”後，您的交易將在區塊鏈上啟動並驗證。交易完成時間可能從幾秒鐘到幾分鐘不等，具體取決於網路擁塞和智慧合約活動。請停留在該介面上，直到購買完全執行。",
    contactUs: "聯絡我們",
    footerText: "tgslots 保留所有權利",
    privacy: "隱私",
    terms: "條款",
    cookie: "隱私",
    stepName: "步",
    fundName: "匿名操作，確保您的資金安全",
    fundDesc:
      "由於區塊鏈的去中心化性質，我們不需要您進行 KYC登录，也不需要以集中方式儲存您的資金。因此您無需交出您的個人訊息，也無需擔心您的資金安全。您只需要一個 Telegram 帳戶和一些加密貨幣，就可以開始了！",
    fundTips1:
      "如果您還沒有 Telegram 帳戶，請下載應用程式並建立帳戶。然後，透過上面的「開啟 TGSlots」連結開啟 TGSlots。如果您已經有 Telegram 帳戶，請點選「開啟 TGSlots」立即開始！",
    fundTips2:
      "您可以選擇從您的加密錢包、CEX 帳戶或信用卡（透過為 TON 錢包充值）將資金轉移到您的 TGSlots 帳戶。完成存款後，就可以出發了！",
    fundTips3:
      "選擇一個遊戲並開始吧！在 TGSlots 享受 24/7 全天候無憂的遊戲體驗，專業客戶支援也 24/7 隨時待命。",
    openTips: "打開TGSlots",
    download: "下載電報",
  },
};
