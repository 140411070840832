//   韩语（한국인）
export default {
  message: {
    unStakeErrorTips:
      "질권 토큰은 거래소에 상장된 후에야 질권을 해제할 수 있다.",
    copyTips: "복제",
    tgsName: "$TGS 토큰 주소",
    tgsTips:
      "이것은 토큰 주소입니다.이 주소로 이동하지 마십시오.토큰을 구매하고 싶으시면 정품 국수를 예매하세요.",
    succeeTips: "거래가 성공했습니다",
    failTips: "거래에 실패했습니다. 다시 시도해 주세요.",
    viewTips: "BSC 스캔에서 보기: ",
    approve: "승인하다",
    menuHome: "집",
    menuPresale: "사전 판매",
    menuWork: "작동 방식",
    menuStaking: "스테이킹 보상",
    menuToken: "토큰 경제학",
    menuBuy: "구입 방법",
    menuFAQ: "자주하는 질문",
    homeTips: "텔레그램의 세계 최고의 슬롯",
    slot: "슬롯",
    join: "커뮤니티 가입",
    play: "지금 플레이",
    letMeIn: "나를 들여 보내줘",
    presaleTop: "사전 판매",
    event: "프리세일 이벤트",
    http: "https://TGSlots/#home",
    desc: "TGSlots는 Telegram의 온라인 슬롯 및 기타 카지노 게임을 위한 독점적인 장소입니다. 비수탁형 지갑으로 완전한 라이선스를 받았으며 귀하의 자금이 100% 안전합니다. 수익의 일부를 공유하려면 $TGS 토큰을 받으세요!",
    title: "사전 판매에서 $TGS를 받아야 하는 이유는 무엇입니까?",
    tips1: "독점 보상",
    currentPrice: "현재 가격",
    achieved: "우리의 목표",
    increases: "가격이 인상되기 전에 지금 구매하세요:",
    pay: "당신이 지불",
    max: "최대",
    receive: "$TGS를 받음",
    buyRequire: "최소 100$TGS 이상 구매하시기 바랍니다.",
    enoughTips: "당신은 충분하지 않습니다",
    enoughTips1: "이 거래에 대한 비용을 지불합니다.",
    gasPayTips: "당신이 충분한지 확인하세요",
    gasPayTips1: "천연가스 지불에 사용",
    gasTips: "한시적 혜택",
    gasTips1:
      "TGSlots는 $TGS를 구매할 때 휘발유 요금을 전액 보조하므로 고객 지원에 문의하여 배상을 청구하십시오. ",
    walletTips: "지갑",
    balanceTips: "균형",
    toContact: "연락하려면 클릭하세요",
    purchaseTips:
      "타사 브라우저를 이용하여 정상적으로 구매하실 수 없는 경우, 지갑 내장 브라우저에 링크를 복사하여 접속하시기 바랍니다.",
    copyLink: "링크 복사",
    apy: "현재 스테이킹 APY :",
    buyAndStake: "APY 구매 및 스테이킹",
    apyTips: "APY",
    justBuy: "스테이킹 없이 그냥 사고 싶다",
    connectWallet: "지갑 연결",
    wallet: "내 지갑에 $TGS를 추가하는 방법",
    navStakTips:
      "TGSlots를 사용하면 토큰을 스테이킹하고 언스테이크할 수 있습니다. 원하는 기능을 선택하세요. 스테이킹된 토큰만 APY를 얻습니다.",
    blockStakName1: "현재 APY",
    blockStakName2: "스테이킹된 총 $TGS",
    blockStakName3: "총 $TGS 스테이킹 비율 ",
    blockStakName4: "총 지급 보상($TGS)",
    youStaking: "당신의 스테이킹",
    staked1: "스테이킹된 총 $TGS",
    staked2: "총 $TGS 스테이킹 비율",
    harvested: "수확된 총 보상($TGS)",
    claimable: "청구 가능한 총 보상($TGS)",
    receiveTips: "받다",
    stake: "말뚝",
    unStake: "언스테이킹",
    learnMore: "더 알아보기",
    indicatedAPY:
      "표시된 APY를 기반으로 패시브 보상을 받으려면 토큰을 스테이킹하세요.",
    unStakeTips: "토큰을 언스테이크하고 스테이킹에서 보상을 받으세요",
    preSaleName: "사전 판매에서 $TGS를 받아야 하는 이유는 무엇입니까?",
    presaleTitle1: "독점 보상",
    presaleTips1:
      "사전 판매 참가자는 TGSlot을 플레이할 때 독점 보상을 받을 수 있습니다.",
    presaleTitle2: "최고의 가격",
    presaleTips2:
      "우리의 플랫폼 수익이 증가함에 따라 $TGS가 달릴 것이기 때문에 사전 판매 기간보다 $TGS를 얻을 수 있는 더 좋은 시기는 없을 것입니다.",
    presaleTitle3: "하이 롤러에 대한 보상",
    presaleTips3:
      "독점 NFT 및 기타 출시 후 보상을 받으려면 사전 판매에 5K USD 이상을 투자하세요!",
    presaleTitle4: "오늘부터 수익을 창출해 보세요",
    presaleTips4:
      "사전 판매에서 $TGS를 얻으면 즉시 스테이킹하여 보상을 즉시 잠금 해제할 수 있습니다!",
    rewards1:
      "사전 판매 참가자는 TGSlots 역대 최고 가격을 플레이할 때 독점 보상을 받을 수 있습니다.",
    tips2: "최고의 가격",
    rewards2:
      "우리의 플랫폼 수익이 증가함에 따라 $TGS가 달릴 것이기 때문에 사전 판매 기간보다 $TGS를 얻을 수 있는 더 좋은 시기는 없을 것입니다.",
    tips3: "하이 롤러에 대한 보상",
    rewards3:
      "독점 NFT 및 기타 출시 후 보상을 받으려면 사전 판매에 5K USD 이상을 투자하세요!",
    tips4: "오늘부터 수익을 창출해 보세요",
    rewards4:
      "사전 판매에서 $TGS를 얻으면 즉시 스테이킹하여 보상을 즉시 잠금 해제할 수 있습니다!",
    work: "작동 원리",
    workTips:
      "TGSlots는 온라인 카지노가 제공하는 모든 기능과 함께 사용자를 주주로 만들고 $TGS를 보유함으로써 지속적으로 보상을 받는 독특한 인센티브 시스템을 갖추고 있습니다.",
    stakTitle: "스테이킹 보상",
    stakTips:
      "사전 판매 기간 동안 구매하고 게임 내에서 획득한 $TGS를 스테이킹하면 귀하는 우리의 주주가 되어 높은 APY로 수익의 일부를 공유하게 됩니다!",
    incomTitle: "수동적 소득을 얻으세요",
    incomTips:
      "잠자는 동안에도 $TGS 보유량이 시간이 지남에 따라 증가하는 것을 지켜보세요. $TGS를 스테이킹하는 순간 마법이 일어납니다.",
    giftTitle: "즉시 보상 청구",
    giftTips:
      "원하는 대로 스테이킹 보상을 청구할 수 있습니다. TGSlots에서 칩으로 사용하거나 더 많은 보상을 위해 스테이킹 풀에 다시 스테이킹할 수 있습니다!",
    cashTitle: "더 많은 수익 창출 기회",
    cashTips:
      "$TGS를 스테이킹하면 플랫폼 수익에 따른 보상을 받을 수 있습니다. 업데이트를 위해 수시로 스테이킹 페이지를 확인하세요!",
    tokenTitle: "토큰 경제학",
    tokenTips:
      "우리의 토큰 경제는 인센티브를 조정하고 관련된 모든 토큰 보유자에게 지속 가능한 상생 상황을 달성하도록 설계되었습니다.",
    itemTitle1: "사전 판매",
    itemTitle2: "스테이킹 보상",
    itemTitle3: "유동성 풀",
    itemTitle4: "플레이어 보상",
    itemTitle5: "협력 대리인",
    itemTitle6: "기관 투자자",
    itemTitle7: "지역사회 재무",
    itemTitle8: "마케팅",
    itemTips1: "$TGS 생태계를 시작하려면",
    itemTips2: "헌신적인 보유자에게 보상하기 위해",
    itemTips3: "DEX와 CEX에서 쌍을 생성하려면",
    itemTips4: "모든 플레이어를 위한 보상",
    itemTips5: "TGSlots 성장에 도움을 준 사람들을 위한 보상",
    itemTips6: "TGSlots에 투자하는 투자 조직을 위해 예약됨",
    itemTips7: "커뮤니티 전략 예비용",
    itemTips8: "협력 및 직접 마케팅을 위해",
    jumpName: "바로 뛰어들다",
    jumpTips:
      "텔레그램에서 TGSlots를 열고 주요 암호화폐/신용카드로 계정을 충전하면 시작됩니다!",
    safeTips1: "라이센스가 있고 비구속적임",
    safeTips2: "계정 설정이 필요하지 않습니다",
    safeTips3: "스마트폰에서 플레이",
    safeTips4: "텔레그램에 완전히 통합됨",
    jumpPlay: "지금 플레이",
    whitepaper: "백지",
    whitepaperTips:
      "우리와 마찬가지로 TGSlots에 대해 진지하게 생각하는 사람들을 위해 우리는 TGSlots 제작에 대한 세부 사항과 생각을 탐구하는 백서를 준비했습니다. 이 글을 읽고 우리가 다른 온라인 카지노와 어떻게 다른지 알아보세요!",
    FqName: "자주 묻는 질문",
    FqTitle1: "TGSlot이 다른 점은 무엇입니까?",
    FqTips1:
      "TGSlots는 최고의 온라인 카지노 게임, 스포츠 북 등에 대한 액세스를 제공할 뿐만 아니라 충성도를 중시하는 생태계를 육성하기 위해 최선을 다하고 있습니다. 우리의 헌신에 대한 증거로 우리는 $TGS 토큰 환매를 통해 카지노 수익의 일부를 공유함으로써 환원하고 있습니다. 플레이하고, 획득하고, 보상을 받으세요!",
    FqTitle2: "사전 판매 기간은 얼마나 되나요?",
    FqTips2:
      "사전 판매는 하드 캡에 도달할 때까지 열립니다. 사전 판매 소프트 캡은 200만 달러입니다.",
    FqTitle3: "왜 $TGS를 구매해야 하나요?",
    FqTips3:
      "TGSlots를 사용하면 많은 최고의 암호화폐를 플레이할 수 있지만 $TGS만이 토큰 스테이킹과 보유를 통해 놀라운 보상을 받을 수 있습니다. $TGS는 TGSlots에서 플레이하는 데 필요하지 않지만 다른 모든 통화와 마찬가지로 게임에서 베팅하는 데 사용할 수 있습니다.",
    FqTitle4: "사전 판매를 통해 $TGS 토큰을 즉시 받을 수 있나요?",
    FqTips4:
      "$TGS에는 베스팅 기간이 없습니다. 그러나 사전 판매 중에 토큰을 스테이킹하면 출시일 + 7일까지 잠금 상태가 됩니다. 토큰을 스테이킹하면 즉시 스테이킹 보상 및 기타 향후 혜택을 받기 시작합니다.",
    FqTitle5: "토큰은 언제 출시되나요?",
    FqTips5:
      "사전 판매 종료 후 며칠 이내에 $TGS를 출시할 예정입니다. 우리는 웹사이트와 소셜 미디어 채널을 통해 출시일을 모든 사람에게 알려드릴 것입니다.",
    stakingName: "스테이킹",
    stakingTips:
      "TGSlots를 사용하면 원하는 대로 $TGS 토큰을 스테이킹하고 언스테이킹할 수 있습니다. 하지만 조심하세요. 스테이킹된 토큰만 APY를 얻을 수 있습니다! 세부",
    sakeips1: "현재 APY",
    sakeips2: "스테이킹 풀의 총 $TGS",
    sakeips3: "스테이킹 풀의 소유권 %($TGS)",
    sakeips4: "총 지불금($TGS)",
    buyName: "구입 방법",
    buyTips:
      "사전 판매 기간 동안 토큰을 구매하려면 TGSlots 공식 웹사이트를 방문하세요. 프리세일 이후에는 공식 웹사이트나 탈중앙화 거래소를 통해 토큰을 직접 얻을 수 있습니다.",
    buyTitle: "사전 판매 기간 동안 $TGS를 구매하는 방법",
    buyStepName1: "1 단계: 공식 포털 방문",
    buySteTips1:
      "독점 사전 판매 구매 인터페이스를 찾으려면 TGSlots 전용 웹페이지를 방문하세요. 이는 사전 판매 $TGS 토큰을 얻을 수 있는 유일한 승인된 플랫폼이라는 점에 유의하십시오.",
    buyStepName2: "2 단계: 결제 수단 선택",
    buySteTips2:
      "Ethereum(ETH) 또는 Tether(USDT)를 사용하여 Ethereum 네트워크에서 Presale $TGS 토큰을 구매하도록 선택할 수 있습니다. 또는 바이낸스 스마트 체인을 활용하고 BNB 또는 USDT를 선택할 수 있습니다. 선택하고 다음 단계로 진행하세요.",
    buyStepName3: "3 단계: 암호화폐 지갑 연결",
    buySteTips3:
      "암호화폐 지갑이 아직 동기화되지 않은 경우 연결하라는 메시지가 표시됩니다. 일반적으로 지갑은 기본적으로 이더리움 메인넷용으로 설정됩니다. 이는 ETH로 거래하는 경우 유리하게 작용합니다. 하지만 바이낸스 스마트 체인을 선택하는 경우 네트워크 전환을 확인해야 할 수도 있습니다.",
    buyStepName4: "4 단계: 구매 금액 지정",
    buySteTips4:
      "구매하려는 $TGS 토큰 금액을 입력하세요. 구매하려는 토큰 비용 외에도 거래를 완료하려면 최소 0.015 ETH가 필요합니다. 바이낸스 스마트 체인에서는 이 최소 요구 사항이 더 낮지만 여전히 적용됩니다. 구매할 수 있는 $TGS 토큰의 최소 금액은 100 $TGS로 설정되어 있습니다.",
    buyStepName5: "5 단계: 구매 완료",
    buySteTips5:
      "금액을 입력하면 거래를 승인하라는 메시지가 표시됩니다. '승인'을 클릭하면 블록체인에서 거래가 시작되고 확인됩니다. 트랜잭션 완료 시간은 네트워크 정체 및 스마트 계약 활동에 따라 몇 초에서 몇 분까지 달라질 수 있습니다. 구매가 완전히 실행될 때까지 인터페이스를 유지하세요.",
    contactUs: "문의하기",
    footerText: "tgslots 판권 소유",
    privacy: "은둔",
    terms: "자귀",
    cookie: "은둔",
    stepName: "단계",
    fundName: "귀하의 자금을 안전하게 익명으로 플레이하세요",
    fundDesc:
      "블록체인의 분산된 특성 덕분에 우리는 귀하에게 KYC를 요구하지 않으며 귀하의 자금을 중앙 집중식으로 저장할 필요도 없습니다. 따라서 귀하는 개인 정보를 넘겨줄 필요가 없으며 자금의 안전에 대해 걱정할 필요가 없습니다. 텔레그램 계정과 암호화폐만 있으면 준비가 완료됩니다!",
    fundTips1:
      "아직 텔레그램 계정이 없다면 앱을 다운로드하고 계정을 만드세요. 그런 다음 위의 'TGSlots 열기' 링크를 통해 TGSlots를 엽니다. 이미 텔레그램 계정이 있다면 'TGSlots 열기'를 클릭하여 바로 시작하세요!",
    fundTips2:
      "암호화폐 지갑, CEX 계정 또는 신용 카드(TON 지갑 충전)에서 TGSlots 계정으로 자금을 이체하도록 선택할 수 있습니다. 입금을 마치면 이제 출발 준비가 된 것입니다!",
    fundTips3:
      "게임을 선택하고 시작하세요! 연중무휴 24시간 전문 고객 지원을 받으며 TGSlots에서 연중무휴 24시간 번거로움 없는 게임 경험을 즐겨보세요.",
    openTips: "TGS 슬롯 열기",
    download: "텔레그램 다운로드",
  },
};
