<template>
  <div class="pledgeCoin mainBgColor spacing">
    <a name="Presale" class="hidden-anchor"></a>
    <div class="pledgeCoinLogoImg">
      <span class="white block-name">{{ $t("message.preSaleName") }}</span>
      <div class="line">
        <img src="@/assets/image/show.png" class="line-img" alt="line" />
      </div>
    </div>
    <div class="step">
      <div class="stepItem" v-for="(item, index) in stepList" :key="index">
        <img class="pledgeCoinImg icon-60" :src="item.img" alt="pledgeCoin" />
        <p class="stepTitle white">{{ $t(item.title) }}</p>
        <p class="stepDesc gray fs12">
          {{ $t(item.tips) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepList: [
        {
          title: "message.presaleTitle1",
          tips: "message.presaleTips1",
          img: require("@/assets/image/trophy.png"),
        },
        {
          title: "message.presaleTitle2",
          tips: "message.presaleTips2",
          img: require("@/assets/image/prefreentialPrice.png"),
        },
        {
          title: "message.presaleTitle3",
          tips: "message.presaleTips3",
          img: require("@/assets/image/giftWeb.png"),
        },
        {
          title: "message.presaleTitle4",
          tips: "message.presaleTips4",
          img: require("@/assets/image/money.png"),
        },
      ],
    };
  },
};
</script>
