import { baseURL } from "@/api/config";

export function formatDateTime(dateTime) {
  const year = dateTime.getFullYear();
  const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
  const day = dateTime.getDate().toString().padStart(2, "0");
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");
  const seconds = dateTime.getSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export function formatTimeDiff(timeDiff) {
  // 计算剩余时间
  const hours = Math.floor(timeDiff / 1000 / 60 / 60);
  const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
  const seconds = Math.floor((timeDiff / 1000) % 60);
  // 判断时间差是否小于等于0 小于等于0 循环计算
  return [...toHours(hours), ...toArr(minutes), ...toArr(seconds)];
}
/**
 * @description: 剩余时间倒计时函数
 */
export function getTimeArr() {
  const currentTime = new Date(); // 当前时间
  const targetStopTime = new Date(baseURL.TERMINATION_TIME); // 终止结束时间
  if (currentTime === targetStopTime) {
    return;
  }
  const targetEndTime = new Date(baseURL.INITIAL_END_TIME); // 初始结束时间
  if (currentTime > targetEndTime) {
    const cycleTime = baseURL.CYCLE_TIME; // 时间周期
    // 计算初始结束时间+时间周期后的时间——————>>>目标结束时间
    const initialEndTime = formatDateTime(
      new Date(
        new Date(baseURL.INITIAL_END_TIME).getTime() +
          cycleTime * 60 * 60 * 1000
      )
    );
    const timeDiff = new Date(initialEndTime).getTime() - new Date().getTime();
    // 计算剩余时间
    const hours = Math.floor(timeDiff / 1000 / 60 / 60);
    const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
    // 判断时间差是否小于等于0 小于等于0 循环计算
    return [...toHours(hours), ...toArr(minutes), ...toArr(seconds)];
  } else {
    const initialEndTime = baseURL.INITIAL_END_TIME;
    const timeDiff = new Date(initialEndTime).getTime() - new Date().getTime();
    const hours = Math.floor(timeDiff / 1000 / 60 / 60);
    const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
    return [...toHours(hours), ...toArr(minutes), ...toArr(seconds)];
  }
}
// 更换小时的数组类型
function toHours(h) {
  return h >= 10 ? ("" + h).split("").map((item) => Number(item)) : [0, 0, h];
}
function toArr(n) {
  return n >= 10 ? ("" + n).split("").map((item) => Number(item)) : [0, n];
}
