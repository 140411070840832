// 俄罗斯语（руская мова）
export default {
  message: {
    unStakeErrorTips:
      "Адключаныя знакі могуць быць адключаныя толькі пасля спісу ў абмене.",
    copyTips: "копія",
    tgsName: "Адрас токена $TGS",
    tgsTips:
      "Гэта адрас маркера. Не пераводзьце на гэты адрас. Калі вы хочаце набыць жэтоны, выкарыстоўвайце перад продажам сапраўдную локшыну.",
    succeeTips: "Трансакцыя прайшла паспяхова",
    failTips: "Збой транзакцыі, паспрабуйце яшчэ раз",
    viewTips: "Прагляд на BSC Scan: ",
    approve: "Ухваліць",
    homeTips: "Лепшыя ў свеце гульнявыя аўтаматы ў Telegram",
    slot: "СЛОТЫ",
    join: "Далучайцеся да суполкі",
    play: "Гуляць зараз",
    letMeIn: "Упусці мяне",
    presaleTop: "ПЕРАДПРАДАЖ",
    event: "Перадпродажная падзея",
    http: "https://TGSlots/#home",
    desc: "TGSlots - гэта эксклюзіўнае месца для онлайн-слотаў і іншых гульняў казіно ў Telegram. Поўная ліцэнзія, і вашыя сродкі на 100% бяспечныя з кашалькамі без захавання. Атрымайце наш токен $TGS, каб падзяліцца часткай нашых заробкаў!",
    title: "Навошта атрымліваць $TGS у перадпродажы?",
    tips1: "Эксклюзіўныя ўзнагароды",
    currentPrice: "Бягучая цана",
    achieved: "Наша мэта",
    increases: "Купляйце зараз, пакуль цэны не вырастуць у:",
    pay: "вы плаціце",
    max: "МАКС",
    receive: "Вы атрымаеце $TGS",
    buyRequire: "Купіце не менш за 100 TGS.",
    enoughTips: "У вас недастаткова",
    enoughTips1: "каб плаціць за гэтую транзакцыю.",
    gasPayTips: "Пераканайцеся, што ў вас дастаткова",
    gasPayTips1: "за аплату газу",
    gasTips: "Абмежаваная па часе прапанова",
    gasTips1:
      "TGSlots цалкам субсідуе плату за бензін, калі вы купляеце $TGS. Калі ласка, звярніцеся ў службу падтрымкі, каб запатрабаваць. ",
    walletTips: "Кашалёк",
    balanceTips: "Баланс",
    toContact: "Націсніце, каб звязацца",
    purchaseTips:
      "Калі вы не можаце нармальна рабіць пакупкі з дапамогай браўзера трэцяга боку, скапіруйце спасылку ва ўбудаваны браўзер кашалька, каб атрымаць доступ да яго.",
    copyLink: "Скапіраваць спасылку",
    apy: "Бягучая стаўка APY:",
    buyAndStake: "Купляйце і стаўце на APY",
    apyTips: "APY",
    justBuy: "Проста хачу купіць без стаўкі",
    connectWallet: "Падключыць кашалёк",
    wallet: "Як дадаць $TGS у мой кашалёк",
    navStakTips:
      "TGSlots дазваляе рабіць стаўкі і здымаць стаўкі вашых токенаў. Выберыце, якую функцыю вы хочаце. Толькі токены на стаўкі зарабляюць APY.",
    blockStakName1: "Бягучы APY",
    blockStakName2: "Агульная стаўка ў TGS",
    blockStakName3: "% ад агульнай колькасці стаўкі ў TGS ",
    blockStakName4: "Агульная колькасць выплачаных узнагарод ($TGS)",
    youStaking: "Ваша стаўка",
    staked1: "Агульная стаўка ў TGS",
    staked2: "% ад агульнай сумы стаўкі ў TGS",
    harvested: "Агульная колькасць атрыманых узнагарод ($TGS)",
    claimable: "Агульная колькасць заяўленых узнагарод ($TGS)",
    receiveTips: "Атрымлівайце",
    stake: "Стаўка",
    unStake: "Зняць стаўку",
    learnMore: "Даведайцеся больш",
    indicatedAPY:
      "Стаўце свае токены, каб атрымліваць пасіўныя ўзнагароды на аснове пазначанага APY",
    unStakeTips:
      "Зніміце стаўкі са сваіх токенаў і выкупіце свае ўзнагароды са стаўкі",
    preSaleName: "Навошта атрымліваць $TGS у перадпродажы?",
    presaleTitle1: "Эксклюзіўныя ўзнагароды",
    presaleTips1:
      "Удзельнікі нашага папярэдняга продажу маюць права на эксклюзіўныя ўзнагароды, калі яны гуляюць у TGSlots",
    presaleTitle2: "Лепшая цана",
    presaleTips2:
      "Ніколі не будзе лепшага часу, каб атрымаць $TGS, чым падчас перадпродажу, таму што $TGS будзе змяншацца па меры росту даходаў нашай платформы.",
    presaleTitle3: "Узнагароды для хайролераў",
    presaleTips3:
      "Інвестуйце 5 тысяч долараў або больш у наш перадпродаж, каб атрымаць узнагароду эксклюзіўнымі NFT і іншымі ўзнагародамі пасля запуску!",
    presaleTitle4: "Пачніце зарабляць сёння",
    presaleTips4:
      "Атрымайце $TGS у нашым перадпродажы, і вы адразу ж зможаце зрабіць стаўку, каб атрымаць узнагароды!",
    rewards1:
      "Удзельнікі нашага папярэдняга продажу маюць права на эксклюзіўныя ўзнагароды, калі яны гуляюць у TGSlots па лепшай цане",
    tips2: "Лепшая цана",
    rewards2:
      "Ніколі не будзе лепшага часу, каб атрымаць $TGS, чым падчас перадпродажу, таму што $TGS будзе змяншацца па меры росту даходаў нашай платформы.",
    tips3: "Узнагароды для хайролераў",
    rewards3:
      "Інвестуйце 5 тысяч долараў або больш у наш перадпродаж, каб атрымаць узнагароду эксклюзіўнымі NFT і іншымі ўзнагародамі пасля запуску!",
    tips4: "Пачніце зарабляць сёння",
    rewards4:
      "Атрымайце $TGS у нашым перадпродажы, і вы адразу ж зможаце зрабіць стаўку, каб атрымаць узнагароды!",
    work: "Як гэта працуе",
    workTips:
      "У TGSlots ёсць усё, што можа прапанаваць інтэрнэт-казіно, а таксама наша унікальная сістэма заахвочванняў, якая робіць карыстальнікаў нашымі акцыянерамі і пастаянна атрымлівае ўзнагароду, захоўваючы $TGS",
    stakTitle: "Стаўкі ўзнагароды",
    stakTips:
      "Ставіўшы $TGS, набытыя падчас перадпродажу і заробленыя ў гульні, вы станеце нашым акцыянерам і падзеліцеся часткай нашых заробкаў па высокім APY!",
    incomTitle: "Атрымлівайце пасіўны прыбытак",
    incomTips:
      "Глядзіце, як вашыя ўладанні ў $TGS растуць з цягам часу, нават калі вы спіце. Магія адбываецца ў той момант, калі вы ставіце свае $TGS",
    giftTitle: "Патрабуйце ўзнагароды імгненна",
    giftTips:
      "Вы можаце патрабаваць узнагароды ад стаўкі, калі хочаце. Вы можаце выкарыстоўваць яго ў якасці фішак у гульнявых аўтаматах TGSlots або пераставіць у пул ставак, каб атрымаць яшчэ больш узнагарод!",
    cashTitle: "Больш магчымасцей заробку",
    cashTips:
      "Калі вы робіце стаўку $TGS, вы адкрываеце доступ да ўзнагарод на аснове даходаў нашай платформы. Час ад часу правярайце нашы абнаўленні на нашай старонцы стаўкі!",
    tokenTitle: "Токен Эканоміка",
    tokenTips:
      "Наша эканоміка токенаў прызначана для выраўноўвання стымулаў і дасягнення ўстойлівай бяспройгрышнай сітуацыі для ўсіх задзейнічаных уладальнікаў токенаў.",
    itemTitle1: "Перадпродаж",
    itemTitle2: "Стаўкі ўзнагароды",
    itemTitle3: "Пул ліквіднасці",
    itemTitle4: "Узнагароды гульцам",
    itemTitle5: "Кааператыўны агент",
    itemTitle6: "Інстытуцыйныя інвестары",
    itemTitle7: "Грамадская казна",
    itemTitle8: "Маркетынг",
    itemTips1: "Каб запусціць экасістэму $TGS",
    itemTips2: "Каб узнагародзіць нашых адданых уладальнікаў",
    itemTips3: "Стварыць пару на DEX і CEX",
    itemTips4: "Узнагароды для кожнага гульца",
    itemTips5: "Узнагароды для тых, хто дапамагае развіваць TGSlots",
    itemTips6:
      "Зарэзервавана для інвестыцыйных арганізацый, якія інвестуюць у TGSlots",
    itemTips7: "Для грамадскага стратэгічнага рэзерву",
    itemTips8: "Для супрацоўніцтва і прамога маркетынгу",
    jumpName: "Перайсці адразу",
    jumpTips:
      "Проста адкрыйце TGSlots у Telegram і папоўніце свой рахунак з дапамогай асноўных крыптавалют / крэдытных карт, каб пачаць!",
    safeTips1: "Ліцэнзійны і без пазбаўлення волі",
    safeTips2: "Наладжваць уліковы запіс не патрабуецца",
    safeTips3: "Гуляць на вашым смартфоне",
    safeTips4: "Цалкам інтэграваны ў Telegram",
    jumpPlay: "Гуляць зараз",
    whitepaper: "Whitepaper",
    whitepaperTips:
      "Для людзей, якія аднолькава сур'ёзна ставяцца да TGSlots, як і мы, мы падрыхтавалі тэхнічную кнігу, якая даследуе дэталі і ідэі стварэння TGSlots. Прачытайце, каб даведацца, чым мы адрозніваемся ад іншых інтэрнэт-казіно!",
    FqName: "Часта задаюць пытанні",
    FqTitle1: "Што адрознівае TGSlots?",
    FqTips1:
      "TGSlots дае вам доступ да ўсіх найлепшых онлайн-гульняў у казіно, спартыўных кніг і іншага, але мы таксама імкнемся развіваць экасістэму, якая шануе лаяльнасць. У якасці сведчання нашай прыхільнасці мы аддаем грошы, дзелімся часткай даходаў нашага казіно праз выкуп токена $TGS. Гуляйце, зарабляйце і атрымлівайце ўзнагароду!",
    FqTitle2: "Колькі доўжыцца папярэдні продаж?",
    FqTips2:
      "Папярэдні продаж будзе адкрыты, пакуль не будзе дасягнуты жорсткі ліміт. Сума перадпродажу складае 2 мільёны долараў ЗША.",
    FqTitle3: "Чаму я павінен купляць $TGS?",
    FqTips3:
      "Нягледзячы на тое, што TGSlots дазволіць вам гуляць з многімі лепшымі крыптавалютамі, толькі $TGS дасць вам доступ да неверагодных узнагарод, як за стаўку, так і за простае ўтрыманне нашага токена. $TGS не патрабуецца для гульні ў TGSlots, але яго можна выкарыстоўваць для ставак у гульнях, як і ва ўсіх іншых валютах.",
    FqTitle4: "Ці атрымаю я токены $TGS з папярэдняй продажу адразу?",
    FqTips4:
      "Для $TGS няма перыяду набыцця права. Аднак калі вы паставіце свае токены падчас перадпродажу, яны будуць заблакіраваны да дня запуску + 7 дзён. Стаўка токенаў адразу пачынае атрымліваць узнагароды за стаўку і іншыя будучыя перавагі.",
    FqTitle5: "Калі будзе запушчаны токен?",
    FqTips5:
      "Мы запусцім $TGS праз некалькі дзён пасля заканчэння перадпродажу. Мы будзем трымаць усіх у курсе дня запуску праз наш вэб-сайт і ў сацыяльных сетках.",
    stakingName: "Стаўка",
    stakingTips:
      "TGSlots дазваляе вам рабіць стаўкі і здымаць стаўкі на вашыя токены $TGS, калі вы хочаце. Але будзьце асцярожныя, APY зарабляюць толькі токены на стаўкі! Дэталі",
    sakeips1: "Бягучы APY",
    sakeips2: "Агульная сума TGS у пуле ставак",
    sakeips3: "Ваш працэнт уласнасці ў пуле ставак ($TGS)",
    sakeips4: "Агульная выплата ($TGS)",
    buyName: "Як купіць",
    buyTips:
      "Каб набыць токены падчас перадпродажу, наведайце афіцыйны сайт TGSlots. Пасля папярэдняй продажу токены можна атрымаць непасрэдна на афіцыйным сайце або праз дэцэнтралізаваныя біржы.",
    buyTitle: "Як купіць $TGS падчас перадпродажу",
    buyStepName1: "Крок 1: Наведайце афіцыйны партал",
    buySteTips1:
      "Наведайце спецыяльную вэб-старонку TGSlots, каб знайсці эксклюзіўны інтэрфейс перадпродажнай пакупкі. Звярніце ўвагу, што гэта адзіная аўтарызаваная платформа для атрымання перадпродажных токенаў $TGS.",
    buyStepName2: "Крок 2: Выберыце спосаб аплаты",
    buySteTips2:
      "Вы можаце набыць токен $TGS перад продажам у сетцы Ethereum з дапамогай Ethereum (ETH) або Tether (USDT). Акрамя таго, вы можаце выкарыстоўваць Binance Smart Chain і выбраць BNB або USDT. Зрабіце свой выбар і перайдзіце да наступнага кроку.",
    buyStepName3: "Крок 3: Падключыце крыпта кашалёк",
    buySteTips3:
      "Калі ваш крыпта-кашалёк яшчэ не сінхранізаваны, вы ўбачыце падказку з просьбай аб падключэнні. Як правіла, кашалькі настроены для асноўнай сеткі Ethereum па змаўчанні; гэта працуе вам на карысць, калі вы гандлюеце з ETH. Аднак, калі вы выбіраеце Binance Smart Chain, вам можа спатрэбіцца пацвердзіць пераключэнне сеткі.",
    buyStepName4: "Крок 4: Укажыце суму пакупкі",
    buySteTips4:
      "Увядзіце колькасць токенаў $TGS, якія вы хочаце набыць. Звярніце ўвагу, што ў дадатак да кошту токена, які вы збіраецеся набыць, для завяршэння транзакцыі патрабуецца мінімум 0,015 ETH. На Binance Smart Chain гэта мінімальнае патрабаванне ніжэйшае, але ўсё яшчэ прымяняецца. Мінімальная колькасць токенаў $TGS, якія можна набыць, складае 100 $TGS.",
    buyStepName5: "Крок 5: завяршыце пакупку",
    buySteTips5:
      "Пасля ўводу сумы вам будзе прапанавана аўтарызаваць транзакцыю. Пры націску «Зацвердзіць» ваша транзакцыя будзе ініцыявана і праверана ў блокчейне. Час завяршэння транзакцыі можа вар'іравацца ад секунд да хвілін у залежнасці ад загружанасці сеткі і актыўнасці смарт-кантракту. Калі ласка, заставайцеся на інтэрфейсе, пакуль купля не будзе цалкам выканана.",
    contactUs: "Звяжыцеся з намі",
    footerText: "tgslots Усе правы абаронены",
    privacy: "Прыватнасць",
    terms: "Умовы",
    cookie: "Прыватнасць",
    stepName: "Крок",
    fundName: "Гуляйце ананімна з бяспекай вашых сродкаў",
    fundDesc:
      "Дзякуючы дэцэнтралізаванай прыродзе блокчейна, мы не патрабуем ад вас KYC і не павінны захоўваць вашы сродкі цэнтралізавана. Такім чынам, вам не трэба перадаваць вашу асабістую інфармацыю і вам не трэба турбавацца аб захаванасці вашых сродкаў. Усё, што вам трэба, гэта ўліковы запіс Telegram, некалькі крыптавалют, і ўсё гатова!",
    fundTips1:
      "Калі ў вас яшчэ няма ўліковага запісу Telegram, спампуйце праграму і стварыце ўліковы запіс. Затым адкрыйце TGSlots па спасылцы «Адкрыць TGSlots» вышэй. Калі ў вас ужо ёсць уліковы запіс Telegram, націсніце кнопку «Адкрыць TGSlots», каб адразу пачаць!",
    fundTips2:
      "Вы можаце выбраць перавод сродкаў на свой рахунак TGSlots з вашага крыпта-кашалька, вашага рахунку CEX або вашай крэдытнай карты (папоўніўшы свой кашалёк TON). Як толькі вы скончыце дэпазіт, вы гатовыя да працы!",
    fundTips3:
      "Выбірайце гульню і наперад! Атрымлівайце асалоду ад кругласутачнай беспраблемнай гульні ў TGSlots з прафесійнай падтрымкай кліентаў у рэжыме чакання 24/7.",
    openTips: "Адкрыйце TGSlots",
    download: "Спампаваць Telegram",
  },
};
