const baseURLMap = {
  // 正式
  HASH_NET: "https://bscscan.com",
  RPCURL_API: "https://bsc-dataseed.binance.org",
  TOKEN_ADDRESS: "0x7c344B2848BDC24D8be3F74fc8C9B9FCD822FEC0",
  STAKINGMANAGERV1_ADDRESS: "0xEA2f18ffFE63e459b9771456F11ecdFd220D6BA4",
  PRESALEV1_PROXY_ADDRESS: "0x8C38bA99bE2a584C8165EbA538cd39cc9ac23609",
  USDT_ADDRESS: "0x55d398326f99059ff775485246999027b3197955",
  USDC_ADDRESS: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  TEN_BILLION: 10000000000,
  TEN_MILLION: 10000000,
  TWENTY_PERCENT: 0.2,
  CURRENT_PRICE: 0.0172,
  CYCLE_TIME: 200, // 时间周期暂定200小时
  INITIAL_END_TIME: "2024-06-24 23:59:59", // 初始结束时间
  TERMINATION_TIME: "2026-06-25 23:59:59", // 终止时间
  WHITEPAPER_URL: "https://tgslots.gitbook.io/tgslots/", // 白皮书地址
  FIRSTBLOCK: 39539668,
  TWENTY_MILLION: 2000000000,
  // 测试
  // HASH_NET: "https://testnet.bscscan.com",
  // RPCURL_API: "https://bsc-testnet-dataseed.bnbchain.org",
  // TOKEN_ADDRESS: "0xa5b02998e6520589eB43c5E82F033397aff3c34E",
  // STAKINGMANAGERV1_ADDRESS: "0xC183315707f523BD1FFE2Fce1259E200B488ac07",
  // PRESALEV1_PROXY_ADDRESS: "0x09edd065AB232c6e09dedb302FC20F92B7ec1364",
  // USDT_ADDRESS: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  // USDC_ADDRESS: "0x64544969ed7EBf5f083679233325356EbE738930",
  // TEN_BILLION: 10000000000,
  // TEN_MILLION: 10000000,
  // TWENTY_PERCENT: 0.2,
  // CURRENT_PRICE: 0.0172,
  // CYCLE_TIME: 200, // 时间周期暂定200小时
  // INITIAL_END_TIME: "2024-06-24 23:59:59", // 初始结束时间
  // TERMINATION_TIME: "2026-06-25 23:59:59", // 终止时间
  // WHITEPAPER_URL: "https://tgslots.gitbook.io/tgslots/", // 白皮书地址
};

const baseURL = baseURLMap;
module.exports = {
  baseURL,
};
