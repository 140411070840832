// 英语
export default {
  message: {
    // 交易提示
    unStakeErrorTips:
      "The pledged tokens can only be released from pledge after being listed on the exchange.",
    copyTips: "copy",
    tgsName: "$TGS token address",
    tgsTips:
      "This is the token address. Do not transfer to this address. If you want to buy tokens, please use pre-sale genuine noodles.",
    succeeTips: "Transaction Succeeded",
    failTips: "Transaction Failed, Please Try Again",
    viewTips: "View on BSC Scan: ",
    // Home 模块
    approve: "Approve",
    menuHome: "Home",
    menuPresale: "Presale",
    menuWork: "How It Works",
    menuStaking: "Staking Rewards",
    menuToken: "Token Economics",
    menuBuy: "How To Buy",
    menuFAQ: "FAQ",
    homeTips: "The world's best slots on Telegram",
    slot: "SLOTS",
    join: "Join Community",
    play: "Play now",
    letMeIn: "Let Me In",
    presaleTop: "PRESALE",
    event: "Presale Event",
    http: "https://TGSlots/#home",
    desc: "TGSlots is the exclusive place for online Slots and other casino games on Telegram. Fully licensed and your funds 100% safe with non-custodial wallets. Get our $TGS token to share a part of our earnings!",
    title: "Why Get $TGS in the Presale?",
    tips1: "Exclusive Rewards",
    currentPrice: "Current price",
    achieved: "Our Goal",
    increases: "Buy now before price increases in:",
    pay: "you pay",
    max: "MAX",
    receive: "$TGS you receive",
    buyRequire: "Please purchase at least 100 $TGS.",
    enoughTips: "You do not have enough",
    enoughTips1: "to pay for this transaction.",
    gasPayTips: "Make sure you have enough",
    gasPayTips1: "for gas payment",
    gasTips: "Limited-Time Offer",
    gasTips1:
      "TGSlots fully subsidizes gas fees when you buy $TGS, please contact customer support to claim. ",
    walletTips: "Wallet",
    balanceTips: "Balance",
    toContact: "Click to contact",
    purchaseTips:
      "If you cannot purchase normally using a third-party browser, please copy the link to the wallet's built-in browser to access it. ",
    copyLink: "Copy Link",
    apy: "Current Staking APY :",
    buyAndStake: "Buy and Stake for APY",
    apyTips: "APY",
    justBuy: "Just want to buy without staking ",
    connectWallet: "Connect Wallet",
    wallet: "How to add $TGS to my wallet",
    // NavStaking
    navStakTips:
      "TGSlots lets you stake and unstake your tokens. Choose which function you would like. Only staked tokens earn APY.",
    blockStakName1: "Current APY",
    blockStakName2: "Total $TGS staked",
    blockStakName3: "% of Total $TGS Staked",
    blockStakName4: "Total Rewards Paid ($TGS)",
    youStaking: "Your Staking",
    staked1: "Total $TGS staked",
    staked2: "% of Total $TGS Staked",
    harvested: "Total Rewards Harvested ($TGS)",
    claimable: "Total Rewards Claimable ($TGS)",
    receiveTips: "Receive",
    stake: "Stake",
    unStake: "Unstake",
    learnMore: "Learn More",
    indicatedAPY:
      "Stake your tokens to receive passive rewards based on the indicated APY",
    unStakeTips: "Unstake your tokens and redeem your rewards from staking",
    //preSale 模块
    preSaleName: "Why Get $TGS in the Presale?",
    presaleTitle1: "Exclusive Rewards",
    presaleTips1:
      "Participants in our presale are entitled to exclusive rewards when they play TGSlots",
    presaleTitle2: "Best Price Ever",
    presaleTips2:
      "There will never be a better time to get $TGS than during presale because $TGS will moon as our platform revenue grows.",
    presaleTitle3: "Rewards for High Roller",
    presaleTips3:
      "Invest 5K USD or more in our presale to get rewared with exclusive NFTs and other post-launch rewards!",
    presaleTitle4: "Start Earning Today",
    presaleTips4:
      "Get $TGS in our presale and you will be able to stake immediately to unlock rewards right away!",
    rewards1:
      "Participants in our presale are entitled to exclusive rewards when they play TGSlots Best Price Ever",
    tips2: "Best Price Ever",
    rewards2:
      "There will never be a better time to get $TGS than during presale because $TGS will moon as our platform revenue grows.",
    tips3: "Rewards for High Roller",
    rewards3:
      "Invest 5K USD or more in our presale to get rewared with exclusive NFTs and other post-launch rewards!",
    tips4: "Start Earning Today",
    rewards4:
      "Get $TGS in our presale and you will be able to stake immediately to unlock rewards right away!",
    // Work 模块
    work: "How it works",
    workTips:
      "TGSlots has everything that an online casino has to offer, plus our unique incentive system that makes users our shareholders and be rewarded constantly by holding onto $TGS",
    stakTitle: "Staking Rewards",
    stakTips:
      "By staking $TGS purchased during the pre-sale and earned in-game, you will become our shareholder and share a part of our earnings at a high APY!",
    incomTitle: "Earn passive income",
    incomTips:
      "Watch your $TGS holdings grow over time, even while you are asleep. The magic happends the moment when you stake your $TGS",
    giftTitle: "Claim Rewards Instantly",
    giftTips:
      "You can claim rewards from staking as and when you wish. You may use it as chips in TGSlots, or restake it back into the staking pool for even more rewards!",
    cashTitle: "More Earning Opportunities",
    cashTips:
      "When you stake $TGS you unlock access to rewards based on our platform revenue. Check our staking page from time to time for updates!",
    tokenTitle: "Token Economics",
    tokenTips:
      "Our token economics is designed to align incentives and achieve a sustainable win-win situation for all token holders involved.",
    itemTitle1: "Presale",
    itemTitle2: "Staking Rewards",
    itemTitle3: "Liquidity Pool",
    itemTitle4: "Players Rewards",
    itemTitle5: "Cooperative agent",
    itemTitle6: "Institutional investors",
    itemTitle7: "Community treasury",
    itemTitle8: "Marketing",
    itemTips1: "To jump start the $TGS ecosystem",
    itemTips2: "To reward our dedicated holders",
    itemTips3: "To create the pair on DEX and CEX",
    itemTips4: "Rewards for every player",
    itemTips5: "Rewards for those who help grow TGSlots",
    itemTips6: "Reserved for investment organizations investing in TGSlots",
    itemTips7: "For community strategic reserve",
    itemTips8: "For cooperation and direct marketing",
    jumpName: "Jump Right In",
    jumpTips:
      "Simply open TGSlots in Telegram and top up your account with major cryptocurrencies / credit cards to get started!",
    safeTips1: "Licensed and Non-Custodial",
    safeTips2: "No Account Setup Needed",
    safeTips3: "Play on your Smartphone",
    safeTips4: "Fully integrated into Telegram",
    jumpPlay: "Play now",
    whitepaper: "Whitepaper",
    whitepaperTips:
      "For people that are equally serious about TGSlots as we do, we have prepared the whitepaper that explores the details and the thinkings behind making TGSlots. Read it to see how we are different from other online casinos!",
    FqName: "Frequently Asked Questions",
    FqTitle1: "What makes TGSlots different?",
    FqTips1:
      "TGSlots give you access to all of the top online casino games, sport books and more, but we're also committed to cultivating an ecosystem that values loyalty. As a testament to our dedication, we're giving back by sharing a portion of our casino revenue through buy backs for the $TGS token. Play, earn, and be rewarded!",
    FqTitle2: "How long is the presale?",
    FqTips2:
      "The presale will be open until the hard cap is reached. The soft cap for the presale is 2 million USD.",
    FqTitle3: "Why should I buy $TGS?",
    FqTips3:
      "While TGSlots will let you play with many top cryptocurrencies, only $TGS will give you access to incredible rewards, both from staking and just holding our token. $TGS is not required for playing in TGSlots, but it can be used to wager in games like all other currencies.",
    FqTitle4: "Do I get the $TGS tokens from the presale immediately?",
    FqTips4:
      "There is no vesting period for $TGS. However if you stake your tokens during the presale they will be locked until launch day + 7 days. Staking the tokens immediately begins earning staking rewards and other future benefits.",
    FqTitle5: "When will the token launch?",
    FqTips5:
      "We will launch $TGS within days of the presale ending. We will keep everyone informed of launch day through our website and social media channels.",
    stakingName: "Staking",
    stakingTips:
      "TGSlots lets you stake and unstake your $TGS tokens as and when you wish. But be careful, only staked tokens earn APY! Details",
    sakeips1: "Current APY",
    sakeips2: "Total $TGS in Staking Pool",
    sakeips3: "Your % ownership in Staking Pool ($TGS)",
    sakeips4: "Total Payout ($TGS)",
    // Buy 模块
    buyName: "How To Buy",
    buyTips:
      "To purchase tokens during the Presale, visit TGSlots  official website. After the Presale, tokens can be obtained directly from the official website or through decentralized exchanges.",
    buyTitle: "How to buy $TGS during the presale",
    buyStepName1: "Step 1: Visit the official portal",
    buySteTips1:
      "Visit TGSlots’s dedicated webpage to find the exclusive Presale purchase interface. Please note that this is the only authorized platform to obtain Presale $TGS tokens.",
    buyStepName2: "Step 2: Choose payment method",
    buySteTips2:
      "You can choose to purchase the Presale $TGS token on the Ethereum network using Ethereum (ETH) or Tether (USDT). Alternatively, you can leverage Binance Smart Chain and choose BNB or USDT. Make your choice and proceed to the next step.",
    buyStepName3: "Step 3: Connect your crypto wallet",
    buySteTips3:
      "If your crypto wallet has not synced yet, you will see a prompt asking you to connect. Typically, wallets are set up for Ethereum mainnet by default; this works to your advantage if you trade with ETH. However, if you choose Binance Smart Chain, you may need to confirm the network switch.",
    buyStepName4: "Step 4: Specify purchase amount",
    buySteTips4:
      "Enter the amount of $TGS tokens you wish to purchase. Please note that in addition to the cost of the token you intend to purchase, a minimum of 0.015 ETH is required to complete the transaction. On Binance Smart Chain, this minimum requirement is lower, but still applies. The minimum amount of $TGS tokens that can be purchased is set to 100 $TGS.",
    buyStepName5: "Step 5: Complete your purchase",
    buySteTips5:
      "Once you have entered the amount, you will be prompted to authorize the transaction. By clicking 'Approve', your transaction will be initiated and verified on the blockchain. Transaction completion times can vary from seconds to minutes, depending on network congestion and smart contract activity. Please stay on the interface until the purchase is fully executed.",
    // Footer
    contactUs: "Contact Us",
    footerText: "tgslots All Rights Reserved",
    privacy: "Privacy",
    terms: "Terms",
    cookie: "Privacy",
    stepName: "Step",
    fundName: "Play Anonymously With Your Funds Safe",
    fundDesc:
      "Thanks to the decentralized nature of blockchain, we do not require KYC from you nor do we need to store your funds in a centralized manner. Hence you do not need to hand over your personal information and you do not need to worry about the safety of your funds. All you need is a Telegram account, some cryptocurrencies and you're ready to go!",
    fundTips1:
      "If you do not have a Telegram account yet, please download the app and create an account. Then, open TGSlots via the 'Open TGSlots' link above. If you have a Telegram account already, click the 'Open TGSlots' to get going rightaway!",
    fundTips2:
      "You can choose to transfer funds to your TGSlots account from either your crypto wallet, your CEX account or your credit card (by topping up your TON Wallet). Once you finish depositing, you're ready to go!",
    fundTips3:
      "Choose a game and get going! Enjoy a 24/7 hassle-free gaming experience at TGSlots, with professional customer support on standby 24/7 too.",
    openTips: "Open TGSlots",
    download: "Download Telegram",
  },
};
