<template>
  <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view
  ></keep-alive>
  <router-view v-if="!$route.meta.keepAlive"></router-view>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
#app {
  font-family: "Open-Sans";
}
</style>
