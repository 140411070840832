<template>
  <div class="pledgeCoin mainBgColor spacing">
    <a name="StakingRecords" class="hidden-anchor"></a>
    <div class="pledgeContainer">
      <el-row :gutter="20">
        <el-col :sm="8">
          <div class="pledgeCoinLogoImg">
            <span class="white block-name">{{ $t("message.stakTitle") }}</span>
            <div class="line">
              <img src="@/assets/image/show.png" class="line-img" alt="line" />
            </div>
            <div class="desc gray">
              {{ $t("message.stakTips") }}
            </div>
          </div>
        </el-col>
        <el-col :sm="7"></el-col>
        <el-col :sm="8">
          <div><img class="webImg" src="@/assets/image/stak.png" alt="" /></div>
        </el-col>
      </el-row>
    </div>
    <div class="step">
      <div class="stepItem" v-for="(item, index) in stepList" :key="index">
        <img class="pledgeCoinImg icon-60" :src="item.img" alt="pledgeCoin" />
        <p class="stepTitle white">{{ $t(item.title) }}</p>
        <p class="stepDesc gray fs12">
          {{ $t(item.tips) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepList: [
        {
          title: "message.incomTitle",
          tips: "message.incomTips",
          img: require("@/assets/image/income.png"),
        },
        {
          title: "message.giftTitle",
          tips: "message.giftTips",
          img: require("@/assets/image/gift.png"),
        },
        {
          title: "message.cashTitle",
          tips: "message.cashTips",
          img: require("@/assets/image/copperCash.png"),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-row {
  align-items: center;
}
.webImg {
  width: 100%;
  height: auto;
}
</style>
