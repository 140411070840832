<template>
  <div class="home">
    <div class="navbar" id="navbar">
      <img alt="logo" class="topLogo" src="@/assets/image/logo.png" />
      <div class="home-right">
        <div class="navContainer">
          <a
            class="menu-item"
            v-for="(item, index) in menuList"
            :key="index"
            :href="'#' + item.id"
            @click="selectMenu"
            >{{ $t(item.name) }}</a
          >
        </div>
        <div class="flex">
          <div class="pledge m-r-30">
            <a class="white" @click="cickStaking" href="#Staking">{{
              $t("message.stakingName")
            }}</a>
          </div>
          <el-dropdown trigger="click" style="background: #23252a">
            <div class="menuLink">
              <img
                class="zhImg icon-32 m-r-5"
                :src="itemLang.img"
                alt="zhImg"
              />
              <p>{{ itemLang.lang }}</p>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in langList"
                  :key="index"
                  @click="selectClick(item)"
                >
                  <div class="flex-center">
                    <img :src="item.img" alt="" class="lang-img icon-32" />
                    <div class="active-name m-l-10">{{ item.lang }}</div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="menuImg" @click="dialogHeaderVisible = true">
          <!-- <img alt="menu" :src="'https://TGSlots/img/menu.png'" /> -->
          <el-icon size="24px" color="#fff"><Grid /></el-icon>
        </div>
      </div>
      <el-dialog v-model="dialogHeaderVisible" class="mock">
        <div class="mobileNavContainer">
          <a
            class="menu-item"
            v-for="(item, index) in menuList"
            :key="index"
            :href="'#' + item.id"
            @click="selectMenu"
            >{{ $t(item.name) }}</a
          >
        </div>
      </el-dialog>
    </div>
    <div v-if="isShowHome === 'true'" class="content-warpper">
      <Home @selectMenu="selectMenu" />
      <Presale></Presale>
      <Work />
      <StakingRecords />
      <Token />
      <Jump />
      <FundSafe />
      <Whitepaper></Whitepaper>
      <Frequently></Frequently>
      <Footer @selectMenu="selectMenu" />
    </div>
    <div v-else-if="isToBuy === 'true'" class="content-warpper">
      <Buy />
      <Footer @selectMenu="selectMenu" />
    </div>
    <div
      class="navStaking-main content-warpper"
      v-else-if="isShowStaking === 'true'"
    >
      <NavStaking />
      <Footer @selectMenu="selectMenu" />
    </div>
  </div>
</template>

<script>
import common from "@/api/common";
import Home from "@/components/Home.vue";
import Presale from "@/components/Presale.vue";
import Work from "@/components/Work.vue";
import Buy from "@/components/Buy.vue";
import StakingRecords from "@/components/StakingRecords.vue";
import Token from "@/components/Token.vue";
import Jump from "@/components/Jump.vue";
import Whitepaper from "@/components/Whitepaper.vue";
import FundSafe from "@/components/FundSafe.vue";
import Frequently from "@/components/Frequently.vue";
// import Staking from "@/components/Staking.vue";
import NavStaking from "@/components/NavStaking.vue";
import Footer from "@/components/Footer.vue";
import { Grid } from "@element-plus/icons-vue";
export default {
  name: "homeView",
  components: {
    Buy,
    Home,
    Presale,
    Work,
    StakingRecords,
    Token,
    Jump,
    FundSafe,
    Whitepaper,
    Frequently,
    // Staking,
    NavStaking,
    Footer,
    Grid,
  },
  data() {
    return {
      isShowStaking: "",
      isToBuy: "",
      isShowHome: "true",
      dialogHeaderVisible: false,
      menuList: common.menuList,
      langList: common.langList,
      itemLang: {},
    };
  },
  created() {
    this.isShowStaking = localStorage.getItem("isShowStaking")
      ? localStorage.getItem("isShowStaking")
      : false;
    this.isToBuy = localStorage.getItem("isToBuy")
      ? localStorage.getItem("isToBuy")
      : false;
    this.isShowHome = localStorage.getItem("isShowHome")
      ? localStorage.getItem("isShowHome")
      : "true";
  },
  mounted() {
    this.itemLang = sessionStorage.getItem("itemLang")
      ? JSON.parse(sessionStorage.getItem("itemLang"))
      : {
          id: "en",
          lang: "English",
          img: require("@/assets/image/icon_mg.png"),
        };
  },
  methods: {
    howToBuy() {
      this.isToBuy = "true";
      this.isShowStaking = false;
      this.isShowHome = false;
      localStorage.setItem("isShowStaking", this.isShowStaking);
      localStorage.setItem("isToBuy", this.isToBuy);
      localStorage.setItem("isShowHome", this.isShowHome);
    },
    selectClick(e) {
      this.itemLang = e;
      sessionStorage.setItem("locale", e.id);
      sessionStorage.setItem("itemLang", JSON.stringify(e));
      this.$nextTick(() => {
        this.menuList = common.menuList;
      });
      this.$i18n.locale = e.id;
    },
    selectMenu(event) {
      const href = event.target.getAttribute("href");
      window.location.hash = href;
      if (href === "#HowToBuy") {
        this.howToBuy();
      } else {
        this.isShowHome = "true";
        this.isToBuy = false;
        this.isShowStaking = false;
        localStorage.setItem("isShowStaking", this.isShowStaking);
        localStorage.setItem("isToBuy", this.isToBuy);
        localStorage.setItem("isShowHome", this.isShowHome);
      }
      this.dialogHeaderVisible = false;
    },
    cickStaking() {
      this.isShowStaking = "true";
      this.isToBuy = false;
      this.isShowHome = false;
      localStorage.setItem("isShowStaking", this.isShowStaking);
      localStorage.setItem("isToBuy", this.isToBuy);
      localStorage.setItem("isShowHome", this.isShowHome);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-overlay {
  background: #00000073 !important;
}
@media screen and (max-width: 1000px) {
  ::v-deep .el-dialog {
    margin: 0;
  }
}
// .el-dropdown-menu {
//   background: #23252a !important;
//   border: none !important;
//   ::v-deep .el-dropdown-menu__item {
//     color: #fff !important;
//   }
// }
// .el-dropdown__popper {
//   border: none !important;
//   background: none !important;
// }
// ::v-deep .el-popper.is-light {
//   border: none !important;
//   background: none !important;
// }
// ::v-deep .el-popper.is-light .el-popper__arrow::before {
//   background-color: #2e3946;
// }
.home {
  overflow-x: hidden;
  a {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-right: 0;
    padding: 0 20px;
    text-decoration: none;
    white-space: nowrap;
  }
  .home-right {
    display: flex;
    align-items: center;
  }
  .menuLink {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .el-dropdown {
    align-items: center;
    border-radius: 22px;
    color: #fff;
    cursor: pointer;
    height: 44px;
    padding-left: 10px;
    background: #23252a;
    min-width: 150px !important;
  }
  .pledge {
    align-items: center;
    background-color: rgba(13, 153, 255, 1);
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    justify-content: center;
    min-width: 104px;
  }
}
</style>
