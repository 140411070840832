<template>
  <div class="howtobuyContainer mainBgColor spacing">
    <a name="HowToBuy" class="hidden-anchor"></a>
    <div class="howtoBuyTop">
      <div class="white block-name">{{ $t("message.buyName") }}</div>
      <div class="desc gray">
        {{ $t("message.buyTips") }}
      </div>
    </div>
    <div class="step">
      <div class="stepTopTitle white fs32 m-b-20">
        {{ $t("message.buyTitle") }}
      </div>
      <div
        class="stepItem white"
        v-for="(item, index) in stepList"
        :key="index"
      >
        <p class="stepTitle">{{ $t(item.name) }}</p>
        <p class="stepDesc">
          {{ $t(item.tips) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepList: [
        {
          name: "message.buyStepName1",
          tips: "message.buySteTips1",
        },
        {
          name: "message.buyStepName2",
          tips: "message.buySteTips2",
        },
        {
          name: "message.buyStepName3",
          tips: "message.buySteTips3",
        },
        {
          name: "message.buyStepName4",
          tips: "message.buySteTips4",
        },
        {
          name: "message.buyStepName5",
          tips: "message.buySteTips5",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.howtobuyContainer {
  overflow: hidden;
  .stepItem {
    background: #0f182e;
    border: 1px solid #0f182e;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 48px 40px;
  }
}
</style>
