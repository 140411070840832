import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n/index";
import router from "./router";
import store from "./store";
import animate from "animate.css";
import "@/assets/common.scss";
import "@/assets/flex.scss";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueClipboard from "vue-clipboard2";
import "./web";
createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(animate)
  .use(ElementPlus)
  .use(VueClipboard)
  .mount("#app");
