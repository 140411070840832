import { createStore } from "vuex";
import {
  getToken,
  removeToken,
  setToken,
  setGuid,
  getGuid,
  removeGuid,
} from "@/utils/auth";
export default createStore({
  namespaced: true,
  state: {
    token: getToken(),
    guid: getGuid(),
  },
  getters: {},
  mutations: {
    set_token: (state, token) => {
      state.token = token;
      setToken(token);
    },
    set_guid: (state, guid) => {
      state.guid = guid;
      setGuid(guid);
    },
  },
  actions: {
    // 清除token
    resetToken({ commit }) {
      return new Promise((resolve) => {
        commit("set_token", "");
        removeToken();
        resolve();
      });
    },
    // 清除guid
    resetGuid({ commit }) {
      return new Promise((resolve) => {
        commit("set_guid", "");
        removeGuid();
        resolve();
      });
    },
  },
  modules: {},
});
