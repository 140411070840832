<template>
  <div class="whiteBookContainer mainBgColor spacing">
    <el-row :gutter="20">
      <el-col :sm="10">
        <div>
          <div class="whiteBookDesc">
            <div class="whiteBookLogo">
              <span class="white block-name">{{
                $t("message.whitepaper")
              }}</span>
              <div class="line">
                <img
                  src="@/assets/image/show.png"
                  class="line-img"
                  alt="line"
                />
              </div>
            </div>
            <img
              class="whiteBookImgMobile"
              src="@/assets/image/three-D.png"
              alt="whiteBookImgMobile"
            />
            <div class="desc gray">{{ $t("message.whitepaperTips") }}</div>
            <div class="white-btn common-btn" @click="openWebUrl()">
              {{ $t("message.whitepaper") }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :sm="7"></el-col>
      <el-col :sm="5">
        <img
          class="whiteBookImg"
          src="@/assets/image/three-D.png"
          alt="whiteBookImg"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { baseURL } from "@/api/config";
export default {
  methods: {
    openWebUrl() {
      window.open(baseURL.WHITEPAPER_URL);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-row {
  align-items: center;
}
.whiteBookContainer {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: space-between;
  .whiteBookImg {
    height: auto;
    width: 100%;
    margin-top: 25px;
  }
  .white-btn {
    width: 200px;
    background-color: #0085ff;
    border: 1px solid #0085ff;
    font-size: 20px;
  }
}
</style>
