//  越南语（Tiếng Việt）
export default {
  message: {
    unStakeErrorTips:
      "Stake Token chỉ có thể được giải phóng sau khi niêm yết trên sàn giao dịch.",
    copyTips: "sao chép",
    tgsName: "Địa chỉ mã thông báo $TGS",
    tgsTips:
      "Đây là địa chỉ mã thông báo. Không chuyển đến địa chỉ này. Nếu bạn muốn mua token, vui lòng sử dụng mì chính hãng bán trước.",
    succeeTips: "Giao dịch thành công",
    failTips: "Giao dịch không thành công, vui lòng thử lại",
    viewTips: "Xem trên BSC Scan: ",
    approve: "Chấp thuận",
    menuHome: "Trang chủ",
    menuPresale: "Bán trước",
    menuWork: "Cách hoạt động",
    menuStaking: "Phần thưởng vốn chủ sở hữu",
    menuToken: "Kiểm soát Token",
    menuBuy: "Cách mua",
    menuFAQ: "Câu hỏi thường gặp",
    homeTips: "Máy đánh bạc tốt nhất thế giới trên Telegram",
    slot: "SLOT",
    join: "Tham gia cộng đồng",
    play: "Bắt đầu chơi",
    letMeIn: "Cho tôi vào",
    presaleTop: "BÁN TRƯỚC",
    event: "Sự kiện bán trước",
    http: "https://TGSlots/#home",
    desc: "TGSlots là nơi độc quyền dành cho Slots trực tuyến và các trò chơi sòng bạc khác trên Telegram. Được cấp phép đầy đủ và tiền của bạn an toàn 100% với ví không giám sát. Nhận mã thông báo $TGS của chúng tôi để chia sẻ một phần thu nhập của chúng tôi!",
    title: "Tại sao nhận được $TGS trong đợt bán trước?",
    tips1: "Phần thưởng độc quyền",
    currentPrice: "Giá hiện tại",
    achieved: "Mục tiêu của chúng tôi",
    increases: "Mua ngay trước khi tăng giá vào:",
    pay: "bạn trả",
    max: "TỐI ĐA",
    receive: "$TGS bạn nhận được",
    buyRequire: "Vui lòng mua ít nhất 100 $TGS.",
    enoughTips: "Bạn không có đủ",
    enoughTips1: "để thanh toán cho giao dịch này.",
    gasPayTips: "Hãy chắc chắn rằng bạn có đủ",
    gasPayTips1: "để thanh toán tiền gas",
    gasTips: "Ưu đãi trong thời gian có hạn",
    gasTips1:
      "TGSlots hỗ trợ toàn bộ phí gas khi bạn mua $TGS, vui lòng liên hệ bộ phận hỗ trợ khách hàng để yêu cầu. ",
    walletTips: "Cái ví",
    balanceTips: "Sự cân bằng",
    toContact: "Bấm để liên hệ",
    purchaseTips:
      "Nếu bạn không thể mua hàng bình thường bằng trình duyệt của bên thứ ba, vui lòng sao chép liên kết tới trình duyệt tích hợp của ví để truy cập.",
    copyLink: "Sao chép đường dẫn",
    apy: "APY đặt cược hiện tại :",
    buyAndStake: "Mua và đặt cược cho APY",
    apyTips: "APY",
    justBuy: "Chỉ muốn mua mà không cần đặt cược",
    connectWallet: "Kết nối ví",
    wallet: "Cách thêm $TGS vào ví của tôi",
    navStakTips:
      "TGSlots cho phép bạn đặt cược và hủy đặt cược mã thông báo của mình. Chọn chức năng bạn muốn. Chỉ các token đã đặt cọc mới kiếm được APY.",
    blockStakName1: "APY hiện tại",
    blockStakName2: "Tổng số $TGS đã đặt cược",
    blockStakName3: "% trên tổng số $TGS được đặt cược ",
    blockStakName4: "Tổng số tiền thưởng được trả ($TGS)",
    youStaking: "Đặt cược của bạn",
    staked1: "Tổng số $TGS đã đặt cược",
    staked2: "% trên tổng số $TGS được đặt cược",
    harvested: "Tổng số phần thưởng thu được ($TGS)",
    claimable: "Tổng số phần thưởng có thể nhận được ($TGS)",
    receiveTips: "Nhận được",
    stake: "Cổ phần",
    unStake: "Hủy đặt cược",
    learnMore: "Tìm hiểu thêm",
    indicatedAPY:
      "Đặt cược mã thông báo của bạn để nhận phần thưởng thụ động dựa trên APY được chỉ định",
    unStakeTips:
      "Hủy đặt cược mã thông báo của bạn và đổi phần thưởng của bạn từ việc đặt cược",
    preSaleName: "Tại sao nhận được $TGS trong đợt bán trước?",
    presaleTitle1: "Phần thưởng độc quyền",
    presaleTips1:
      "Những người tham gia đợt bán trước của chúng tôi được hưởng phần thưởng độc quyền khi họ chơi TGSlots",
    presaleTitle2: "Giá tốt nhất từ trước đến nay",
    presaleTips2:
      "Sẽ không bao giờ có thời điểm nào tốt hơn để nhận được $TGS hơn thời điểm bán trước vì $TGS sẽ tăng khi doanh thu nền tảng của chúng tôi tăng lên.",
    presaleTitle3: "Phần thưởng cho Con lăn cao",
    presaleTips3:
      "Đầu tư 5 nghìn USD trở lên vào đợt bán trước của chúng tôi để nhận được NFT độc quyền và các phần thưởng sau khi ra mắt khác!",
    presaleTitle4: "Bắt đầu kiếm tiền ngay hôm nay",
    presaleTips4:
      "Nhận $TGS trong đợt bán trước của chúng tôi và bạn sẽ có thể đặt cược ngay lập tức để mở khóa phần thưởng ngay lập tức!",
    rewards1:
      "Những người tham gia đợt bán trước của chúng tôi được hưởng phần thưởng độc quyền khi họ chơi TGSlots Giá tốt nhất từ trước đến nay",
    tips2: "Giá tốt nhất từ trước đến nay",
    rewards2:
      "Sẽ không bao giờ có thời điểm nào tốt hơn để nhận được $TGS hơn thời điểm bán trước vì $TGS sẽ tăng khi doanh thu nền tảng của chúng tôi tăng lên.",
    tips3: "Phần thưởng cho Con lăn cao",
    rewards3:
      "Đầu tư 5 nghìn USD trở lên vào đợt bán trước của chúng tôi để nhận được NFT độc quyền và các phần thưởng sau khi ra mắt khác!",
    tips4: "Bắt đầu kiếm tiền ngay hôm nay",
    rewards4:
      "Nhận $TGS trong đợt bán trước của chúng tôi và bạn sẽ có thể đặt cược ngay lập tức để mở khóa phần thưởng ngay lập tức!",
    work: "Làm thế nào nó hoạt động",
    workTips:
      "TGSlots có mọi thứ mà một sòng bạc trực tuyến phải cung cấp, cộng với hệ thống khuyến khích độc đáo của chúng tôi giúp người dùng trở thành cổ đông của chúng tôi và được thưởng liên tục bằng cách nắm giữ $TGS",
    stakTitle: "Đặt cược phần thưởng",
    stakTips:
      "Bằng cách đặt cược $TGS đã mua trong đợt bán trước và kiếm được trong trò chơi, bạn sẽ trở thành cổ đông của chúng tôi và chia sẻ một phần thu nhập của chúng tôi với APY cao!",
    incomTitle: "Kiếm thu nhập thụ động",
    incomTips:
      "Xem số tiền nắm giữ TGS của bạn tăng lên theo thời gian, ngay cả khi bạn đang ngủ. Điều kỳ diệu sẽ xảy ra vào thời điểm bạn đặt cược $TGS của mình",
    giftTitle: "Yêu cầu phần thưởng ngay lập tức",
    giftTips:
      "Bạn có thể nhận phần thưởng từ việc đặt cược bất cứ lúc nào bạn muốn. Bạn có thể sử dụng nó làm chip trong TGSlots hoặc đặt lại nó vào nhóm đặt cược để nhận được nhiều phần thưởng hơn nữa!",
    cashTitle: "Thêm cơ hội kiếm tiền",
    cashTips:
      "Khi bạn đặt cược $TGS, bạn sẽ có quyền truy cập vào phần thưởng dựa trên doanh thu nền tảng của chúng tôi. Thỉnh thoảng hãy kiểm tra trang đặt cược của chúng tôi để biết thông tin cập nhật!",
    tokenTitle: "Kinh tế mã thông báo",
    tokenTips:
      "Nền kinh tế mã thông báo của chúng tôi được thiết kế để điều chỉnh các biện pháp khuyến khích và đạt được tình hình đôi bên cùng có lợi bền vững cho tất cả chủ sở hữu mã thông báo có liên quan.",
    itemTitle1: "Bán trước",
    itemTitle2: "Đặt cược phần thưởng",
    itemTitle3: "Nhóm thanh khoản",
    itemTitle4: "Phần thưởng người chơi",
    itemTitle5: "Đại lý hợp tác",
    itemTitle6: "Tổ chức đầu tư",
    itemTitle7: "Kho bạc cộng đồng",
    itemTitle8: "Tiếp thị",
    itemTips1: "Để bắt đầu hệ sinh thái $TGS",
    itemTips2: "Để thưởng cho những người nắm giữ tận tâm của chúng tôi",
    itemTips3: "Để tạo cặp trên DEX và CEX",
    itemTips4: "Phần thưởng cho mỗi người chơi",
    itemTips5: "Phần thưởng dành cho những người giúp phát triển TGSlots",
    itemTips6: "Dành riêng cho các tổ chức đầu tư đầu tư vào TGSlots",
    itemTips7: "Dành cho dự trữ chiến lược cộng đồng",
    itemTips8: "Để hợp tác và tiếp thị trực tiếp",
    jumpName: "Nhảy ngay vào",
    jumpTips:
      "Chỉ cần mở TGSlots trong Telegram và nạp tiền vào tài khoản của bạn bằng các loại tiền điện tử/thẻ tín dụng chính để bắt đầu!",
    safeTips1: "Được cấp phép và không bị giam giữ",
    safeTips2: "Không cần thiết lập tài khoản",
    safeTips3: "Chơi trên điện thoại thông minh của bạn",
    safeTips4: "Tích hợp hoàn toàn vào Telegram",
    jumpPlay: "Bắt đầu chơi",
    whitepaper: "Giấy trắng",
    whitepaperTips:
      "Đối với những người cũng nghiêm túc về TGSlots như chúng tôi, chúng tôi đã chuẩn bị báo cáo nghiên cứu chuyên sâu khám phá các chi tiết và suy nghĩ đằng sau việc tạo ra TGSlots. Hãy đọc nó để xem chúng tôi khác biệt như thế nào so với các sòng bạc trực tuyến khác!",
    FqName: "Các câu hỏi thường gặp",
    FqTitle1: "Điều gì làm cho TGSlots trở nên khác biệt?",
    FqTips1:
      "TGSlots cung cấp cho bạn quyền truy cập vào tất cả các trò chơi sòng bạc trực tuyến hàng đầu, sách thể thao và hơn thế nữa, nhưng chúng tôi cũng cam kết phát triển một hệ sinh thái coi trọng lòng trung thành. Như một minh chứng cho sự cống hiến của chúng tôi, chúng tôi sẽ đền đáp bằng cách chia sẻ một phần doanh thu từ sòng bạc của mình thông qua việc mua lại mã thông báo $TGS. Chơi, kiếm tiền và được khen thưởng!",
    FqTitle2: "Thời gian bán trước là bao lâu?",
    FqTips2:
      "Đợt bán trước sẽ mở cho đến khi đạt đến mức hard cap. Mức soft cap cho đợt bán trước là 2 triệu đô la Mỹ.",
    FqTitle3: "Tại sao tôi nên mua $TGS?",
    FqTips3:
      "Mặc dù TGSlots sẽ cho phép bạn chơi với nhiều loại tiền điện tử hàng đầu, nhưng chỉ $TGS mới cấp cho bạn quyền truy cập vào các phần thưởng đáng kinh ngạc, cả từ việc đặt cược và chỉ giữ mã thông báo của chúng tôi. Không cần phải có $TGS để chơi trong TGSlots, nhưng nó có thể được sử dụng để đặt cược trong các trò chơi giống như tất cả các loại tiền tệ khác.",
    FqTitle4:
      "Tôi có nhận được token $TGS từ đợt bán trước ngay lập tức không?",
    FqTips4:
      "Không có thời gian trao quyền cho $TGS. Tuy nhiên, nếu bạn đặt cược mã thông báo của mình trong đợt bán trước, chúng sẽ bị khóa cho đến ngày ra mắt + 7 ngày. Việc đặt cược mã thông báo ngay lập tức bắt đầu kiếm được phần thưởng đặt cược và các lợi ích khác trong tương lai.",
    FqTitle5: "Khi nào mã thông báo sẽ ra mắt?",
    FqTips5:
      "Chúng tôi sẽ ra mắt $TGS trong vòng vài ngày kể từ khi đợt bán trước kết thúc. Chúng tôi sẽ thông báo cho mọi người về ngày ra mắt thông qua trang web và các kênh truyền thông xã hội của chúng tôi.",
    stakingName: "đặt cược",
    stakingTips:
      "TGSlots cho phép bạn đặt cược và hủy đặt cọc token $TGS của mình bất cứ lúc nào bạn muốn. Nhưng hãy cẩn thận, chỉ những token đã đặt cọc mới kiếm được APY! Chi tiết",
    sakeips1: "APY hiện tại",
    sakeips2: "Tổng số $TGS trong quỹ đặt cược",
    sakeips3: "% quyền sở hữu của bạn trong Stake Pool ($TGS)",
    sakeips4: "Tổng số tiền xuất chi ($TGS)",
    buyName: "Lam thê nao để mua",
    buyTips:
      "Để mua token trong đợt Bán trước, hãy truy cập trang web chính thức của TGSlots. Sau đợt Bán trước, bạn có thể nhận được mã thông báo trực tiếp từ trang web chính thức hoặc thông qua các sàn giao dịch phi tập trung.",
    buyTitle: "Cách mua $TGS trong đợt bán trước",
    buyStepName1: "Bước 1: Truy cập cổng thông tin chính thức",
    buySteTips1:
      "Truy cập trang web chuyên dụng của TGSlots để tìm giao diện mua Presale độc quyền. Xin lưu ý rằng đây là nền tảng được ủy quyền duy nhất để nhận mã thông báo Presale $TGS.",
    buyStepName2: "Bước 2: Chọn phương thức thanh toán",
    buySteTips2:
      "Bạn có thể chọn mua mã thông báo Presale $TGS trên mạng Ethereum bằng cách sử dụng Ethereum (ETH) hoặc Tether (USDT). Ngoài ra, bạn có thể tận dụng Binance Smart Chain và chọn BNB hoặc USDT. Hãy lựa chọn và tiến hành bước tiếp theo.",
    buyStepName3: "Bước 3: Kết nối ví tiền điện tử của bạn",
    buySteTips3:
      "Nếu ví tiền điện tử của bạn chưa được đồng bộ hóa, bạn sẽ thấy lời nhắc yêu cầu bạn kết nối. Thông thường, ví được thiết lập mặc định cho mạng chính Ethereum; điều này có lợi cho bạn nếu bạn giao dịch bằng ETH. Tuy nhiên, nếu bạn chọn Binance Smart Chain, bạn có thể cần xác nhận việc chuyển đổi mạng.",
    buyStepName4: "Bước 4: Chỉ định số tiền mua",
    buySteTips4:
      "Nhập số lượng token $TGS bạn muốn mua. Xin lưu ý rằng ngoài chi phí của mã thông báo bạn định mua, cần có tối thiểu 0,015 ETH để hoàn tất giao dịch. Trên Binance Smart Chain, yêu cầu tối thiểu này thấp hơn nhưng vẫn được áp dụng. Số lượng token $TGS tối thiểu có thể mua được được đặt thành 100 $TGS.",
    buyStepName5: "Bước 5: Hoàn tất việc mua hàng của bạn",
    buySteTips5:
      "Khi bạn đã nhập số tiền, bạn sẽ được nhắc ủy quyền giao dịch. Bằng cách nhấp vào 'Chấp thuận', giao dịch của bạn sẽ được bắt đầu và xác minh trên blockchain. Thời gian hoàn thành giao dịch có thể thay đổi từ vài giây đến vài phút, tùy thuộc vào tình trạng tắc nghẽn mạng và hoạt động hợp đồng thông minh. Vui lòng ở lại giao diện cho đến khi giao dịch mua được thực hiện đầy đủ.",
    contactUs: "Liên hệ chúng tôi",
    footerText: "tgslots Mọi quyền được bảo lưu",
    privacy: "Sự riêng tư",
    terms: "Điều kiện",
    cookie: "Sự riêng tư",
    stepName: "Bước chân",
    fundName: "Chơi ẩn danh với tiền của bạn an toàn",
    fundDesc:
      "Nhờ tính chất phi tập trung của blockchain, chúng tôi không yêu cầu bạn KYC cũng như không cần lưu trữ tiền của bạn một cách tập trung. Do đó, bạn không cần phải cung cấp thông tin cá nhân của mình và bạn không cần phải lo lắng về sự an toàn của tiền của mình. Tất cả những gì bạn cần là một tài khoản Telegram, một số loại tiền điện tử và bạn đã sẵn sàng!",
    fundTips1:
      "Nếu bạn chưa có tài khoản Telegram, vui lòng tải xuống ứng dụng và tạo tài khoản. Sau đó, mở TGSlots thông qua liên kết 'Mở TGSlots' ở trên. Nếu bạn đã có tài khoản Telegram, hãy nhấp vào 'Mở TGSlots' để bắt đầu ngay!",
    fundTips2:
      "Bạn có thể chọn chuyển tiền vào tài khoản TGSlots của mình từ ví tiền điện tử, tài khoản CEX hoặc thẻ tín dụng của bạn (bằng cách nạp tiền vào Ví TON của bạn). Khi bạn gửi tiền xong, bạn đã sẵn sàng để đi!",
    fundTips3:
      "Chọn một trò chơi và bắt đầu! Tận hưởng trải nghiệm chơi trò chơi không rắc rối 24/7 tại TGSlots, với dịch vụ hỗ trợ khách hàng chuyên nghiệp luôn sẵn sàng 24/7.",
    openTips: "Mở TGSlots",
    download: "Tải xuống Telegram",
  },
};
