<template>
  <div class="bottombar">
    <div class="showNav flex-center justify-between">
      <img
        alt="project logo"
        src="@/assets/image/logo.png"
        style="width: 136px; height: auto"
      />
      <div class="navContainer">
        <a
          v-for="(item, index) in menuList"
          :key="index"
          :href="'#' + item.id"
          @click="selectMenu"
          >{{ $t(item.name) }}</a
        >
      </div>
    </div>
    <p class="diver"></p>
    <p class="payforTypeTitle fs18">{{ $t("message.contactUs") }}</p>
    <div class="displayItem">
      <div class="payforType">
        <img
          alt="telegram"
          class="cursor"
          src="@/assets/image/telegram-icon.webp.png"
          @click="openWeb('https://t.me/+qeIrYeD1RGs5MTA8')"
        />
        <img
          alt="twitter"
          class="cursor"
          @click="openWeb('https://x.com/TGSLOTS')"
          src="@/assets/image/twitter-icon.webp.png"
        /><img class="cursor" alt="ad" src="@/assets/image/adLogo.webp.png" />
      </div>
      <p class="diverMobile"></p>
      <div class="copyright gray fs14">
        @ 2024 {{ $t("message.footerText") }}
      </div>
      <div class="other">
        <span>{{ $t("message.privacy") }}</span
        ><span>{{ $t("message.terms") }}</span
        ><span>{{ $t("message.cookie") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/api/common";

export default {
  data() {
    return {
      menuList: common.menuList,
    };
  },
  methods: {
    openWeb(url) {
      window.open(url);
    },
    selectMenu(event) {
      this.$emit("selectMenu", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottombar {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 5%;
  background: #0f182e;
  a {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-right: 0;
    padding: 0 20px;
    text-decoration: none;
    white-space: nowrap;
  }
  .diver {
    background-color: #30323a;
    height: 1px;
    margin: 20px 0 30px;
  }
  .displayItem {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .payforType {
      img {
        height: 40px;
        margin-right: 15px;
        width: 40px;
      }
    }
    .other {
      span {
        margin-right: 20px;
        cursor: pointer;
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
</style>
