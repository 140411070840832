<template>
  <div class="mainBgColor spacing">
    <a name="TokenEconomics" class="hidden-anchor"></a>
    <div class="economicToken">
      <el-row :gutter="20">
        <el-col :sm="9">
          <div>
            <div class="economicTokenLeft">
              <div class="economicTokenLogo">
                <span class="white block-name">{{
                  $t("message.tokenTitle")
                }}</span>
                <div class="line">
                  <img
                    src="@/assets/image/show.png"
                    class="line-img"
                    alt="line"
                  />
                </div>
              </div>
            </div>
            <div class="ant-row m-t-20">
              <div>
                <div class="desc gray m-b-40">
                  {{ $t("message.tokenTips") }}
                </div>
                <div
                  class="economicTokenItem"
                  v-for="(item, index) in economicTokenList"
                  :key="index"
                >
                  <div
                    class="leftRadius"
                    :style="{ background: item.bag }"
                  ></div>
                  <div class="economicTokenDetail m-l-20">
                    <p class="economicTokenDetailTitle fs24">
                      <span
                        class="tokenPercentage"
                        :style="{ color: item.bag }"
                        >{{ item.rate }}</span
                      >
                      <span class="m-l-10">{{ $t(item.title) }}</span>
                    </p>
                    <p class="economicTokenDetailDesc gray fs12">
                      {{ $t(item.tips) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :sm="14">
          <img
            class="economicTokenDetailImg"
            src="@/assets/image/group.png"
            alt="economicTokenDetailImg"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      economicTokenList: [
        {
          rate: "40%",
          title: "message.itemTitle1",
          tips: "message.itemTips1",
          bag: "#C5AC79",
        },
        {
          rate: "20%",
          title: "message.itemTitle2",
          tips: "message.itemTips2",
          bag: "rgba(255, 255, 255, 0.6)",
        },
        {
          rate: "10%",
          title: "message.itemTitle3",
          tips: "message.itemTips3",
          bag: "#64CF3C",
        },
        {
          rate: "10%",
          title: "message.itemTitle4",
          tips: "message.itemTips4",
          bag: "#F47F30",
        },
        {
          rate: "5%",
          title: "message.itemTitle5",
          tips: "message.itemTips5",
          bag: "#CF3C7A",
        },
        {
          rate: "5%",
          title: "message.itemTitle6",
          tips: "message.itemTips6",
          bag: "#3CA3CF",
        },
        {
          rate: "5%",
          title: "message.itemTitle7",
          tips: "message.itemTips7",
          bag: "#BACF3C",
        },
        {
          rate: "5%",
          title: "message.itemTitle8",
          tips: "message.itemTips8",
          bag: "#8E3CCF",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-row {
  align-items: center;
}
.economicToken {
  color: #fff;
  text-align: left;
  .economicTokenItem {
    background-color: #2e3442;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    height: 100px;
    margin-top: 16px;
  }
  .leftRadius {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    height: 100px;
    width: 8px;
  }
}
</style>
