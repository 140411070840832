//  日语（日本語）
export default {
  message: {
    unStakeErrorTips:
      "質入れトークンは取引所に上場してから質入れを解除することができる。",
    copyTips: "コピー",
    tgsName: "$TGSトークンアドレス",
    tgsTips:
      "これはトークンアドレスです。このアドレスに送金しないでください。トークンを購入したい場合は、先行販売の純正麺を使用してください。",
    succeeTips: "トランザクションは成功しました",
    failTips: "取引に失敗しました。もう一度お試しください",
    viewTips: "BSC スキャンで表示: ",
    approve: "承認する",
    menuHome: "ホームページ",
    menuPresale: "先行販売",
    menuWork: "使い方",
    menuStaking: "ステーキング報酬",
    menuToken: "トークンエコノミクス",
    menuBuy: "購入する方法",
    menuFAQ: "よくある質問",
    homeTips: "Telegram の世界最高のスロット マシン",
    slot: "スロット",
    join: "コミュニティに参加する",
    play: "今すぐプレイ",
    letMeIn: "入らせて",
    presaleTop: "先行販売",
    event: "プレセールイベント",
    http: "https://TGSlots/#home",
    desc: "TGSlots は、Telegram のオンライン スロットやその他のカジノ ゲーム専用の場所です。完全にライセンスされており、非管理型ウォレットで資金は 100% 安全です。$TGS トークンを取得して、収益の一部を共有しましょう。",
    title: "プレセールで $TGS を入手する理由",
    tips1: "限定特典",
    currentPrice: "現在の価格",
    achieved: "私たちの目標",
    increases: "価格が上がる前に今すぐ購入:",
    pay: "あなたが支払う",
    max: "マックス",
    receive: "受け取るTGS",
    buyRequire: "少なくとも100 TGSを購入してください。",
    enoughTips: "あなたは十分に持っていません",
    enoughTips1: "この取引の支払いに。",
    gasPayTips: "十分な",
    gasPayTips1: "天然ガス支払用",
    gasTips: "タイムセール",
    gasTips1:
      "TGSlotsは$TGSを購入する際にガソリン代を全額補助しますので、カスタマーサポートに連絡してクレームをつけてください。 ",
    walletTips: "財布",
    balanceTips: "バランス",
    toContact: "クリックして連絡",
    purchaseTips:
      "サードパーティのブラウザを使用して正常に購入できない場合は、ウォレットの組み込みブラウザにリンクをコピーしてアクセスしてください。",
    copyLink: "リンクをコピーする",
    apy: "現在のステーキングAPY:",
    buyAndStake: "APYで購入してステーキングする",
    apyTips: "年利",
    justBuy: "ステーキングせずに購入したいだけ",
    connectWallet: "ウォレットを接続する",
    wallet: "ウォレットに$TGSを追加する方法",
    navStakTips:
      "TGSlots では、トークンをステークしたりステーク解除したりできます。必要な機能を選択してください。ステークされたトークンのみが APY を獲得します。",
    blockStakName1: "現在のAPY",
    blockStakName2: "ステークされた合計$TGS",
    blockStakName3: "% 合計 $TGS の ",
    blockStakName4: "支払われた報酬総額 ($TGS)",
    youStaking: "あなたのステーキング",
    staked1: "合計$TGSステーク",
    staked2: "合計$TGSのステークの割合",
    harvested: "獲得した報酬総額 ($TGS)",
    claimable: "請求可能な合計報酬額 ($TGS)",
    receiveTips: "受け取る",
    stake: "ステーク",
    unStake: "ステーク解除",
    learnMore: "もっと詳しく知る",
    indicatedAPY:
      "トークンをステークして、示されたAPYに基づいて受動的な報酬を受け取ります",
    unStakeTips: "トークンをアンステークし、ステーキングからの報酬を引き換える",
    preSaleName: "プレセールで $TGS を入手する理由",
    presaleTitle1: "限定特典",
    presaleTips1:
      "プレセール参加者はTGSlotsをプレイすると限定特典を受け取ることができます",
    presaleTitle2: "史上最高の価格",
    presaleTips2:
      "当社のプラットフォームの収益が増加するにつれて $TGS が急騰するため、プレセール中ほど $TGS を入手するのに最適な時期はありません。",
    presaleTitle3: "ハイローラーの報酬",
    presaleTips3:
      "プレセールに 5,000 米ドル以上投資すると、限定 NFT やその他の発売後の特典を獲得できます。",
    presaleTitle4: "今日から稼ぎ始めましょう",
    presaleTips4:
      "プレセールで $TGS を入手すると、すぐにステークして報酬をアンロックできるようになります。",
    rewards1:
      "プレセールの参加者は、TGSlots Best Price Everをプレイすると、特別な報酬を受け取る権利があります。",
    tips2: "史上最高の価格",
    rewards2:
      "当社のプラットフォームの収益が増加するにつれて $TGS が急騰するため、プレセール中ほど $TGS を入手するのに最適な時期はありません。",
    tips3: "ハイローラーの報酬",
    rewards3:
      "プレセールに 5,000 米ドル以上投資すると、限定 NFT やその他の発売後の特典を獲得できます。",
    tips4: "今日から稼ぎ始めましょう",
    rewards4:
      "プレセールで $TGS を入手すると、すぐにステークして報酬をアンロックできるようになります。",
    work: "使い方",
    workTips:
      "TGSlotsにはオンラインカジノが提供するすべてのものがあり、さらにユーザーを株主とし、TGS$を保持することで継続的に報酬を得る独自のインセンティブシステムも備わっています。",
    stakTitle: "ステーキング報酬",
    stakTips:
      "プレセール中に購入し、ゲーム内で獲得した $TGS をステーキングすることで、あなたは当社の株主となり、高い APY で当社の収益の一部を共有できます。",
    incomTitle: "受動的な収入を得る",
    incomTips:
      "あなたが眠っている間にも、$TGSの保有量が時間とともに増えていくのを見てください。魔法は$TGSをステークした瞬間に起こります。",
    giftTitle: "すぐに報酬を受け取る",
    giftTips:
      "ステーキングによる報酬は、いつでもお望みどおりに請求できます。TGSlots でチップとして使用したり、ステーキング プールに再度ステーキングしてさらに多くの報酬を獲得したりすることもできます。",
    cashTitle: "より多くの収入機会",
    cashTips:
      "$TGS をステークすると、プラットフォームの収益に基づいた報酬にアクセスできるようになります。最新情報については、ステーキング ページを随時ご確認ください。",
    tokenTitle: "トークンエコノミクス",
    tokenTips:
      "当社のトークンエコノミクスは、インセンティブを調整し、関係するすべてのトークン保有者にとって持続可能な win-win の状況を実現するように設計されています。",
    itemTitle1: "先行販売",
    itemTitle2: "ステーキング報酬",
    itemTitle3: "流動性プール",
    itemTitle4: "プレイヤー報酬",
    itemTitle5: "協力エージェント",
    itemTitle6: "機関投資家",
    itemTitle7: "コミュニティの宝庫",
    itemTitle8: "マーケティング",
    itemTips1: "$TGSエコシステムを活性化させる",
    itemTips2: "熱心なホルダーに報いるために",
    itemTips3: "DEXとCEXでペアを作成するには",
    itemTips4: "すべてのプレイヤーに報酬",
    itemTips5: "TGSlotsの成長に貢献した人への報酬",
    itemTips6: "TGSlotsに投資する投資組織専用",
    itemTips7: "コミュニティ戦略準備金",
    itemTips8: "協力とダイレクトマーケティング",
    jumpName: "すぐに飛び込もう",
    jumpTips:
      "始めるには、Telegram で TGSlots を開き、主要な暗号通貨やクレジットカードでアカウントに資金を入金するだけです。",
    safeTips1: "ライセンス型および非管理型",
    safeTips2: "アカウント設定は不要",
    safeTips3: "スマートフォンでプレイ",
    safeTips4: "Telegramに完全に統合",
    jumpPlay: "今すぐプレイ",
    whitepaper: "白書",
    whitepaperTips:
      "私たちと同じように TGSlots に真剣に取り組んでいる方のために、TGSlots を制作する背景と詳細を説明したホワイトペーパーを用意しました。これを読んで、私たちが他のオンライン カジノとどう違うのかをご確認ください。",
    FqName: "よくある質問",
    FqTitle1: "TGSlots の違いは何ですか?",
    FqTips1:
      "TGSlots では、トップクラスのオンライン カジノ ゲーム、スポーツ ブックなどすべてにアクセスできますが、当社は忠誠心を重視するエコシステムの育成にも取り組んでいます。当社の献身的な取り組みの証として、$TGS トークンの買い戻しを通じてカジノ収益の一部を共有することで還元しています。プレイして、稼いで、報酬を獲得しましょう!",
    FqTitle2: "先行販売期間はどのくらいですか？",
    FqTips2:
      "プレセールはハードキャップに達するまで行われます。プレセールのソフトキャップは 200 万ドルです。",
    FqTitle3: "なぜ $TGS を購入する必要があるのでしょうか?",
    FqTips3:
      "TGSlots では多くの主要な暗号通貨でプレイできますが、ステーキングとトークンの保有の両方から素晴らしい報酬を獲得できるのは $TGS だけです。TGSlots でのプレイに $TGS は必要ありませんが、他の通貨と同様にゲームでの賭けに使用できます。",
    FqTitle4: "プレセールで得た$TGSトークンはすぐに入手できますか?",
    FqTips4:
      "$TGS には権利確定期間はありません。ただし、プレセール中にトークンをステークすると、発売日 + 7 日間までロックされます。トークンをステークすると、すぐにステーク報酬やその他の将来の特典の獲得が始まります。",
    FqTitle5: "トークンはいつ開始されますか?",
    FqTips5:
      "プレセールの終了から数日以内に $TGS をリリースする予定です。リリース日については、当社の Web サイトやソーシャル メディア チャネルを通じて皆様にお知らせします。",
    stakingName: "ステーキング",
    stakingTips:
      "TGSlots では、$TGS トークンをいつでも好きなときにステークしたり、ステーク解除したりできます。ただし、ステークされたトークンのみが APY を獲得できるので注意してください。詳細",
    sakeips1: "現在のAPY",
    sakeips2: "ステーキングプールの合計$TGS",
    sakeips3: "ステーキングプールにおけるあなたの所有権の割合（$TGS）",
    sakeips4: "総支払額（$TGS）",
    buyName: "購入する方法",
    buyTips:
      "プレセール中にトークンを購入するには、TGSlots の公式 Web サイトにアクセスしてください。プレセール後は、公式 Web サイトから直接、または分散型取引所を通じてトークンを入手できます。",
    buyTitle: "プレセール中に$TGSを購入する方法",
    buyStepName1: "ステップ1: 公式ポータルにアクセスする",
    buySteTips1:
      "TGSlots の専用ウェブページにアクセスして、専用のプレセール購入インターフェイスを見つけてください。これはプレセール $TGS トークンを取得できる唯一の認定プラットフォームであることにご注意ください。",
    buyStepName2: "ステップ2: 支払い方法を選択する",
    buySteTips2:
      "Ethereum (ETH) または Tether (USDT) を使用して、Ethereum ネットワークでプレセール $TGS トークンを購入することができます。または、Binance Smart Chain を活用して、BNB または USDT を選択することもできます。選択して次のステップに進みます。",
    buyStepName3: "ステップ3: 暗号通貨ウォレットを接続する",
    buySteTips3:
      "暗号通貨ウォレットがまだ同期されていない場合は、接続を求めるプロンプトが表示されます。通常、ウォレットはデフォルトで Ethereum メインネット用に設定されています。これは、ETH で取引する場合に有利です。ただし、Binance Smart Chain を選択した場合は、ネットワークの切り替えを確認する必要がある場合があります。",
    buyStepName4: "ステップ4: 購入金額を指定する",
    buySteTips4:
      "購入したい $TGS トークンの数量を入力します。購入予定のトークンのコストに加えて、トランザクションを完了するには最低 0.015 ETH が必要であることに注意してください。Binance Smart Chain では、この最小要件は低くなりますが、それでも適用されます。購入できる $TGS トークンの最小数量は 100 $TGS に設定されています。",
    buyStepName5: "ステップ5: 購入を完了する",
    buySteTips5:
      "金額を入力すると、取引を承認するように求められます。[承認] をクリックすると、取引が開始され、ブロックチェーン上で検証されます。取引の完了時間は、ネットワークの混雑状況やスマート コントラクトのアクティビティに応じて、数秒から数分までさまざまです。購入が完全に実行されるまで、インターフェイスに留まってください。",
    contactUs: "お問い合わせ",
    footerText: "tgslots 無断転載禁止",
    privacy: "プライバシー",
    terms: "条項",
    cookie: "プライバシー",
    stepName: "ステップ",
    fundName: "資金を安全に匿名でプレイ",
    fundDesc:
      "ブロックチェーンの分散型の性質により、お客様から KYC を要求したり、お客様の資金を集中的に保管する必要がありません。したがって、お客様は個人情報を渡す必要がなく、資金の安全性について心配する必要もありません。必要なのは Telegram アカウントといくつかの暗号通貨だけです。これで準備完了です。",
    fundTips1:
      "Telegram アカウントをまだお持ちでない場合は、アプリをダウンロードしてアカウントを作成してください。次に、上記の「TGSlots を開く」リンクから TGSlots を開きます。すでに Telegram アカウントをお持ちの場合は、「TGSlots を開く」をクリックしてすぐに開始してください。",
    fundTips2:
      "TGSlots アカウントへの資金の送金は、暗号通貨ウォレット、CEX アカウント、またはクレジットカード (TON ウォレットにチャージ) から選択できます。入金が完了したら、準備完了です!",
    fundTips3:
      "ゲームを選択して、始めましょう! TGSlots では、24 時間年中無休の手間のかからないゲーム体験をお楽しみいただけます。プロのカスタマー サポートも 24 時間年中無休で待機しています。",
    openTips: "TGSlotsを開く",
    download: "Telegramをダウンロード",
  },
};
