const common = {
  langList: [
    {
      id: "en",
      lang: "English",
      img: require("@/assets/image/icon_mg.png"),
    },
    {
      id: "ar",
      lang: "عربي",
      img: require("@/assets/image/arab.png"),
    },
    {
      id: "zh_hk",
      lang: "繁體中文",
      img: require("@/assets/image/zhhk.png"),
    },
    {
      id: "vi",
      lang: "Tiếng Việt",
      img: require("@/assets/image/icon_yn.png"),
    },
    {
      id: "hi",
      lang: "हिंदी",
      img: require("@/assets/image/icon_yd.png"),
    },
    {
      id: "pt_br",
      lang: "Português",
      img: require("@/assets/image/icon_bx.png"),
    },
    {
      id: "es_mx",
      lang: "español",
      img: require("@/assets/image/icon_mxg.png"),
    },
    {
      id: "ja",
      lang: "日本語",
      img: require("@/assets/image/icon_rb.png"),
    },
    {
      id: "ko",
      lang: "한국인",
      img: require("@/assets/image/icon_hg.png"),
    },
    {
      id: "ru",
      lang: "руская мова",
      img: require("@/assets/image/russia.png"),
    },
  ],
  menuList: [
    {
      name: "message.menuHome",
      id: "Home",
    },
    {
      name: "message.menuPresale",
      id: "Presale",
    },
    {
      name: "message.menuWork",
      id: "HowItWorks",
    },
    {
      name: "message.menuStaking",
      id: "StakingRecords",
    },
    {
      name: "message.menuToken",
      id: "TokenEconomics",
    },
    {
      name: "message.menuBuy",
      id: "HowToBuy",
    },
    {
      name: "message.menuFAQ",
      id: "FAQ",
    },
  ],
};

export default common;
