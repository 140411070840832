<template>
  <div class="home-wrapper">
    <div class="transaction-warp flex-center" v-if="isShowTranStatus">
      <div
        class="left-tran flex-center"
        :class="tranStatus ? 'bg-blue' : 'bg-red'"
        @click="isShowTranStatus = false"
      >
        <img class="icon-35 cursor" :src="tranStatus ? successImg : failImg" />
      </div>
      <div class="right-tran">
        <div :class="tranStatus ? '' : 'red'" class="fs16">
          {{ tranStatus ? $t("message.succeeTips") : $t("message.failTips") }}
        </div>
        <div class="flex-center blue fs14 m-t-5">
          <span class="m-r-5">{{ $t("message.viewTips") }}</span>
          <div class="cursor" @click="webOpenHash()">
            {{ transactionHash.slice(0, 8) + "..." }}
          </div>
        </div>
      </div>
    </div>
    <a name="Home" style="position: relative; top: -204px"></a>
    <el-row :gutter="20" class="grid-content">
      <el-col :span="2"></el-col>
      <el-col :sm="9">
        <div class="flex">
          <div class="actionView">
            <div class="flex-center">
              <img class="logo-img" src="@/assets/image/logo.png" />
              <img class="duck-img m-l-10" src="@/assets/image/duckling.png" />
            </div>
            <div class="home-tips white">{{ $t("message.homeTips") }}</div>
            <!-- <span class="white fs64">{{ $t("message.slot") }}</span> -->
            <div class="gray fs12 desc">{{ $t("message.desc") }}</div>
            <div
              class="join common-btn"
              @click="openWebUrl('https://t.me/testtgslots')"
            >
              <img
                class="joinLogo m-r-10"
                alt="join logo"
                src="@/assets/image/join.png"
              />
              {{ $t("message.join") }}
            </div>
            <div
              class="play-now common-btn"
              @click="openWebUrl('https://t.me/TGslots_bet_bot')"
            >
              <img
                class="joinLogo m-r-10"
                alt="join logo"
                src="@/assets/image/join.png"
              />
              {{ $t("message.play") }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :sm="12">
        <div class="fs54 white">{{ $t("message.presaleTop") }}</div>
        <div class="form-wrapper">
          <div class="ant-row">
            <div class="grid-content white top-money">$ {{ presalePrice }}</div>
          </div>
          <div class="flex-center justify-between">
            <div class="current-col">
              <p class="white">{{ $t("message.currentPrice") }}</p>
              <p class="gray">$ {{ currentPrice }}</p>
            </div>
            <div class="current-col">
              <p class="white">{{ $t("message.achieved") }}</p>
              <p class="gray">$ {{ targetShow }}</p>
            </div>
          </div>
          <div class="m-b-20">
            <el-progress
              :class="percentage > 3 ? '' : 'blackText'"
              :text-inside="true"
              :stroke-width="26"
              :percentage="percentage"
            />
          </div>
          <div v-if="webAccount.address">
            <div class="flex-center justify-between">
              <span class="text-tips">{{ $t("message.walletTips") }}</span>
              <div class="flex-center wallet-text">
                <span class="m-r-8">{{ formatString(webAccount.address) }}</span
                ><img
                  class="icon-24 cursor"
                  src="@/assets/image/quit.png"
                  alt=""
                  @click="quitWallet()"
                />
              </div>
            </div>
            <div class="flex-center justify-between">
              <span class="text-tips">{{ $t("message.balanceTips") }}</span>
              <div class="flex-center balance-text">
                <p class="m-r-8">
                  {{ balanceCurrency
                  }}<span class="m-l-5">{{ activeName }}</span>
                </p>
                <img class="icon-24" :src="activeImg" alt="" />
              </div>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col
              :span="8"
              v-for="(item, index) in cardList"
              :key="index"
              @click="selectCard(item, index)"
            >
              <div
                class="eth-btn"
                :class="activeIndex === index ? 'isActive' : ''"
              >
                <img :src="item.img" class="icon icon-22" alt="eth" />
                <span class="white">{{ item.name }}</span>
              </div>
            </el-col>
          </el-row>
          <div>
            <div class="in-put">
              <div class="flex-center justify-between white">
                <div class="label">
                  {{ activeName }} {{ $t("message.pay") }}
                </div>
                <!-- <div class="max-value">{{ $t("message.max") }}</div> -->
              </div>
              <el-input
                v-model="paymentCurrency"
                @change="changeCurrency"
                :disabled="webAccount.address ? false : true"
              ></el-input>
            </div>
            <div class="in-put">
              <div class="label white">
                {{ $t("message.receive") }}
              </div>
              <el-input v-model="receivedTgs" :disabled="true"></el-input>
            </div>
          </div>
          <!--<div class="gasTip">
            <div class="flex">
              <img
                class="icon-22 m-r-5 m-t-3"
                src="@/assets/image/clock.png"
                alt=""
              />
              <div>
                <span class="orange m-r-5">{{ $t("message.gasTips") }}</span>
                <span class="gray">{{ $t("message.gasTips1") }}</span>
                <a class="gasTipLink">{{ $t("message.toContact") }}</a>
              </div>
            </div>
          </div>-->
          <div class="gasTip">
            <span class="gray">{{ $t("message.purchaseTips") }}</span>
            <img
              class="icon-16 m-r-5 cursor"
              src="@/assets/image/copy.png"
              alt=""
            />
          </div>
          <div class="tip-message" v-if="amountShow">
            {{ $t("message.buyRequire") }}
          </div>
          <div class="tip-message" v-if="enoughShow">
            {{ $t("message.enoughTips") }} {{ activeName }}
            {{ $t("message.enoughTips1") }}
          </div>
          <div class="tip-message" v-if="gasPayShow">
            {{ $t("message.gasPayTips") }} {{ activeName }}
            {{ $t("message.gasPayTips") }}
          </div>
          <div class="other-row">
            <div v-if="webAccount.address" class="other-btn">
              <!-- USDT和USDC -->
              <div v-if="activeIndex !== 0">
                <el-button
                  v-if="!isApprove && !loadingApprove"
                  :disabled="isDisabledBuyBtn"
                  type="primary"
                  @click="buyApprove()"
                >
                  <span v-if="isPaymentCurrency">{{
                    $t("message.approve")
                  }}</span>
                  <span v-else
                    >{{ $t("message.buyAndStake") }}
                    {{ currentPriceAPY }}
                    {{ $t("message.apyTips") }}</span
                  >
                </el-button>
                <el-button
                  v-else-if="isApprove && !loadingApprove"
                  :disabled="isDisabledBuyBtn"
                  type="primary"
                  @click="buyOtherWithAndStake()"
                >
                  <span
                    >{{ $t("message.buyAndStake") }}
                    {{ currentPriceAPY }}
                    {{ $t("message.apyTips") }}</span
                  >
                </el-button>
                <el-button
                  v-else-if="loadingApprove"
                  :disabled="true"
                  type="primary"
                  loading
                >
                  <span v-if="!isApprove">{{ $t("message.approve") }}</span>
                  <span v-else
                    >{{ $t("message.buyAndStake") }}
                    {{ currentPriceAPY }}
                    {{ $t("message.apyTips") }}</span
                  >
                </el-button>
              </div>
              <!-- BNB -->
              <div v-else>
                <el-button
                  v-if="loadingBuy"
                  :disabled="true"
                  type="primary"
                  loading
                >
                  <span
                    >{{ $t("message.buyAndStake") }}
                    {{ currentPriceAPY }}
                    {{ $t("message.apyTips") }}</span
                  >
                </el-button>
                <el-button
                  v-else-if="!loadingBuy"
                  :disabled="isDisabledBuyBtn"
                  type="primary"
                  @click="buyWithAndStake()"
                >
                  <span
                    >{{ $t("message.buyAndStake") }}
                    {{ currentPriceAPY }}
                    {{ $t("message.apyTips") }}</span
                  >
                </el-button>
              </div>
            </div>
            <div v-else class="other-btn">
              <el-button type="primary" @click="open()">
                <span>{{ $t("message.connectWallet") }}</span>
              </el-button>
            </div>
            <div v-if="webAccount.address">
              <el-button
                v-if="activeIndex !== 0"
                :disabled="isApproveBtn"
                class="m-t-10 m-b-20"
                type="text"
                @click="justOtherBuyWith()"
              >
                <span class="gray"> {{ $t("message.justBuy") }}</span>
              </el-button>
              <el-button
                v-else-if="activeIndex === 0"
                class="m-t-10 m-b-20"
                :disabled="isDisabledBuyBtn"
                type="text"
                @click="justBuyWith()"
              >
                <span class="gray"> {{ $t("message.justBuy") }}</span>
              </el-button>
            </div>
            <div class="m-t-10 m-b-20 gray" v-else>
              {{ $t("message.apy") }} {{ currentPriceAPY }}
            </div>
            <div class="other-btn">
              <el-button type="primary" @click="howToAddTgs()">
                <span>{{ $t("message.wallet") }}</span>
                <!-- <a
                  class="white"
                  style="text-decoration: none"
                  href="#HowToBuy"
                  @click="selectMenu"
                  >{{ $t("message.wallet") }}</a
                > -->
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <div class="clock-container">
            <flip-item :total="9" :current="timeArr[0]" />
            <flip-item :total="9" :current="timeArr[1]" />
            <flip-item :total="9" :current="timeArr[2]" />
            <div class="colon"></div>
            <flip-item :total="5" :current="timeArr[3]" />
            <flip-item :total="9" :current="timeArr[4]" />
            <div class="colon"></div>
            <flip-item :total="5" :current="timeArr[5]" />
            <flip-item :total="9" :current="timeArr[6]" />
    </div> -->
    <el-dialog
      v-model="addTgsDialogVisible"
      :title="$t('message.tgsName')"
      width="550"
      align-center
    >
      <el-divider />
      <div class="main m-t-20">
        <span>{{ tokenAddress }}</span>
        <div class="m-t-20 red">
          {{ $t("message.tgsTips") }}
        </div>
        <div class="other-btn m-t-20">
          <el-button @click="onCopy" type="primary">
            <span>{{ $t("message.copyTips") }}</span>
          </el-button>
        </div>
        <div class="flex-center justify-between m-t-20">
          <span class="text-tips">$TGS {{ $t("message.balanceTips") }}</span>
          <div class="flex-center">
            <span class="m-l-5 ft16" style="color: #db7820">{{
              tgsBalance
            }}</span>
            <img class="icon-24 m-l-5" src="@/assets/image/token.png" alt="" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useDisconnect,
} from "@web3modal/ethers/vue";
import Web3 from "web3";
import Web3Modal from "web3modal";
import commonWeb3 from "@/api/web3";
import { baseURL } from "@/api/config";
import { getTimeArr } from "../utils";
import { ElMessage } from "element-plus";
// import FlipItem from "./Clock/FlipItem.vue";
// import { CircleCloseFilled } from "@element-plus/icons-vue";
export default {
  components: {
    // FlipItem,
    // CircleCloseFilled,
  },
  data() {
    return {
      activeIndex: 0,
      percentage: 0,
      targetPercent: 0,
      targetShow: window.parameter.TARGETShow,
      // persalShow: window.parameter.PRESALEShow,
      // presalePrice: window.parameter.PRESALEPRICE,
      presalePrice: 0,
      targetPrice: window.parameter.TARGETPRICE,
      timeArr: getTimeArr(),
      activeName: "BNB",
      activeImg: require("@/assets/image/bnbIcons.png"),
      cardList: [
        {
          name: "BNB",
          img: require("@/assets/image/bnbIcons.png"),
        },
        {
          name: "USDT",
          img: require("@/assets/image/usdt.png"),
        },
        {
          name: "USDC",
          img: require("@/assets/image/usdc.png"),
        },
      ],
      transactionHash: "",
      isShowTranStatus: false,
      tranStatus: null,
      successImg: require("@/assets/image/succ.png"),
      failImg: require("@/assets/image/fail.png"),
      formatePayment: 0, // 转换值
      paymentCurrency: 0,
      receivedTgs: 0,
      usdtBuyHelper: 0,
      amountShow: false,
      enoughShow: false,
      gasPayShow: false,
      isDisabledBuyBtn: true,
      isApproveBtn: true,
      netProvider: null,
      webAccount: {
        address: "",
        isConnected: false,
        chainId: 0,
      },
      walletProvider: {
        walletProvider: null,
      },
      createWeb3Modal: {},
      writeWeb3: null,
      web3Modal: null,
      priceBNB: 0,
      // currentPrice: baseURL.CURRENT_PRICE,
      currentPrice: 0,
      balanceCurrency: 0,
      tokenPrice: 0,
      formWeiTokenPrice: 0,
      // staking
      stakingAbi: commonWeb3.stakingAbi,
      stakingAddress: baseURL.STAKINGMANAGERV1_ADDRESS,
      // presale
      presaleContract: {},
      presaleAbi: commonWeb3.presaleAbi,
      presaleAddress: baseURL.PRESALEV1_PROXY_ADDRESS, // PresaleV1_Proxy 合约地址
      // token
      tgsBalance: 0,
      addTgsDialogVisible: false,
      tokenContract: {},
      tokenAbi: commonWeb3.tokenAbi,
      tokenAddress: baseURL.TOKEN_ADDRESS, // token 合约地址
      isApprove: false, // 是否授权
      isPaymentCurrency: false,
      //usdtAbi
      approveUsdtCoin: 0,
      usdtAbi: commonWeb3.usdtAbi,
      usdtAddress: baseURL.USDT_ADDRESS,
      usdtContract: {},
      //usdc
      approveUsdcCoin: 0,
      usdcAbi: commonWeb3.usdcAbi,
      usdcAddress: baseURL.USDC_ADDRESS,
      usdcContract: {},
      //
      loadingApprove: false,
      loadingBuy: false,
      comparisonValue: 0, // 比较值
      unlimitedValue: 0, // 无限值
      pledgeDay: null, // 质押天数
      pledgeHour: null, // 质押小时数
      currentAPY: 0,
      currentPriceAPY: 0, //年利率
      twentyPercent: baseURL.TWENTY_PERCENT,
      firstBlock: baseURL.FIRSTBLOCK,
      otherWeb3: null,
    };
  },
  created() {
    this.getNoConnectContract();
  },
  mounted() {
    this.webAccount = useWeb3ModalAccount();
    this.startTimer();
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts[0] && this.webAccount.address) {
          this.commonGetBanlance(accounts[0]);
        }
      });
    }
    this.deviceClient =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    // console.log(this.deviceClient, "是否是h5端");
  },
  beforeUnmount() {
    this.stopTimer();
  },
  watch: {
    webAccount: {
      handler(newValue) {
        if (newValue.address && newValue.isConnected) {
          this.getConnectContract();
        }
      },
      deep: true, // 深度监听
      immediate: true,
    },
  },
  methods: {
    selectMenu(event) {
      this.$emit("selectMenu", event);
    },
    howToAddTgs() {
      this.addTgsDialogVisible = true;
    },
    onCopy() {
      this.$copyText(this.tokenAddress).then(
        () => {
          ElMessage({
            message: "success",
            type: "success",
          });
        },
        () => {
          ElMessage.error("error");
        }
      );
    },
    animateProgressBar() {
      if (this.presalePrice === 0) {
        this.targetPercent = 0;
      } else {
        const targetValue = parseFloat(
          (this.presalePrice / this.targetPrice) * 100
        );
        if (targetValue < 0.1) {
          this.targetPercent = 0.1;
        } else {
          this.targetPercent = targetValue.toFixed(2);
        }
      }
      if (this.targetPercent >= 100) {
        this.percentage = 100;
      } else {
        this.percentage = this.targetPercent;
      }
    },
    webOpenHash() {
      const url = baseURL.HASH_NET + this.transactionHash;
      window.open(url);
    },
    openWebUrl(url) {
      window.open(url);
    },
    formatString(str) {
      return str.substring(0, 4) + "****" + str.substring(str.length - 4);
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.stopTimer();
        this.timeArr = getTimeArr();
        this.startTimer();
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },
    async open() {
      useWeb3Modal().open();
    },
    async quitWallet() {
      useDisconnect().disconnect();
    },
    // 获取时间
    getPledgeDays() {
      const today = new Date(); // 获取当天日期
      const nextYear = new Date( // 获取明年的今天
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      );
      const timeDiff = nextYear - today;
      this.pledgeDay = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // 剩余质押天数---pledgeDay
      this.pledgeHour = this.pledgeDay * 24; // 剩余质押小时数
    },
    async getNoConnectContract() {
      this.getPledgeDays();
      this.otherWeb3 = new Web3(baseURL.RPCURL_API);
      const presaleContract = new this.otherWeb3.eth.Contract(
        this.presaleAbi,
        this.presaleAddress
      );
      const tokenContract = new this.otherWeb3.eth.Contract(
        this.tokenAbi,
        this.tokenAddress
      );
      const noConnectStakeContract = new this.otherWeb3.eth.Contract(
        this.stakingAbi,
        this.stakingAddress
      );
      const tokenPrice = await presaleContract.methods.tokenPrice().call();
      this.currentPrice = this.otherWeb3.utils.fromWei(tokenPrice, "ether"); // 当前tokenPrice价格
      const tgsStaked = await tokenContract.methods
        .balanceOf(this.stakingAddress)
        .call();
      const tgsStakedWei = this.otherWeb3.utils.fromWei(tgsStaked, "ether");
      const presaleTokenPrice = (
        (this.targetPrice / this.currentPrice - tgsStakedWei) *
        this.currentPrice
      ).toFixed(2);
      presaleTokenPrice > 0
        ? (this.presalePrice = presaleTokenPrice)
        : (this.presalePrice = 0);
      // Total Rewards Paid
      const currentBlock = await this.otherWeb3.eth.getBlockNumber(); //
      const rewardTokensPerBlock = await noConnectStakeContract.methods
        .rewardTokensPerBlock()
        .call();
      const rewardsPaid = (
        (parseInt(currentBlock) - this.firstBlock) *
        this.otherWeb3.utils.fromWei(rewardTokensPerBlock, "ether")
      ).toFixed(2);
      // Current APY
      const poolPrice = await tokenContract.methods.totalSupply().call();
      const totalSupply = this.otherWeb3.utils.fromWei(poolPrice, "ether");
      const totalPrizePool = totalSupply * this.twentyPercent; // 总奖池
      const totalTgsStakedWei = parseInt(tgsStakedWei).toFixed(2);
      if (totalTgsStakedWei !== 0) {
        this.currentAPY =
          ((totalPrizePool - rewardsPaid) / totalTgsStakedWei) *
          (365 / this.pledgeDay) *
          100;
      }
      this.currentPriceAPY = this.currentAPY.toFixed(2) + "%";
      this.animateProgressBar();
    },
    // 创建获取合约
    async getConnectContract() {
      this.createWeb3Modal = new Web3Modal({
        cacheProvider: true, // 是否缓存提供商信息
        disableInjectedProvider: false, // 是否禁用浏览器上已注入的提供商（例如 MetaMask）
        fullScreen: false, // 是否使用全屏模式显示 Web3Modal 登录框
        network: "mainnet", // 连接的区块链网络（例如 mainnet，rinkeby，ropsten）
        theme: "light", // 选择 Web3Modal 登录框的主题，可选值有“light”（浅色主题）和“dark”（深色主题）
      });
      this.netProvider = await this.createWeb3Modal.connect();
      this.writeWeb3 = new Web3(this.netProvider);
      this.presaleContract = new this.writeWeb3.eth.Contract(
        this.presaleAbi,
        this.presaleAddress
      );
      this.tokenContract = new this.writeWeb3.eth.Contract(
        this.tokenAbi,
        this.tokenAddress
      );

      this.usdtContract = new this.writeWeb3.eth.Contract(
        this.usdtAbi,
        this.usdtAddress
      );
      this.usdcContract = new this.writeWeb3.eth.Contract(
        this.usdcAbi,
        this.usdcAddress
      );
      // eslint-disable-next-line no-undef
      const value = BigInt(10) ** BigInt(18) - BigInt(1);
      this.comparisonValue = this.writeWeb3.utils.toWei(
        value.toString(),
        "ether"
      );
      const latestPrice = await this.presaleContract.methods
        .getLatestPrice()
        .call();
      this.priceBNB = this.writeWeb3.utils.fromWei(latestPrice, "ether");
      this.getBnbBalance(this.webAccount.address);
    },
    async getBnbBalance(address) {
      const tokenBalance = await this.tokenContract.methods
        .balanceOf(this.webAccount.address)
        .call();
      const balanceTgs = parseFloat(
        this.writeWeb3.utils.fromWei(tokenBalance, "ether")
      );
      if (balanceTgs !== 0) {
        this.deviceClient
          ? (this.tgsBalance = parseFloat(balanceTgs.toFixed(8)))
          : (this.tgsBalance = parseFloat(balanceTgs.toFixed(18)));
      }
      this.writeWeb3.eth.getBalance(address).then((balance) => {
        const balanceCurrency = parseFloat(
          this.writeWeb3.utils.fromWei(balance, "ether")
        );
        if (balanceCurrency !== 0) {
          this.balanceCurrency = this.deviceClient
            ? parseFloat(balanceCurrency.toFixed(8))
            : parseFloat(balanceCurrency.toFixed(18));
        }
      });
    },
    async getUsdtBalance(address) {
      const usdtBalance = await this.usdtContract.methods
        .balanceOf(address)
        .call();
      const balanceCurrency = parseFloat(
        this.writeWeb3.utils.fromWei(usdtBalance, "ether")
      );
      if (balanceCurrency !== 0) {
        this.deviceClient
          ? (this.balanceCurrency = parseFloat(balanceCurrency.toFixed(8)))
          : (this.balanceCurrency = parseFloat(balanceCurrency.toFixed(18)));
      }
      this.approveUsdtCoin = await this.usdtContract.methods
        .allowance(address, this.presaleAddress)
        .call();
      if (parseInt(this.approveUsdtCoin) < parseInt(this.comparisonValue)) {
        this.isApprove = false;
        this.isApproveBtn = true;
      } else {
        this.isApprove = true;
        this.isApproveBtn = false;
      }
    },
    async getUsdcBalance(address) {
      const usdcBalance = await this.usdcContract.methods
        .balanceOf(address)
        .call();
      const balanceCurrency = parseFloat(
        this.writeWeb3.utils.fromWei(usdcBalance, "ether")
      );
      if (balanceCurrency !== 0) {
        this.balanceCurrency = this.deviceClient
          ? parseFloat(balanceCurrency.toFixed(8))
          : parseFloat(balanceCurrency.toFixed(18));
      }
      this.approveUsdcCoin = await this.usdcContract.methods
        .allowance(address, this.presaleAddress)
        .call();
      if (parseInt(this.approveUsdcCoin) < parseInt(this.comparisonValue)) {
        (this.isApprove = false), (this.isApproveBtn = true);
      } else {
        this.isApprove = true;
        this.isApproveBtn = false;
      }
    },
    commonGetBanlance(address) {
      this.activeName === "BNB"
        ? this.getBnbBalance(address)
        : this.activeName === "USDT"
        ? this.getUsdtBalance(address)
        : this.getUsdcBalance(address);
      this.isPaymentCurrency = false;
      this.balanceCurrency = 0;
      this.receivedTgs = 0;
      this.paymentCurrency = 0;
      this.amountShow = false;
      this.enoughShow = false;
      this.gasPayShow = false;
      this.isDisabledBuyBtn = true;
    },
    // 选择币的种类
    async selectCard(item, index) {
      this.activeName = item.name;
      this.activeImg = item.img;
      this.activeIndex = index;
      if (this.webAccount.address) {
        this.commonGetBanlance(this.webAccount.address);
      }
    },
    // 输入框转换支付币
    async changeCurrency(e) {
      this.amountShow = false;
      this.enoughShow = false;
      this.gasPayShow = false;
      this.isDisabledBuyBtn = false;
      this.isPaymentCurrency = true;
      this.isApprove ? (this.isApproveBtn = false) : (this.isApproveBtn = true);
      if (this.activeName === "BNB") {
        this.receivedTgs = Math.floor(
          ((e * this.priceBNB) / this.currentPrice).toFixed(2)
        );
      } else {
        this.tokenPrice = await this.presaleContract.methods
          .tokenPrice()
          .call();
        this.formWeiTokenPrice = this.writeWeb3.utils.fromWei(
          this.tokenPrice,
          "ether"
        );
        this.receivedTgs = parseInt(e / this.formWeiTokenPrice);
        // const result = baseURL.TEN_MILLION / baseURL.TEN_BILLION;
        // this.receivedTgs = e / result;
      }
      if (this.receivedTgs < 100) {
        return (
          (this.isDisabledBuyBtn = true),
          (this.isApproveBtn = true),
          (this.amountShow = true)
        );
      }
      if (this.paymentCurrency) {
        const gasPayPrice = parseFloat(
          this.balanceCurrency - this.paymentCurrency
        ).toFixed(2);
        if (this.balanceCurrency < parseFloat(this.paymentCurrency)) {
          (this.enoughShow = true),
            (this.isDisabledBuyBtn = true),
            (this.isApproveBtn = true);
        } else if (gasPayPrice <= 0.01) {
          (this.gasPayShow = true),
            (this.isDisabledBuyBtn = true),
            (this.isApproveBtn = true);
        }
        this.formatePayment = this.writeWeb3.utils.toWei(
          this.paymentCurrency,
          "ether"
        );
      }
    },
    getHash(res) {
      this.tranStatus = res.status;
      this.transactionHash = res.transactionHash;
      this.isShowTranStatus = true;
      setTimeout(() => {
        this.isShowTranStatus = false;
      }, 5000);
    },
    // BNB购买并质押
    async buyWithAndStake() {
      this.loadingBuy = true;
      try {
        await this.presaleContract.methods
          .buyWithEthAndStake(this.receivedTgs)
          .send({
            from: this.webAccount.address,
            value: this.formatePayment,
          })
          .then((res) => {
            this.getHash(res);
            this.getBnbBalance(this.webAccount.address);
            this.loadingBuy = false;
          });
      } catch (error) {
        this.loadingBuy = false;
        // console.error("Error:", error);
      }
    },
    // buyWith BNB购买
    async justBuyWith() {
      try {
        await this.presaleContract.methods
          .buyWithEth(this.receivedTgs)
          .send({
            from: this.webAccount.address,
            value: this.formatePayment,
          })
          .then((res) => {
            this.getHash(res);
            this.getBnbBalance(this.webAccount.address);
          });
      } catch (error) {
        // console.error("Error:", error);
      }
    },
    // 授权USDT和USDC
    async buyApprove() {
      this.loadingApprove = true;
      const fromAddress = this.webAccount.address;
      let coinContract = {};
      this.activeName === "USDT"
        ? (coinContract = this.usdtContract)
        : (coinContract = this.usdcContract);
      this.unlimitedValue = await this.writeWeb3.utils
        .toBN("2")
        .pow(this.writeWeb3.utils.toBN("256"))
        .sub(this.writeWeb3.utils.toBN("1"))
        .toString();
      coinContract.methods
        .approve(this.presaleAddress, this.unlimitedValue)
        .send({
          from: fromAddress,
        })
        .then(() => {
          this.isApprove = true;
          this.isApproveBtn = false;
          this.loadingApprove = false;
          this.buyOtherWithAndStake();
        })
        .catch(() => {
          this.loadingApprove = false;
          this.isApprove = false;
        });
    },
    // USDT和USDC购买并质押
    async buyOtherWithAndStake() {
      this.loadingApprove = true;
      if (this.activeName === "USDT") {
        try {
          await this.presaleContract.methods
            .buyWithUSDTAndStake(this.receivedTgs)
            .send({
              from: this.webAccount.address,
            })
            .then((res) => {
              this.loadingApprove = false;
              this.getHash(res);
              this.getUsdtBalance(this.webAccount.address);
            });
        } catch (error) {
          this.loadingApprove = false;
          // console.error("Error:", error);
        }
      } else {
        try {
          await this.presaleContract.methods
            .buyWithUSDCAndStake(this.receivedTgs)
            .send({
              from: this.webAccount.address,
            })
            .then((res) => {
              this.loadingApprove = false;
              this.getHash(res);
              this.getUsdcBalance(this.webAccount.address);
            });
        } catch (error) {
          this.loadingApprove = false;
          // console.error("Error:", error);
        }
      }
    },
    // USDT和USDC购买
    async justOtherBuyWith() {
      if (this.activeName === "USDT") {
        try {
          this.presaleContract.methods
            .buyWithUSDT(this.receivedTgs)
            .send({
              from: this.webAccount.address,
            })
            .then((res) => {
              this.getHash(res);
              this.getUsdtBalance(this.webAccount.address);
            });
        } catch (error) {
          // console.error("Error:", error);
        }
      } else {
        try {
          this.presaleContract.methods
            .buyWithUSDC(this.receivedTgs)
            .send({
              from: this.webAccount.address,
            })
            .then((res) => {
              this.getHash(res);
              this.getUsdcBalance(this.webAccount.address);
            });
        } catch (error) {
          // console.error("Error:", error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  background: #2e3442 !important;
  border: 1px solid #586174 !important;
  box-shadow: 0 0 0 1px #586174 !important;
}
.blackText {
  ::v-deep .el-progress-bar__innerText {
    color: #0f182e !important;
  }
}

::v-deep .el-input__inner {
  color: #fff !important;
}
::v-deep .el-divider {
  margin: 0 !important;
}
::v-deep .el-dialog {
  padding: 15px 20px;
}
::v-deep .el-dialog__header {
  padding: 0 10px 10px 10px !important;
}
.other-btn {
  width: 100%;
  .el-button {
    width: 100%;
    height: 52px;
    border-radius: 8px;
  }
}
.wui-connect-button {
  background-color: #6898f3 !important;
  border: none !important;
}
// 移动端
@media screen and (max-width: 1000px) {
  .clock-container {
    .colon {
      padding-right: 14px !important;
      padding-left: 0px !important;
    }
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 1% !important;
    top: 2% !important;
  }
  ::v-deep .is-align-center {
    margin: auto 20px !important;
  }
}
.home-wrapper {
  background: url("@/assets/image/home.png") 50% / cover;
  position: relative;
  .transaction-warp {
    position: fixed;
    right: 2%;
    top: 115px;
    height: 78px;
    z-index: 99999;
    .left-tran {
      border-radius: 8px 0 0 8px;
      height: 100%;
      padding: 0 16px;
      line-height: 78px;
    }
    .right-tran {
      border-radius: 0px 8px 8px 0;
      background: #fff;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px 0 30px;
    }
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: -2%;
    top: -2%;
    z-index: 999;
  }
  .actionView {
    .logo-img {
      width: 70%;
      height: auto;
    }
    .duck-img {
      width: 111px;
      height: auto;
    }
  }
  .let-warp {
    font-size: 16px;
    margin-top: 30px;
    background: #fd9a24;
    color: #fff;
    width: 100%;
  }
  .grid-content {
    .desc {
      width: 100%;
      line-height: 22px;
    }

    .join {
      background-color: #4f515e;
      border: 1px solid #4f515e;
      font-size: 16px;
      margin-top: 42px;
    }

    .play-now {
      background-color: #0085ff;
      border: 1px solid #0085ff;
      font-size: 16px;
      margin-top: 42px;
    }
  }

  .form-wrapper {
    background: #0f182e;
    border: 1px solid #30323a;
    border-radius: 24px;
    box-shadow: inset 2px 3px 4px 0 #00000040;
    padding: 15px 20px 40px 24px;
    .text-tips {
      font-size: 16px;
      color: #fff;
      font-weight: 700;
    }
    .wallet-text {
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;
      font-weight: 400px;
    }
    // 时钟样式
    .clock-container {
      display: flex;
      align-items: center;
      width: 100%;
      .colon {
        height: 50px;
        padding: 0 10px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        &::after,
        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 50%;
        }
      }
    }
    .top-money {
      font-size: 56px;
    }

    .gasTip {
      margin-top: 20px;

      .gasTipLink {
        color: #0085ff;
      }
    }

    .in-put {
      margin-top: 20px;

      .label {
        margin-bottom: 10px;
      }
    }

    .eth-btn {
      height: 35px;
      line-height: 35px;
      margin: 10px 0;
      background: #2e3442;
      border: 1px solid #586174;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      span {
        margin-left: 10px;
      }
    }

    .isActive {
      background: rgba(13, 153, 255, 0.33);
      border: 1px solid #fff;
    }

    .tip-message {
      color: #ff494a;
      margin-top: 20px;
      text-align: left;
      font-size: 16px;
    }
    .other-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .w3-btn {
        width: 100% !important;
        background: #5c72f6 !important;
        border-radius: 8px;
        cursor: pointer;

        ::v-deep button {
          width: 100% !important;
          background: none !important;
        }
      }
    }

    .time-btn {
      background: #30323a;
      border: 1px solid #434755;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
      width: 100%;
    }

    .warpper-title {
      text-align: center;
      font-size: 32px;
    }

    .tips-warp {
      margin-top: 30px;
    }
  }
}
</style>
